/**
 * Classe principale qui gère la partie entretien
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox, ButtonGroup, Button } from "@material-ui/core";
import { Build } from "@material-ui/icons";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";
import MUIDataTable from "mui-datatables";
import DeleteButton from "../utils/deleteButton";

class AppHistoriquePanne extends Component {
  //Fonction de construction de notre tableau
  buildRow = (row, data) => {
    var arrayRow = [];
    var machineName;

    //On ajoute l'id de la panne
    arrayRow.push(row.idPanne);

    //On ajoute la date de création de la panne
    arrayRow.push(
      moment(row.date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY")
    );

    //On ajout le nom de la machine
    this.props.listMachine.length !== 0 &&
      this.props.listMachine.forEach(machine => {
        if (machine.idMachine === row.idMachine) {
          machineName = machine.nomMachine;
          arrayRow.push(machineName);
        }
      });

    //On ajoute le titre de la panne
    arrayRow.push(row.title);

    //On ajoute le temps moteur de la machine lors de l apanne
    arrayRow.push(row.tpsMoteur);

    //On ajoute la description de la panne
    arrayRow.push(row.description);

    //On ajout le Nom/Prenom du responsable
    this.props.listResponsable.forEach(responsable => {
      responsable.idResponsable.toString() === row.idResponsable.toString() &&
        arrayRow.push(responsable.nom + " " + responsable.prenom);
    });

    //On ajoute l'état de la panne(Réparé ou non)
    arrayRow.push(
      row.isDone ? (
        <Checkbox
          disabled
          checked
          inputProps={{
            "aria-label": "disabled checked checkbox"
          }}
        />
      ) : (
        <Checkbox
          disabled
          inputProps={{
            "aria-label": "disabled checked checkbox"
          }}
        />
      )
    );

    //On ajoute le temps moteur de la panne lors de sa réparation
    arrayRow.push(row.isDone ? row.tpsMoteurFin : "-");

    //on ajoute la date de réalisation de la panne
    arrayRow.push(
      row.isDone &&
        row.dateFin !== null &&
        row.dateFin !== "0000-00-00 00:00:00"
        ? moment(row.dateFin, "YYYY-MM-DDTHH:mm:ss.SSSZ")
            .utcOffset(2)
            .format("DD/MM/YYYY")
        : "-"
    );

    //On ajoute le nom/Prenom de la personne qui à réparé la panne
    if (row.isDone && row.idIntervenant !== null) {
      this.props.listResponsable.forEach(responsable => {
        responsable.idResponsable.toString() === row.idIntervenant.toString() &&
          arrayRow.push(responsable.nom + " " + responsable.prenom);
      });
    } else {
      arrayRow.push("-");
    }

    //On ajoute en dernière données une donnée optionelle pour les options
    arrayRow.push("");
    data.push(arrayRow);
  };

  render() {
    var columns = [
      {
        name: "idAlert",
        options: { filter: false, sort: false, display: "excluded" }
      },
      { name: "Date", options: { filter: true, sort: true } },
      { name: "Machine", options: { filter: true, sort: true } },
      { name: "Titre", options: { filter: true, sort: true } },
      { name: "Temps Moteur", options: { filter: false, sort: true } },
      { name: "Description", options: { filter: false, sort: true } },
      { name: "Responsable", options: { filter: true, sort: true } },
      { name: "Réparé ?", options: { filter: false, sort: true } },
      { name: "Temps Moteur Final", options: { filter: true, sort: true } },
      { name: "Date de réparation", options: { filter: true, sort: true } },
      { name: "Intervenant", options: { filter: true, sort: true } },
      /* { name: "Commentaire", options: { filter: false, sort: true } }, */
      {
        name: "Options",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonGroup orientation="vertical">
                <Button>
                  <Link
                    to={{
                      pathname: "/AppEntretienPanne",
                      state: {
                        panneToModify: this.props.listAlertePanne.find(
                          panne =>
                            panne.idPanne ===
                            tableMeta.tableData[tableMeta.rowIndex][0]
                        )
                      }
                    }}
                  >
                    <Build style={{ color: "#414040", cursor: "pointer" }} />
                  </Link>
                </Button>
                <Button>
                  <DeleteButton
                    token={this.props.token}
                    tenant={this.props.tenant}
                    idAlert={tableMeta.rowData[0]}
                    machineName={tableMeta.rowData[2]}
                    entretienType={2}
                    dispatch={this.props.dispatch}
                  />
                </Button>
              </ButtonGroup>
            );
          }
        }
      }
    ];
    const options = {
      filter: true,
      filterType: "dropdown",
      selectableRows: "none",
      responsive: "scroll",
      rowsPerPage: 10,
      customToolbar: () => {
        return (
          <CustomToolbar tenant={this.props.tenant} token={this.props.token} />
        );
      },
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: "Aucun résultat trouvé",
          toolTip: "Trier"
        },
        pagination: {
          next: "Page Suivante",
          previous: "Page Précedente",
          rowsPerPage: "Ligne par page:",
          displayRows: "sur"
        },
        toolbar: {
          search: "Recherche",
          downloadCsv: "Télecharger",
          print: "Imprimer",
          viewColumns: "Afficher/Cacher Colonnes",
          filterTable: "Filtre"
        },
        filter: {
          title: "FILTRES",
          reset: "RESET"
        },
        viewColumns: {
          title: "Afficher Colonnes",
          titleAria: "Afficher/Cacher des Colonnes"
        },
        selectedRows: {
          text: "ligne(s) sélectionnées",
          delete: "Supprimer",
          deleteAria: "Supprimer les lignes sélectionnées"
        }
      }
    };
    var data = [];
    this.props.listAlertePanne.length !== 0 &&
      this.props.listAlertePanne.forEach(alert => {
        const filter = alert.isDone ? this.props.entretienFinishtoShow : true;
        moment(alert.date, "YYYY-MM-DDTHH:mm:ss.SSSZ").isBetween(
          moment(this.props.beginDate, "YYYY-MM-DD"),
          moment(this.props.endDate, "YYYY-MM-DD"),
          "day",
          []
        ) &&
          filter &&
          this.buildRow(alert, data);
      });
    return (
      <MUIDataTable
        title={"Anomalies"}
        data={data}
        columns={columns}
        options={options}
      />
    );
  }
}

export default connect(state => ({
  token: state.loginForm.token,
  tenant: state.loginForm.idTenant,
  listAlert: state.data.listAlerte,
  listAlertePanne: state.data.listAlertePanne,
  listTypeCategorie: state.data.listTypeCategorie,
  listType: state.data.listType,
  listCompteurHeure: state.data.listCompteurHeure,
  listResponsable: state.responsable.listResponsable,
  listMachine: state.machine.listMachine,
  pageNom: state.menu.pageName
}))(AppHistoriquePanne);
