import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getCategorieByCategorieID } from "../utils/EntretiensUtils";

import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as Actions from "../actions/GalerieActions";

export default function FiltersGED(props) {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.data.listTypeCategorie);
  const machines = useSelector((state) => state.machine.listMachine);
  const type = useSelector((state) => state.data.listType);
  const filters = useSelector((state) => state.galerie.filters);

  const typeGroupByCategorie = type.map((SC) => {
    const categorieObject = getCategorieByCategorieID(
      categories,
      SC.idCategorie
    );

    return {
      categorieName: categorieObject ? categorieObject.nomCategorie : "INCONNU",
      ...SC,
    };
  });

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        id="filter-header"
        expandIcon={<ArrowDropDownCircleRoundedIcon />}
      >
        <FilterListRoundedIcon />
        <Typography>FILTRES</Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        {/* ELEMENT DU FILTRE */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* FILTRE PAR CATEGORIE */}
          <Grid item xs={12} lg={3} style={{ padding: "12px" }}>
            <Autocomplete
              multiple
              filterSelectedOptions
              id={"categorie"}
              options={categories}
              defaultValue={filters.categorie !== null ? filters.categorie : []}
              getOptionLabel={(option) => option.nomCategorie}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categorie"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(event, value) => {
                dispatch(
                  Actions.setGalerieFilters({ ...filters, categorie: value })
                );
              }}
            />
          </Grid>

          {/*FILTRE PAR SOUS CATEGORIE (Type) */}
          <Grid item xs={12} lg={3} style={{ padding: "12px" }}>
            <Autocomplete
              multiple
              filterSelectedOptions
              id={"type"}
              options={typeGroupByCategorie.sort(
                (a, b) => -b.categorieName.localeCompare(a.categorieName)
              )}
              defaultValue={filters.type !== null ? filters.type : []}
              getOptionLabel={(option) => option.nomType}
              groupBy={(option) => option.categorieName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type d'entretien"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(event, value) => {
                dispatch(
                  Actions.setGalerieFilters({ ...filters, type: value })
                );
              }}
            />
          </Grid>

          {/*FILTRE PAR MACHINE */}
          <Grid item xs={12} lg={3} style={{ padding: "12px" }}>
            <Autocomplete
              multiple
              filterSelectedOptions
              id={"machine"}
              options={machines}
              getOptionLabel={(option) => option.nomMachine}
              defaultValue={filters.machine !== null ? filters.machine : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Machine"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(event, value) => {
                dispatch(
                  Actions.setGalerieFilters({ ...filters, machine: value })
                );
              }}
            />
          </Grid>

          {/* CASE à COCHER */}
          <Grid item xs={12} lg={3} style={{ padding: "12px" }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    value="facture"
                    defaultChecked={filters.facture === true ? true : false}
                  />
                }
                label="Facture"
                onChange={(event) =>
                  dispatch(
                    Actions.setGalerieFilters({
                      ...filters,
                      facture: event.target.checked,
                    })
                  )
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="anomalie"
                    defaultChecked={filters.anomalie === true ? true : false}
                  />
                }
                label="Anomalie"
                onChange={(event) =>
                  dispatch(
                    Actions.setGalerieFilters({
                      ...filters,
                      anomalie: event.target.checked,
                    })
                  )
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
