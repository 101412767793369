/**
 * Classe principale qui affiche la page principale après authentification
 */

import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../css/AppMenu.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { Build, CalendarToday, LocalShippingRounded } from "@material-ui/icons";
import * as Actions from "../actions/DataActions.js";
import { getPictures } from "../actions/GalerieActions";
import * as MachineActions from "../actions/MachinesActions.js";
import * as MenuActions from "../actions/MenuActions.js";
import * as NewEntretienActions from "../actions/NewEntretienActions.js";
import * as ResponsableAction from "../actions/ResponsableActions.js";
import { serveurRequest } from "../api/socket.js";
import CardMaintenance from "./Menu/CardMaintenance.tsx";
import CardDivider from "./Menu/CardDivider.tsx";
import { useTranslation } from "react-i18next";
import AppMaintenance from "./AppMaintenance";
import ModalDetails from "./History/ModalDetails.tsx";
import { set } from "date-fns";

function AppMenu(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const token = useSelector((state) => state.loginForm.token);
  const tenant = useSelector((state) => state.loginForm.idTenant);
  const derniereMAJ = useSelector((state) => state.menu.derniereMAJ);
  const listAlert = useSelector((state) => state.data.listAlerte);
  const listMachine = useSelector((state) => state.machine.listMachine);
  const privilege = useSelector((state) => state.loginForm.privilege);
  const listAlertPanne = useSelector((state) => state.data.listAlertePanne);
  const listCompteurHeure = useSelector(
    (state) => state.data.listCompteurHeure
  );
  const listResponsable = useSelector(
    (state) => state.responsable.listResponsable
  );

  const [openArray, setOpenArray] = React.useState([]);

  const alertPanneSortedByStatus = React.useMemo(() => {
    if (
      listAlertPanne.length === 0 ||
      listMachine.length === 0 ||
      listCompteurHeure.length === 0 ||
      listResponsable.length === 0
    )
      return [];
    let sortedAlert = [];
    if (!listAlertPanne) return sortedAlert;
    setOpenArray([]);

    sortedAlert = listAlertPanne
      .filter(
        (panne) => panne?.faultstatus?.name?.match(/^En cours.*$/) || false
      )
      .map((el) => {
        setOpenArray((arr) => [...arr, false]);
        return {
          ...el,
          responsable: listResponsable.find(
            (responsable) => responsable.idResponsable === el.idResponsable
          ),
          equipment: listMachine.find(
            (machine) => machine.idMachine === el.idMachine
          ),
          engineTime: listCompteurHeure.find(
            (compteur) => compteur.idMachine === el.idMachine
          ),
        };
      })
      .sort((panneA, panneB) => {
        let aDate = panneA.dateFin || panneA.date;
        let bDate = panneB.dateFin || panneB.date;
        return new Date(bDate) - new Date(aDate);
      });
    return sortedAlert;
  }, [listAlertPanne, listMachine, listCompteurHeure, listResponsable]);

  const alertSortedByEquipmentType = React.useMemo(() => {
    const sortedEquipment = [];
    /*     listMachine && (listMachine[0].geofarmData.equipment_type.name = "Autre");
    listMachine &&
      (listMachine[1].geofarmData.equipment_type.name = "Tracteur"); */

    if (!listMachine || !listAlert) return sortedEquipment;

    //Création d'un tableau avec toutes les catégories d'equipements
    const allEquipmentType = new Set(
      listMachine.map(
        (equipment) => equipment.geofarmData?.equipment_type?.name || ""
      )
    );

    //Pour chaque type d'equipent je construit un tableau d'equipement
    allEquipmentType.forEach((equipmentType) => {
      const data = {
        equipmentType: equipmentType,
        numberOfEquipments: 0,
        equipments: [],
      };
      listMachine.forEach((equipment) => {
        if (
          equipment.geofarmData?.equipment_type?.name === equipmentType ||
          (!equipmentType && !equipment.geofarmData?.equipment_type?.name)
        ) {
          //Recherche des alertes liéer à l'equipment
          const alertOfEquipment = listAlert.filter(
            (alert) =>
              alert.idMachine === equipment.idMachine &&
              alert.etat !== 3 &&
              alert.etat !== 0
          );
          alertOfEquipment.length &&
            data.equipments.push({
              ...equipment,
              alerts: alertOfEquipment,
            });
        }
      });

      if (data.equipments.length) {
        //data.equipments[0].alerts[0].etat = 2;
        data.numberOfEquipments = data.equipments.length;
        data.equipments.forEach((equipment) =>
          equipment.alerts.sort((alertA, alertB) => {
            if (alertA.etat < alertB) return -1;
            if (alertA.etat > alertB) return 1;
            return 0;
          })
        );
        sortedEquipment.push(data);
      }
    });

    return sortedEquipment;
  }, [listMachine, listAlert]);

  React.useEffect(() => {
    dispatch(MenuActions.setPageName("Menu"));
    dispatch(NewEntretienActions.setNewEntretienModifyIdAlert(""));
  }, []);

  React.useEffect(() => {
    //Récupère la liste des types d'entretien
    serveurRequest("get:mainMechanical", {
      company_id: tenant,
    }).then((resultat) => {
      dispatch(Actions.setCategorieType(resultat));
    });

    serveurRequest("getLastUpdate", {
      idTenant: tenant,
    }).then((response) => {
      let LastUpdateTemp = "";
      let LastUpdate = "";
      let actualHour = new moment().format("Z").split(":");

      if (response[0]) {
        if (actualHour[0].charAt(0) === "+") {
          LastUpdateTemp = moment(response[0].LastUpdate)
            .add(parseInt(actualHour[0].substring(1), 10), "h")
            .format("YYYY-MM-DD HH:mm");
          LastUpdate = moment(LastUpdateTemp)
            .add(parseInt(actualHour[1], 10), "m")
            .format("YYYY-MM-DD HH:mm");
        } else {
          LastUpdateTemp = moment(response[0].LastUpdate)
            .subtract(parseInt(actualHour[0].substring(1), 10), "h")
            .format("YYYY-MM-DD HH:mm");
          LastUpdate = moment(LastUpdateTemp)
            .subtract(parseInt(actualHour[1], 10), "m")
            .format("YYYY-MM-DD HH:mm");
        }
      }
      dispatch(MenuActions.setDerMAJ(LastUpdate));
    });
  }, []);

  React.useEffect(() => {
    //Récupère les alertes dans la B2D
    dispatch(Actions.getAlerte(token, tenant));

    //Récupère les alertes courantes dans la B2D
    dispatch(Actions.getAlerteCourantes(token, tenant));

    //Récupère les anomalies dans la B2D
    dispatch(Actions.getAlertePanne(token, tenant));

    //Récupère les types d'entretiens (vidange,...) dans la B2D
    dispatch(Actions.getType(token, tenant));

    //Récupère la liste des machines
    dispatch(MachineActions.getMachine(token, tenant));

    //Je récupère la liste des compteurs heures actuel des machines
    dispatch(Actions.getCompteurHeure(token, tenant));

    //Récupère la liste des responsables
    dispatch(ResponsableAction.getResponsable(token, tenant));

    //Récupération des images
    dispatch(getPictures(token, tenant));

    //Récupère le titre des anomalies
    dispatch(Actions.getAnomalieTitle(token, tenant));
  }, []);

  const styles = {
    GridElement: {
      textAlign: "center",
      marginTop: "2%",
      marinBottom: "2%",
    },
    ButtonElement: {
      color: "#dadad9",
      backgroundColor: "#c4161b",
    },
    ButtonElementMobile: {
      color: "#dadad9",
      backgroundColor: "#c4161b",
      maxWidth: "170px",
    },
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {/*icones + boutons*/}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* Affichage destiné aux ordinateurs */}
        <Hidden mdDown={true}>
          <Grid item xs={4} />
          <Grid item xs={12} style={styles.GridElement}>
            <AppMaintenance />
          </Grid>

          <Grid
            item
            xs={2}
            style={{
              ...styles.GridElement,
              display: privilege.canAccessToConfiguration ? "block" : "none",
              textAlign: "center",
              marginTop: "2%",
              marinBottom: "2%",
            }}
          >
            <Link to="/AppConfiguration" role="button">
              <Button size="large" style={styles.ButtonElement}>
                <Build />
                {t("WORD_Configuration")}
              </Button>
            </Link>
          </Grid>
          <Grid item xs={4} />
        </Hidden>

        {/* Affichage destiné aux Tablettes/Smartphone */}
        <Hidden lgUp={true}>
          <Grid item xs={12} style={styles.GridElement}>
            <AppMaintenance mobile={true} />
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              ...styles.GridElement,
              display: privilege.canAccessToConfiguration ? "block" : "none",
              textAlign: "center",
              marginTop: "2%",
              marinBottom: "2%",
            }}
          >
            <Link to="/AppConfiguration" role="button">
              <Button size="large" style={styles.ButtonElementMobile}>
                {t("WORD_Configuration")}
                <Build />
              </Button>
            </Link>
          </Grid>
        </Hidden>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8} style={{ marginTop: "10px" }}>
          <Typography variant="subtitle1" align="center">
            {t("QIREPARE_Last_Update_Date")} : {derniereMAJ}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={8}>
          <hr style={{ width: "100%" }} />
        </Grid>
      </Grid>

      {/* bloc affichant les alertes pannes en cours*/}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ flexWrap: "nowrap-", gap: "43px", padding: "100px" }}
      >
        {alertPanneSortedByStatus.length !== 0 &&
          alertPanneSortedByStatus.map((panne, index) => {
            return (
              <Grid
                key={panne.idAlert + panne.date + "panne"}
                item
                sm={9}
                md={6}
                lg={3}
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                  pointerEvents: "fill",
                }}
                onClick={() => {
                  setOpenArray((arr) =>
                    arr.map((el, i) => (i === index ? !el : el))
                  );
                }}
              >
                <CardDivider
                  failureColor={panne?.faultstatus?.color}
                  title={`${panne?.equipment?.nomMachine}`}
                />

                <Grid
                  container
                  direction="column"
                  style={{
                    backgroundColor: "#DADAD9",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "0 0 10px 10px",
                  }}
                >
                  <Grid item key={panne.idAlert + panne.date + "item"}>
                    <Chip
                      label={
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {panne.faultstatus.name}
                        </div>
                      }
                      style={{
                        border: "1px solid #414040",
                        cursor: "pointer",
                        pointerEvents: "fill",
                      }}
                    />
                  </Grid>
                  {panne?.title && (
                    <Grid
                      item
                      key={panne.idAlert + panne.date + "title"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "15px",
                      }}
                    >
                      <Typography style={{ marginLeft: "10px" }}>
                        {t("WORD_Title")}:{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {panne?.title}
                      </Typography>
                    </Grid>
                  )}
                  {panne?.description && (
                    <Grid
                      item
                      key={panne.idAlert + panne.date + "describ"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "15px",
                      }}
                    >
                      <Typography style={{ marginLeft: "10px" }}>
                        {t("WORD_Description")}:{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {panne?.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <ModalDetails
                  value={panne}
                  hiddenButtonModal={true}
                  openProps={openArray?.[index]}
                />
              </Grid>
            );
          })}
      </Grid>

      {/* bloc affichant les alertes dépassés*/}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ flexWrap: "nowrap-", marginTop: "20px" }}
      >
        {alertSortedByEquipmentType.map((equipmentType) => {
          return (
            <Grid
              key={equipmentType}
              container
              md={11}
              lg={6}
              direction="column"
              alignItems="center"
              style={{ rowGap: "8px", display: "inline-flex" }}
            >
              <CardDivider
                title={`${equipmentType.numberOfEquipments} ${t(
                  `EQUIPMENT_TYPE_${equipmentType.equipmentType}`
                )}s`}
              />

              {/* Affichage en priorité des entretiens Critique (etat 2) */}
              {equipmentType.equipments
                .flatMap((equipment) =>
                  equipment.alerts.filter((alert) => alert.etat === 2)
                )
                .map((alert) => {
                  return (
                    <CardMaintenance
                      sheduleMaintenace={alert}
                      showEquipmentName
                      key={alert.idAlert}
                    />
                  );
                })}

              {/* Affichage des entretiens Urgent (etat 1)*/}
              {equipmentType.equipments.map((equipment) => {
                const etatID = 1;
                if (
                  !equipment.alerts.length ||
                  equipment.alerts.every((alert) => alert.etat !== etatID)
                ) {
                  return null;
                }

                return (
                  <Accordion key={equipment.idMachine}>
                    <AccordionSummary style={{ backgroundColor: "#DADAD9" }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item key={equipment.nomMachine + "item2"}>
                          <Typography>{equipment.nomMachine}</Typography>
                        </Grid>
                        <Grid item key={equipment.nomMachine + "item3"}>
                          <Chip
                            label={
                              <span>
                                <b>
                                  {
                                    equipment.alerts.filter(
                                      (alert) => alert.etat === etatID
                                    ).length
                                  }
                                </b>{" "}
                                Entretiens
                              </span>
                            }
                            style={{ border: "1px solid #414040" }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        style={{ width: "100%", rowGap: "16px" }}
                      >
                        {equipment.alerts
                          .filter((alert) => alert.etat === etatID)
                          .map((alert) => {
                            return (
                              <Grid item key={alert.idAlert}>
                                <CardMaintenance
                                  sheduleMaintenace={alert}
                                  key={alert.idAlert}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default AppMenu;
