import Constants from "../Constants";

export const getNumberOfAnomalieWithFilters = (pictures, filters, SCAT) => {
  var numberOfanomalie = 0;

  pictures &&
    pictures.forEach(picture => {
      if (picture.isAnomalie && isPictureInFilters(picture, filters, SCAT)) {
        //Si la photo est une anomalie
        numberOfanomalie++;
      }
    });

  return numberOfanomalie;
};

export const getNumberOfCourantWithFilters = (pictures, filters, SCAT) => {
  var numberOfEntretien = 0;

  pictures &&
    pictures.forEach(picture => {
      if (picture.isEntretien && isPictureInFilters(picture, filters, SCAT)) {
        //Si la photo est une anomalie
        numberOfEntretien++;
      }
    });

  return numberOfEntretien;
};

export const isPictureInCategorie = (picture, anomalie, entretienCourant) => {
  if (anomalie && picture.isAnomalie) {
    return true;
  }

  if (
    entretienCourant &&
    ((picture.isEntretien && picture.isFacture) ||
      (picture.isEntretien && !picture.isFacture))
  ) {
    return true;
  }

  return false;
};

export const isPictureInFilters = (picture, filters, SCAT) => {
  //Filtre par machine
  if (filters.machine !== null && filters.machine.length > 0) {
    if (
      !filters.machine.some(machine => machine.idMachine === picture.IdMachine)
    ) {
      return false;
    }
  }

  //Filtre par anomalie
  if (filters.anomalie !== null) {
    if (filters.anomalie) {
      if (!picture.isAnomalie) return false;
    }
  }

  //Filtre par facture
  if (filters.facture !== null) {
    if (filters.facture) {
      if (!picture.isFacture) {
        return false;
      }
    }
  }

  //Filtre par Sous-Categorie
  if (filters.type !== null && filters.type.length > 0) {
    if (!filters.type.some(Type => Type.idType === picture.idType))
      return false;
  }

  //Filtre par Categorie
  if (filters.categorie !== null && filters.categorie.length > 0) {
    //On cherche l'objet sous Categorie liée à la photo
    const pictureScat = SCAT.find(_SCAT => _SCAT.idType === picture.idType);

    if (pictureScat !== undefined) {
      //Maintenant que nous avons l'objet sous categorie, nous recuperons l'id de la categorie auquel il est liée, pour le comparé à notre filtre
      if (
        !filters.categorie.some(
          CAT => CAT.idCategorie === pictureScat.idCategorie
        )
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

export function getCompleteURLFromPictureObject(url) {
  return `https://${Constants.HOST_PICTURE}:${
    Constants.PORT_PICTURE
  }${url.substr(1)}`;
}
