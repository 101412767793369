import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";
import {
  Paper,
  Button,
  Divider,
  Box,
  Typography,
  Icon,
} from "@material-ui/core";
import { t } from "i18next";

function AppMaintenance(props) {
  const { mobile } = props;
  const privileges = useSelector((state) => state.loginForm.privilege);

  const styleDivider = { marginLeft: "10px", marginRight: "10px" };
  const styleBut = {
    padding: "10px 20px",
    backgroundColor: "#eeeeee",
  };

  return (
    <Box
      sx={{
        display: "flex",
        direction: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Paper>
        <Button style={styleBut} component={Link} to="/AppEntretienCourant">
          <Icon>
            <i className="material-icons left">repeat</i>
          </Icon>
          {!mobile && (
            <>
              <Divider style={styleDivider} orientation="vertical" flexItem />
              <Typography style={{ color: "#551A8B" }}>
                {t("WORD_Current")}
              </Typography>
            </>
          )}
        </Button>
      </Paper>
      <Paper>
        <Button style={styleBut} component={Link} to="/AppEntretienProgrammer">
          <Icon>
            <i className="material-icons left">access_time</i>
          </Icon>
          {!mobile && (
            <>
              <Divider style={styleDivider} orientation="vertical" flexItem />
              <Typography style={{ color: "#551A8B" }}>
                {t("WORD_Schedule")}
              </Typography>
            </>
          )}
        </Button>
      </Paper>
      <Paper>
        <Button style={styleBut} component={Link} to="/AppEntretienPanne">
          <Icon>
            <i className="material-icons left">report</i>
          </Icon>
          {!mobile && (
            <>
              <Divider style={styleDivider} orientation="vertical" flexItem />
              <Typography style={{ color: "#551A8B" }}>
                {t("WORD_Failure")}
              </Typography>
            </>
          )}
        </Button>
      </Paper>
      <Paper>
        <Button style={styleBut} component={Link} to="/AppHistorique">
          <Icon>
            <i className="material-icons left">history</i>
          </Icon>
          {!mobile && (
            <>
              <Divider style={styleDivider} orientation="vertical" flexItem />
              <Typography style={{ color: "#551A8B" }}>
                {t("WORD_Historical")}
              </Typography>
            </>
          )}
        </Button>
      </Paper>

      {
        //AFFICHAGE DE L'ECRAN GED SI L'UTILISATEUR Y A ACCES
        privileges.canEditPhoto && (
          <Paper>
            <Button style={styleBut} component={Link} to="/AppGalerie">
              <Icon>
                <PhotoLibraryRoundedIcon />
              </Icon>
              {!mobile && (
                <>
                  <Divider
                    style={styleDivider}
                    orientation="vertical"
                    flexItem
                  />
                  <Typography style={{ color: "#551A8B" }}>"GED"</Typography>
                </>
              )}
            </Button>
          </Paper>
        )
      }
    </Box>
  );
}

export default AppMaintenance;
