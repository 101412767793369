import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import MyDatePicker from "../../utils/datePicker";
import { Moment } from "moment";
import moment from "moment";

type ModalDateChoiceProps = {
  setBeginDate: (date: Moment) => void;
  beginDate: Moment;
  setEndDate: (date: Moment) => void;
  endDate: Moment;
};

export default function ModalDateChoice(props: ModalDateChoiceProps) {
  const { setBeginDate, beginDate, setEndDate, endDate } = props;
  const [open, setOpen] = React.useState(false);

  const [startDateChoosen, setStartDateChoosen] = React.useState(beginDate);
  const [endDateChoosen, setEndDateChoosen] = React.useState(endDate);

  const { t } = useTranslation();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCloseAccept() {
    setStartDateChoosen(startDateChoosen);
    setEndDateChoosen(endDateChoosen);
    setBeginDate(startDateChoosen);
    setEndDate(endDateChoosen);
    setOpen(false);
  }

  const handleEndDateChange = (end_date: any) => {
    const dateObject = moment(end_date).hour(23).minute(59);
    setEndDateChoosen(dateObject);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("MODAL_DATE_CHOICE_Icon_Title")}>
        <IconButton onClick={handleClickOpen} size="medium">
          <DateRange />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={useMediaQuery(useTheme().breakpoints.down("md"))}
        style={{ textAlign: "center" }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ fontWeight: "750", fontSize: "1.5rem" }}
        >
          {t("MODAL_DATE_CHOICE_Dialog_Title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "40px" }}>
            {t("MODAL_DATE_CHOICE_Dialog_Content")}
          </DialogContentText>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={10} md={6}>
              <MyDatePicker
                label={t("MODAL_DATE_CHOICE_Start_Date")}
                defaultValue={beginDate}
                onChange={(e) => setStartDateChoosen(e)}
              />
            </Grid>
            <Grid item xs={10} md={6}>
              <MyDatePicker
                label={t("MODAL_DATE_CHOICE_End_Date")}
                defaultValue={endDate}
                onChange={(e) => handleEndDateChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: "750", fontSize: "1rem" }}
          >
            {t("MODAL_DATE_CHOICE_Cancel")}
          </Button>
          <Button
            onClick={handleCloseAccept}
            color="primary"
            style={{ fontWeight: "750", fontSize: "1rem" }}
          >
            {t("MODAL_DATE_CHOICE_Accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
