import requestType from "../utils/requestType";
import { serveurRequest, SendFileToServer } from "../api/socket.js";
import { getPictures } from "./GalerieActions";
import moment from "moment";
import { formatEngineTimeForServer } from "../utils/format.ts";
import { endOfDay, formatISO, parseISO } from "date-fns";
import { getAlertePanne } from "./DataActions";

export const NEW_ENTRETIEN_PANNE = requestType("AJOUT_NOUVEAU_ENTRETIEN_PANNE");

export const SET_TITLE_PANNE = "SET_TITLE_PANNE";
export const setTitlePanne = (title) => async (dispatch) => {
  dispatch({ type: SET_TITLE_PANNE, title });
};
export const SET_FAULT_STATUS_PANNE = "SET_FAULT_STATUS_PANNE";
export const setFaultStatusPanne = (faultStatus) => async (dispatch) => {
  dispatch({ type: SET_FAULT_STATUS_PANNE, faultStatus });
};
export const SET_ID_MACHINE_PANNE = "SET_ID_MACHINE_PANNE";
export const setIdMachinePanne = (idMachine) => async (dispatch) => {
  dispatch({ type: SET_ID_MACHINE_PANNE, idMachine });
};
export const SET_ID_RESPONSABLE_PANNE = "SET_ID_RESPONSABLE_PANNE";
export const setIdResponsablePanne = (idResponsable) => async (dispatch) => {
  dispatch({ type: SET_ID_RESPONSABLE_PANNE, idResponsable });
};
export const SET_ID_INTERVENANT_PANNE = "SET_ID_INTERVENANT_PANNE";
export const setIdIntervenantPanne = (idResponsable) => async (dispatch) => {
  dispatch({ type: SET_ID_INTERVENANT_PANNE, idResponsable });
};
export const SET_TPS_MOTEUR_PANNE = "SET_TPS_MOTEUR_PANNE";
export const setTpsMoteurPanne = (tpsMoteur) => async (dispatch) => {
  dispatch({ type: SET_TPS_MOTEUR_PANNE, tpsMoteur });
};
export const SET_DESCRIPTION_PANNE = "SET_DESCRIPTION_PANNE";
export const setDescriptionPanne = (description) => async (dispatch) => {
  dispatch({ type: SET_DESCRIPTION_PANNE, description });
};
export const SET_COMMENTAIRE_PANNE = "SET_COMMENTAIRE_PANNE";
export const setCommentairePanne = (commentaire) => async (dispatch) => {
  dispatch({ type: SET_COMMENTAIRE_PANNE, commentaire });
};
export const SET_TPS_MOTEUR_FIN_PANNE = "SET_TPS_MOTEUR_FIN_PANNE";
export const setTpsMoteurFinPanne = (tpsMoteur) => async (dispatch) => {
  dispatch({ type: SET_TPS_MOTEUR_FIN_PANNE, tpsMoteur });
};
export const SET_DATE_FIN = "SET_DATE_FIN";
export const setDateFin = (dateFin) => async (dispatch) => {
  dispatch({ type: SET_DATE_FIN, dateFin });
};
export const SET_PHOTOS_PANNE = "SET_PHOTOS_PANNE";
export const setPhotoPanne =
  (allPhotosPanne, newPhotosPanne) => async (dispatch) => {
    dispatch({
      type: SET_PHOTOS_PANNE,
      photosPanne: allPhotosPanne.concat(newPhotosPanne),
    });
  };
export const SET_PHOTOS_PANNE_FACTURE = "SET_PHOTOS_PANNE_FACTURE";
export const setPhotoPanneFacture =
  (allPhotosPanneFacture, newPhotosPanneFacture) => async (dispatch) => {
    dispatch({
      type: SET_PHOTOS_PANNE_FACTURE,
      photosPanneFacture: allPhotosPanneFacture.concat(newPhotosPanneFacture),
    });
  };
export const SET_IS_FINISH = "SET_IS_FINISH";
export const setIsFinish = (isFinish) => async (dispatch) => {
  dispatch({ type: SET_IS_FINISH, isFinish });
};
export const SET_IS_LOADING = "SET_IS_LOADING";
export const setIsLoading = (isLoading) => async (dispatch) => {
  dispatch({ type: SET_IS_LOADING, isLoading });
};
export const AJOUT_ENTRETIEN_PANNE = requestType("AJOUT_ENTRETIEN_PANNE");
export const newEntretienPanne = () => async (dispatch) => {
  dispatch({ type: AJOUT_ENTRETIEN_PANNE });
};
export const RESET_ENTRETIEN_PANNE = "RESET_ENTRETIEN_PANNE";
export const resetPanneUI = () => async (dispatch) => {
  dispatch({ type: RESET_ENTRETIEN_PANNE });
};

export const AjoutEntretienPanne =
  (
    token,
    tenant,
    idMachine,
    tpsMoteur,
    title,
    faultStatus,
    idResponsable,
    description,
    tpsMoteurFin,
    dateFin,
    idIntervenant,
    commentaire,
    photosPanne,
    photosPanneFacture,
    setUploadPercent,
    progressIndexCallback
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: AJOUT_ENTRETIEN_PANNE.Request });

    if (
      idMachine.length === 0 ||
      tpsMoteur.length === 0 ||
      idResponsable.length === 0
    ) {
      dispatch({ type: AJOUT_ENTRETIEN_PANNE.Failure });
      return alert(
        "Veuillez remplir tout les champs (Machine, Temps Moteur, Responsable)."
      );
    } else if (
      faultStatus?.name === ("Panne réparée" || "Dépannage") &&
      idIntervenant === null &&
      tpsMoteurFin.length === 0
    ) {
      dispatch({ type: AJOUT_ENTRETIEN_PANNE.Failure });
      return alert(
        "Veuillez remplir tout les champs (Responsable qui est intervenu et l'heure machine lors de la réparation)."
      );
    }

    const state = getState();

    const selectedEquipment = state.machine.listMachine.find((machine) => {
      return machine.idMachine === idMachine;
    });

    const currentDate = new Date();
    const endDate = dateFin ? dateFin.toDate() : null;

    if (endDate) {
      endDate.setUTCHours(currentDate.getUTCHours());
      endDate.setUTCMinutes(currentDate.getUTCMinutes() + 1);
    }

    serveurRequest("POST:maintenance/failure", {
      idTenant: tenant,
      idMachine: idMachine,
      idDevice: selectedEquipment.geofarmData?.equipment_association?.device_id,
      title: title,
      faultStatus: JSON.stringify(faultStatus),
      dateCreation: formatISO(new Date()),
      tpsMoteur: formatEngineTimeForServer(tpsMoteur),
      idResponsable: idResponsable,
      description: description,
      tpsMoteurFin: tpsMoteurFin
        ? formatEngineTimeForServer(tpsMoteurFin)
        : null,
      idIntervenant: idIntervenant,
      commentaire: commentaire,
      dateFin: endDate ? formatISO(endDate) : null,
    }).then(
      async (res) => {
        try {
          const anomalieID = res.id;

          //Variable qui nous inquique l'index de la photo que nous transferons
          var indexPicture = 0;

          //On commence par envoyé nos factures, une par une s'il y en à.
          for (
            var photoIndex = 0;
            photoIndex < photosPanneFacture.length;
            photoIndex++
          ) {
            //Augmente de 1 l'index de la photo
            indexPicture++;

            //On applique cette modification au state
            progressIndexCallback(indexPicture);

            const file = photosPanneFacture[photoIndex];

            const dataInfo = {
              entretienID: null,
              machineID:
                selectedEquipment.geofarmData?.equipment_association
                  ?.equipment_id,
              isFacture: true,
              isEntretienPhoto: false,
              isAnomalie: true,
              anomalieID: anomalieID,
              entretienTypeID: null,
            };

            await SendFileToServer(dataInfo, file, setUploadPercent);
          }

          for (
            var photoIndex = 0;
            photoIndex < photosPanne.length;
            photoIndex++
          ) {
            const file = photosPanne[photoIndex];
            //Augmente de 1 l'index de la photo
            indexPicture++;

            //On applique cette modification au state
            progressIndexCallback(indexPicture);

            const dataInfo = {
              entretienID: null,
              machineID: idMachine,
              isFacture: false,
              isEntretienPhoto: false,
              isAnomalie: true,
              anomalieID: anomalieID,
              entretienTypeID: null,
            };
            await SendFileToServer(dataInfo, file, setUploadPercent);
          }

          dispatch({
            type: AJOUT_ENTRETIEN_PANNE.Success,
          });
          dispatch(setIsFinish(true));

          //Requete pour recuperer les photos qui viennent d'être envoyé
          dispatch(getPictures(token, tenant));
          dispatch(getAlertePanne(token, tenant));
          return alert("Votre anomalie a bien été enregistrée");
        } catch (error) {
          dispatch({
            type: AJOUT_ENTRETIEN_PANNE.Failure,
          });
          console.error(
            "Erreur lors de l'upload de vos photos :",
            JSON.stringify(error)
          );
          return alert("Erreur lors de l'upload de vos photos sur le serveur");
        }
      },
      (rej) => {
        dispatch({ type: AJOUT_ENTRETIEN_PANNE.Failure });
        return alert("Erreur lors de la création de l'anomalie");
      }
    );
  };

export const ModifEntretienPanne =
  (
    token,
    tenant,
    idPanne,
    idMachine,
    tpsMoteur,
    title,
    faultStatus,
    idResponsable,
    description,
    tpsMoteurFin,
    dateCreation,
    dateFin,
    idIntervenant,
    commentaire,
    photosPanne,
    photosPanneFacture,
    setUploadPercent,
    progressIndexCallback
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: AJOUT_ENTRETIEN_PANNE.Request,
    });

    //Récupération du store
    const state = getState();

    const equipment = state.machine.listMachine.find(
      (equipment) =>
        idMachine === equipment.geofarmData.equipment_association.equipment_id
    );

    serveurRequest("PUT:maintenance/failure", {
      idTenant: tenant,
      idPanne: idPanne,
      idMachine: idMachine,
      idDevice: equipment.geofarmData.equipment_association.device_id,
      dateCreation: formatISO(parseISO(dateCreation)),
      tpsMoteur: formatEngineTimeForServer(tpsMoteur),
      title: title,
      faultStatus: JSON.stringify(faultStatus),
      idResponsable: idResponsable,
      description: description,
      tpsMoteurFin: formatEngineTimeForServer(tpsMoteurFin),
      idIntervenant: idIntervenant,
      commentaire: commentaire === "null" ? null : commentaire,
      dateFin: dateFin,
    }).then(
      async (res) => {
        //TODO: Tester s'il y à eu un changement afin de mettre à jour les photos que si nécessaire
        //Maintenant nous allons envoyer au serveurs les photos qui ont déjà envoyées, afin de les mettres à jour si jamais il y à eu un changement
        /* await serveurRequest("updatePannePicture", {
          anomalieID: idPanne,
          pictures: photosAlreadyUploaded,
          token: token,
        }).catch((err) => {
          console.error(err);
        }); */

        //Variable qui nous inquique l'index de la photo que nous transferons
        var indexPicture = 0;

        //On applique cette modification au state
        progressIndexCallback(indexPicture);

        //On commence par envoyé nos factures, une par une s'il y en à.
        for (
          var photoIndex = 0;
          photoIndex < photosPanneFacture.length;
          photoIndex++
        ) {
          //Augmente de 1 l'index de la photo
          indexPicture++;

          //On applique cette modification au state
          progressIndexCallback(indexPicture);

          const file = photosPanneFacture[photoIndex];

          const dataInfo = {
            entretienID: null,
            machineID: idMachine,
            isFacture: true,
            isEntretienPhoto: false,
            isAnomalie: true,
            anomalieID: idPanne,
            entretienTypeID: null,
          };

          await SendFileToServer(dataInfo, file, setUploadPercent);
        }

        //On  envoye les photos que l'utilisateur vient de rajouter
        for (
          var photoIndex = 0;
          photoIndex < photosPanne.length;
          photoIndex++
        ) {
          const file = photosPanne[photoIndex];

          //Augmente de 1 l'index de la photo
          indexPicture++;

          //On applique cette modification au state
          progressIndexCallback(indexPicture);

          const dataInfo = {
            entretienID: null,
            machineID: idMachine,
            isFacture: false,
            isEntretienPhoto: false,
            isAnomalie: true,
            anomalieID: idPanne,
            entretienTypeID: null,
          };

          await SendFileToServer(dataInfo, file, setUploadPercent);
        }

        dispatch({
          type: AJOUT_ENTRETIEN_PANNE.Success,
        });
        dispatch(setIsFinish(true));

        //Requete pour recuperer les photos qui viennent d'être envoyé
        dispatch(getPictures(token, tenant));
        return alert("Votre anomalie a bien été modifié");
      },
      (rej) => {
        dispatch({ type: AJOUT_ENTRETIEN_PANNE.Failure });
        return alert("Erreur lors de la modification de l'anomalie");
      }
    );
  };
