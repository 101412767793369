import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import reducer from "./reducers";

const middleware = applyMiddleware(promise(), thunk, logger);
const composeEnhancers = composeWithDevTools({
  trace: true
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export default createStore(reducer, composeEnhancers(middleware));
