import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { dropAlert } from "../actions/DataActions";
import { DeleteForever } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

function DeleteButton(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginForm.token);
  const tenant = useSelector((state) => state.loginForm.idTenant);

  const entretienName =
    props.entretienType === 0
      ? "entretien programmé"
      : props.entretienType === 1
      ? "entretien courant"
      : "anomalie";

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickDelete() {
    //EntretienType = 0(prog) ou 1(courant) ou 2(anomalie)
    setOpen(false);
    props.setOpenParent(false);
    dispatch(dropAlert(props.idAlert, token, tenant, props.entretienType));
  }

  return (
    <div>
      <DeleteForever
        onClick={handleClickOpen}
        style={{
          color: "#414040",
          cursor: "pointer",
        }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Supprimer l'{entretienName}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous supprimer l'{entretienName} concernant la machine
            {" " + props.machineName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleClickDelete} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteButton.propTypes = {
  machineName: PropTypes.string.isRequired,
  idAlert: PropTypes.number.isRequired,
  entretienType: PropTypes.number.isRequired,
  setOpenParent: PropTypes.func,
};

export default DeleteButton;
