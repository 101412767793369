import { TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setTpsMoteurCourant } from "../../../../../actions/EntretienCourantAction";
import { getLastTpsMoteurForMachineID } from "../../../../../utils/EntretiensUtils";

export type EngineTimeProps = {
  defaultValue?: string;
};

function EngineTime(props: EngineTimeProps) {
  const { control, errors, watch, setValue } = useFormContext();

  const dispatch = useDispatch();

  const equipmentSelected = watch("equipment");

  const allEngineTime = useSelector((state) => state.data.listCompteurHeure);

  React.useEffect(() => {
    if (equipmentSelected) {
      //Met à jour le champs tpsMoteurInput avec le tpsMoteur de la machine choisie
      let defaultCompteur = getLastTpsMoteurForMachineID(
        allEngineTime,
        equipmentSelected
      );

      setValue("tpsMoteurInput", defaultCompteur);

      //On met aussi à jour le store avec le temps moteur trouvé
      dispatch(setTpsMoteurCourant(defaultCompteur));
    }
  }, [equipmentSelected]);

  return (
    <Controller
      as={<TextField />}
      defaultValue=""
      fullWidth
      type="number"
      name="tpsMoteurInput"
      id="tpsMoteurInput"
      label="Heure moteur"
      InputLabelProps={{ shrink: true }}
      error={errors.tpsMoteurInput ? true : false}
      helperText={errors.tpsMoteurInput && errors.tpsMoteurInput.message}
      control={control}
      rules={{
        required: "Veuillez entrer le compteur horaire de la machine",
        min: {
          value: 0,
          message: "Le compteur ne peut pas être en dessous de 0",
        },
      }}
      onChange={([event]) => {
        //On met à jour le store avec le temps moteur
        dispatch(setTpsMoteurCourant(event.target.value));

        return event.target.value;
      }}
    />
  );
}

export default EngineTime;
