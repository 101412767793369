/**
 * Classe de footer, fixe
 */

import React, { Component } from "react";
import "../css/AppFooter.css";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

var Constants = require("../Constants.json");

const styles = {
  appBar: {
    top: "auto",
    height: "30px",
    bottom: 0,
    textAlign: "center",
    zIndex: "99"
  },
  toolbar: {
    minHeight: "30px",
    justifyContent: "space-between"
  }
};

class AppFooter extends Component {
  render() {
    return (
      <AppBar position="fixed" color="primary" style={styles.appBar}>
        <Toolbar style={styles.toolbar}>
          <Typography variant="subtitle1">
            <a
              href="https://www.questinnov.com/"
              target="_blank"
              style={{ color: "#dadad9" }}
              rel="noopener noreferrer"
            >
              Copyright © {Constants.COPYRIGHT}
            </a>
          </Typography>
          <Typography variant="subtitle1" style={{ color: "#dadad9" }}>
            Version {Constants.VERSION}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

export default AppFooter;
