import { InputAdornment, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienAFaire } from "../../../../../../actions/EntretienProgrammerActions";
import MyDatePicker from "../../../../../../utils/datePicker";

type FirstDateReminderProps = {
  defaultValue?: string | null;
};

function FirstDateReminder(props: FirstDateReminderProps) {
  const { control, errors, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = ([date]) => {
    //dispatch(setNewEntretienAFaire(numericValue));

    return date;
  };

  React.useEffect(() => {
    if (props.defaultValue) {
      setValue("reminder_date", moment(props.defaultValue));
    } else if (moment.isMoment(getValues().maximum_date)) {
      setValue(
        "reminder_date",
        getValues().maximum_date.clone().subtract(1, "days")
      );
    }
  }, []);

  return (
    <Controller
      control={control}
      name="reminder_date"
      onChange={handleChange}
      as={(props) => {
        return (
          <MyDatePicker
            {...props}
            defaultValue={props.value}
            label={t("WORD_Reminder")}
            inputVariant="outlined"
            style={{ margin: "8px auto" }}
          />
        );
      }}
    />
  );
}

export default FirstDateReminder;
