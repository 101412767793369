import requestType from "../utils/requestType";
import { serveurRequest } from "../api/socket";
import moment from "moment";

export const ADD_ENTRETIEN = requestType("AJOUT_NOUVEAU_ENTRETIEN");
//export const MODIF_ENTRETIEN = requestType("MODIF_ENTRETIEN");

export const SET_TYPE_NEW_ENTRETIEN = "SET_TYPE_NEW_ENTRETIEN";
export const setNewEntretienType = typeEntretien => async dispatch => {
  dispatch({ type: SET_TYPE_NEW_ENTRETIEN, typeEntretien });
};

export const SET_MACHINE_NEW_ENTRETIEN = "SET_MACHINE_NEW_ENTRETIEN";
export const setNewEntretienMachine = machine => async dispatch => {
  dispatch({ type: SET_MACHINE_NEW_ENTRETIEN, machine });
};

export const SET_DATE_CREATION_NEW_ENTRETIEN =
  "SET_DATE_CREATION_NEW_ENTRETIEN";
export const setNewEntretienDateCreation = date => async dispatch => {
  dispatch({ type: SET_DATE_CREATION_NEW_ENTRETIEN, date });
};

export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const setNewEntretienDescription = description => async dispatch => {
  dispatch({ type: SET_DESCRIPTION, description });
};

export const SET_COMMENTAIRE = "SET_COMMENTAIRE";
export const setNewEntretienCommentaire = commentaire => async dispatch => {
  dispatch({ type: SET_COMMENTAIRE, commentaire });
};

export const SET_RESPONSABLE_NEW_ENTRETIEN = "SET_RESPONSABLE_NEW_ENTRETIEN";
export const setNewEntretienResponsable = listResponsable => async dispatch => {
  dispatch({ type: SET_RESPONSABLE_NEW_ENTRETIEN, listResponsable });
};

export const SET_A_FAIRE = "SET_A_FAIRE";
export const setNewEntretienAFaire = aFaire => async dispatch => {
  dispatch({ type: SET_A_FAIRE, aFaire });
};

export const SET_RAPPEL = "SET_RAPPEL";
export const setNewEntretienRappel = rappel => async dispatch => {
  dispatch({ type: SET_RAPPEL, rappel });
};

export const SET_FAIT_A = "SET_FAIT_A";
export const setNewEntretienFaitA = faitA => async dispatch => {
  dispatch({ type: SET_FAIT_A, faitA });
};

export const SET_RENOUVELER = "SET_RENOUVELER";
export const setNewEntretienRenouveler = renouveler => async dispatch => {
  dispatch({ type: SET_RENOUVELER, renouveler });
};

export const SET_EFFECTUER = "SET_EFFECTUER";
export const setNewEntretienEffectuer = effectuer => async dispatch => {
  dispatch({ type: SET_EFFECTUER, effectuer });
};

export const SET_INPROGRESS = "SET_INPROGRESS";
export const setNewEntretienInProgress = InProgress => async dispatch => {
  dispatch({ type: SET_INPROGRESS, InProgress });
};

export const SET_MODIFY_ID_ALERT = "SET_MODIFY_ID_ALERT";
export const setNewEntretienModifyIdAlert = idAlert => async dispatch => {
  dispatch({ type: SET_MODIFY_ID_ALERT, idAlert });
};

export const SET_IS_FINISH = "SET_IS_FINISH";
export const setisFinish = isFinish => async dispatch => {
  dispatch({ type: SET_IS_FINISH, isFinish });
};

export const RESET_ENTRETIEN = "RESET_ENTRETIEN";
export const resetEntretien = () => async dispatch => {
  dispatch({ type: RESET_ENTRETIEN });
};

export const addEntretien = (
  token,
  idTenant,
  idType,
  idMachine,
  dateCreation,
  description,
  idResponsable,
  commentaire,
  heureLimite,
  rappel,
  faitA,
  renouveler,
  effectuer
) => async dispatch => {
  dispatch({ type: ADD_ENTRETIEN.Request });
  dispatch(setNewEntretienInProgress(true));
  //TODO: valider la liste nécessaire à compléter "description" ?
  //TODO: Date de création automatiquement récupérée sur création en UTC ?
  if (
    idType.length === 0 ||
    idMachine.length === 0 ||
    dateCreation.length === 0 ||
    idResponsable.length === 0 ||
    heureLimite.length === 0 ||
    rappel.length === 0 ||
    (effectuer === "1" && faitA.length === 0)
  ) {
    dispatch({ type: ADD_ENTRETIEN.Failure });
    dispatch(setNewEntretienInProgress(false));
    dispatch(setisFinish(true));
    return alert("Veuillez remplir tout les champs."); //TODO: Faire un test par champs de saisie ?
  }
  var dateFin = "NULL";
  var etat = 0;
  var compteurMachineFin = null;

  if (effectuer === "1") {
    dateFin = new moment().format("YYYY-MM-DD");
    etat = 3;
    serveurRequest("getStatMachine", {
      token: token,
      idMachine: idMachine
    }).then(
      rep => {
        compteurMachineFin = Math.round(rep[rep.length - 1].tempsMoteur / 3600);
      },
      rej => {
        dispatch({ type: ADD_ENTRETIEN.Failure });
        dispatch(setNewEntretienInProgress(false));
        dispatch(setisFinish(true));
        return alert("Erreur lors de la création de l'entretien");
      }
    );
  } else {
    commentaire = "";
  }

  serveurRequest("getStatMachine", { token: token, idMachine: idMachine }).then(
    rep => {
      serveurRequest("newAlert", {
        token: token,
        idResponsable: idResponsable,
        idType: idType,
        idMachine: idMachine,
        idTenant: idTenant,
        dateCreation: dateCreation,
        description: description,
        heureLimite: heureLimite,
        rappel: rappel,
        heureActuel: Math.round(rep[rep.length - 1].tempsMoteur / 3600),
        etat: etat,
        commentaire: commentaire,
        dateFin: dateFin,
        compteurMachineFin: compteurMachineFin
      }).then(
        res => {},
        rej => {
          dispatch({ type: ADD_ENTRETIEN.Failure });
          dispatch(setNewEntretienInProgress(false));
          dispatch(setisFinish(true));
          return alert("Erreur lors de la création de l'entretien");
        }
      );
      dispatch({ type: ADD_ENTRETIEN.Success });
      dispatch(resetEntretien());
      dispatch(setNewEntretienInProgress(false));
      dispatch(setisFinish(true));
      return alert("Votre entretien a bien été ajouté");
    },
    rej => {
      dispatch({ type: ADD_ENTRETIEN.Failure });
      dispatch(setNewEntretienInProgress(false));
      dispatch(setisFinish(true));
      return alert("Erreur lors de la création de l'entretien");
    }
  );

  return;
};
