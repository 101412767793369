import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienResponsable } from "../../../../../actions/EntretienProgrammerActions";

type ManagerProps = { defaultValue?: Array<Record<string, unknown>> };

function Manager(props: ManagerProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isDone = useSelector(
    (state: any) => state.entretienProgrammer.effectuer
  );
  const allManager = useSelector(
    (state: any) => state.responsable.listResponsable
  );
  useEffect(() => {
    allManager.push({
      nom: t("WORD_All_people"),
      prenom: "",
      idResponsable: -1,
    });
  }, []);

  return (
    <Controller
      control={control}
      rules={{
        validate: (value) => value.length > 0 || !isDone,
      }}
      name="Responsables"
      defaultValue={props.defaultValue || []}
      onChange={([event, value]) => {
        dispatch(setNewEntretienResponsable(value));

        return value;
      }}
      as={(props) => (
        <Autocomplete
          {...props}
          onChange={(event, value: any) =>
            value?.[value.length - 1]?.idResponsable === -1
              ? props.onChange(
                  event,
                  allManager.filter(
                    (m) =>
                      m.idResponsable !== -1 ||
                      value.forEach((e, index) => {
                        index !== value.length - 1 &&
                          value.idResponsable !== e.idResponsable;
                      })
                  )
                )
              : props.onChange(event, value)
          }
          multiple
          options={allManager}
          getOptionLabel={(manager) => `${manager.prenom} ${manager.nom}`}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={t("WORD_Manager")}
              error={!!errors.Responsables}
              helperText={errors.Responsables?.message || ""}
            />
          )}
        />
      )}
    />
  );
}

export default Manager;
