/**
 * Classe principale qui se charge du formulaire d'un entretien
 */

import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFileFromArray,
  getAllPhotosFromPanneID,
} from "../../../utils/EntretiensUtils";

import * as Actions from "../../../actions/EntretienPanneActions";
import * as MenuActions from "../../../actions/MenuActions.js";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useLocation } from "react-router-dom";
import AnomalieForm from "../../../hook/useAnomalieForm";
import ImageLightBox from "../../../hook/useImageLightBox";
import { useTranslation } from "react-i18next";

function AppEntretienPanne() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    isOpen: false,
    pictureSelected: {},
    photosAlreadyUploaded: [],
    anomalieToModify: null,
  });

  const allPhotos = useSelector((state) => state.galerie.pictures);
  const photosPanneFacture = useSelector(
    (state) => state.entretienPanne.photosPanneFacture
  );
  const photosPanne = useSelector((state) => state.entretienPanne.photosPanne);
  const token = useSelector((state) => state.loginForm.token);
  const privilege = useSelector((state) => state.loginForm.privilege);
  const tenant = useSelector((state) => state.loginForm.idTenant);
  const isFinish = useSelector((state) => state.entretienPanne.isFinish);
  const compteurHoraire = useSelector((state) => state.data.listCompteurHeure);
  const sousCategorie = useSelector((state) => state.data.listType);
  const listResponsable = useSelector(
    (state) => state.responsable.listResponsable
  );
  const listMachine = useSelector((state) => state.machine.listMachine);

  const photosToRender = photosPanne
    .concat(state.photosAlreadyUploaded)
    .concat(photosPanneFacture);

  React.useEffect(() => {
    //Définit le titre de la page
    dispatch(MenuActions.setPageName("Anomalie"));
  }, []);

  React.useEffect(() => {
    if (location.state?.panneToModify) {
      //Si c'est une anomalie à modifier, on récupère l'ID de l'anomalie à modifier
      const anomalieToModify = location.state.panneToModify || {};

      //Nous allons ensuite chercher si l'anomalie contient des photos et récuperer un tableau de ces photos
      const photosOfAnomalie = getAllPhotosFromPanneID(
        location.state.panneToModify.idPanne,
        allPhotos
      );

      setState((prevState) => ({
        ...prevState,
        photosAlreadyUploaded: photosOfAnomalie,
        anomalieToModify: anomalieToModify,
      }));
    }
  }, [location]);

  const deletePicture = (picture) => {
    //On regarde si notre photo à déjà été upload ou non
    //Vérification qu enotre fichier est le bon format
    if (picture instanceof File) {
      //On reagrde si la photo est une facture
      const isFound = photosPanneFacture.findIndex(
        (_picture) => _picture === picture
      );

      //Si c'est le cas on la supprime du tableau des factures
      if (isFound !== -1) {
        dispatch(
          Actions.setPhotoPanneFacture(
            [],
            deleteFileFromArray(photosPanneFacture, picture)
          )
        );
      } else {
        //On regarde si c'est un photo de la panne
        isFound = photosPanne.findIndex((_picture) => _picture === picture);

        //Si c'est le cas on la supprime des photos des pannes
        if (isFound !== -1) {
          dispatch(
            Actions.setPhotoPanne([], deleteFileFromArray(photosPanne, picture))
          );
        }
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        photosAlreadyUploaded: deleteFileFromArray(
          prevState.photosAlreadyUploaded,
          picture
        ),
      }));
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item xs={10} lg={6}>
        <Paper elevation={3} style={{ margin: "20px", padding: "12px" }}>
          <AnomalieForm
            machines={listMachine}
            responsables={listResponsable}
            allCompteur={compteurHoraire}
            tenant={tenant}
            privileges={privilege}
            photosPanne={photosPanne}
            photosPanneFacture={photosPanneFacture}
            token={token}
            isFinish={isFinish}
            photosAlreadyUploaded={state.photosAlreadyUploaded}
            anomalieToModify={location.state?.panneToModify || null}
          />
        </Paper>
      </Grid>
      {privilege.canEditPhoto && (
        <React.Fragment>
          {/* Nous imbriquons le composant ImageLightBox, qui nous serviras à afficher les images en mode fullscreen*/}
          <ImageLightBox
            picture={state.pictureSelected}
            isOpen={state.isOpen}
            closeRequest={(e) =>
              setState((prev) => ({
                ...prev,
                isOpen: false,
                pictureSelected: {},
              }))
            }
            SCategories={sousCategorie}
            Machines={listMachine}
            isNotGED
          />
          <Grid item xs={10} lg={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ padding: "12px" }}
            >
              <Grid item xs={12}>
                <Typography variant="h4" style={{ padding: "10px" }}>
                  {t("WORD_Picture")}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="panne-photo"
                  multiple
                  type="file"
                  files={photosPanne}
                  onChange={(e) => {
                    dispatch(
                      Actions.setPhotoPanne(
                        photosPanne,
                        Array.from(e.target.files)
                      )
                    );
                  }}
                />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="panne-photo-facture"
                  multiple
                  type="file"
                  files={photosPanneFacture}
                  onChange={(e) => {
                    dispatch(
                      Actions.setPhotoPanneFacture(
                        photosPanneFacture,
                        Array.from(e.target.files)
                      )
                    );
                  }}
                />
                <ButtonGroup size="small" style={{ margin: "10px" }}>
                  <Button>
                    <label htmlFor="panne-photo-facture">
                      <IconButton
                        color="primary"
                        aria-label="upload facture"
                        component="span"
                      >
                        <AttachFileIcon />
                      </IconButton>

                      <Typography variant="button">
                        {t("WORD_Invoice")}
                      </Typography>
                    </label>
                  </Button>
                  <Button>
                    <label htmlFor="panne-photo">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera />
                      </IconButton>

                      <Typography variant="button">
                        {t("WORD_Picture")}
                      </Typography>
                    </label>
                  </Button>
                </ButtonGroup>
              </Grid>

              <Grid item xs={12}>
                <GridList cols={4}>
                  {photosToRender.length > 0 &&
                    photosToRender.map((photoFile, index) => {
                      return (
                        <GridListTile key={index} style={{ cursor: "pointer" }}>
                          <img
                            src={
                              photoFile instanceof File
                                ? URL.createObjectURL(photoFile)
                                : photoFile.pathName
                            }
                            alt={photoFile.name}
                            style={{
                              display: "block",
                              width: "auto",
                              height: "auto",
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                isOpen: true,
                                pictureSelected: {
                                  URL:
                                    photoFile instanceof File
                                      ? URL.createObjectURL(photoFile)
                                      : photoFile.pathName,
                                  uploadDate:
                                    photoFile instanceof File
                                      ? photoFile.lastModifiedDate
                                      : photoFile.uploadDate,
                                },
                              }))
                            }
                          />
                          <GridListTileBar
                            title={photoFile.name}
                            titlePosition="top"
                            actionIcon={
                              <IconButton
                                aria-label={`delete ${photoFile.name}`}
                                style={{ color: "#dadad9" }}
                                onClick={(e) => deletePicture(photoFile)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                            actionPosition="right"
                          />
                        </GridListTile>
                      );
                    })}
                </GridList>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}

export default AppEntretienPanne;
