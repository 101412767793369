import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function MyDatePicker(props) {
  const { onChange, inputVariant, label, defaultValue, value } = props;

  const [locale, setLocale] = useState("fr");
  const [selectedDate, handleDateChange] = useState(
    moment.isMoment(defaultValue) ? defaultValue : moment()
  );

  moment.locale(locale);

  React.useEffect(() => {
    if (value) {
      handleDateChange(value);
    }
  }, [value]);

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <DatePicker
        label={label ? label : ""}
        views={["year", "month", "date"]}
        format="DD / MM / YYYY"
        animateYearScrolling={true}
        showTodayButton={true}
        todayLabel="Aujourd'hui"
        okLabel="Valider"
        cancelLabel="Annuler"
        invalidDateMessage="Format de date invalide"
        inputVariant={inputVariant ? inputVariant : "standard"}
        {...props}
        value={selectedDate}
        onChange={(e) => {
          //e = moment
          onChange(e);
          handleDateChange(e);
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
