/**
 * Classe principale qui gère la page de configuration
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { serveurRequest } from "../api/socket.js";
import * as MachineActions from "../actions/MachinesActions.js";
import * as DataActions from "../actions/DataActions.js";
import * as MenuActions from "../actions/MenuActions";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  ExpansionPanelActions,
  TextField,
} from "@material-ui/core";
import { DirectionsBus, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

function AppConfiguration() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  const token = useSelector((state) => state.loginForm.token);
  const tenant = useSelector((state) => state.loginForm.idTenant);
  const listMachine = useSelector((state) => state.machine.listMachine);
  const compteurMachine = useSelector((state) => state.data.listCompteurHeure);

  React.useEffect(() => {
    dispatch(MachineActions.getMachine(token, tenant));
    dispatch(MenuActions.setPageName("Configuration"));
  }, []);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleChangeData = (event, idMachine, heureMachine) => {
    const dataIndex = data.findIndex((elem) => {
      return elem.idMachine === idMachine;
    });

    if (dataIndex !== -1) {
      const localData = data[idx];
      localData.heureMachine = heureMachine;

      setData([localData]);
    } else {
      const newData = { idMachine: idMachine, heureMachine: heureMachine };

      setData((prev) => [...prev, newData]);
    }
  };

  const updateVehicule = async (e) => {
    e.preventDefault();
    serveurRequest("updateMachine", {
      token: token,
      idTenant: tenant,
    }).then(
      (res) => {
        alert("La liste des machines a bien été mise à jour !");
      },
      (rej) => {
        alert("Erreur lors de la mise à jour des machines");
      }
    );
    dispatch(MachineActions.getMachine(token, tenant));
    dispatch(DataActions.getCompteurHeure(token, tenant));
  };

  const updateVehiculeData = async (machine, data) => {
    const newTM = data.find((elem) => {
      return elem.idMachine === machine.idMachine;
    }).heureMachine;

    serveurRequest("setHeureMachine", {
      token: token,
      idMachine: machine.idMachine,
      heure: newTM,
    }).then(
      (res) => {
        alert(
          "Le compteur horaires de " +
            machine.nomMachine +
            " a bien été mis à jour !"
        );

        //Récupère les nouveaux compteurs
        dispatch(DataActions.getCompteurHeure(token, tenant));
      },
      (rej) => {
        return alert("Erreur lors de la modification du temps moteur");
      }
    );
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <AppBar position="static" color="secondary" spacing={16}>
        <Tabs
          scrollable
          scrollButtons="auto"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t("WORD_Vehicle")} icon={<DirectionsBus />} />
          {/* <Tab label="Responsable" icon={<SupervisorAccount />} /> */}
        </Tabs>
      </AppBar>
      {value === 0 && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={11} xs={6}>
            {/*               <Button
                variant="extendedFab"
                aria-label="Update"
                style={{
                  marginTop: "2%",
                  backgroundColor: "#C4161B",
                  color: "#DADAD9",
                }}
                onClick={(e) => this.updateVehicule(e)}
              >
                <Update />
                Mise à jour de la liste des machines
              </Button> */}
          </Grid>
          <Grid item md={10} xs={10} style={{ marginTop: "2%" }}>
            {listMachine.length !== 0 &&
              listMachine.map((machine) => {
                return (
                  <ExpansionPanel key={machine.idMachine}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <div style={{ flexBasis: "33.33%" }}>
                        <Typography>{machine.nomMachine}</Typography>
                      </div>
                      <div style={{ flexBasis: "33.33%" }}>
                        <Typography>
                          {t("WORD_Engine_Time")} :{" "}
                          {compteurMachine.find((elem) => {
                            return elem.idMachine === machine.idMachine;
                          }) !== undefined
                            ? Math.round(
                                compteurMachine.find((elem) => {
                                  return elem.idMachine === machine.idMachine;
                                }).tempsMoteur
                              )
                            : t("SETTINGS_Machine_No_Data")}
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ alignItems: "center" }}>
                      <TextField
                        label={t("WORD_Engine_Time")}
                        type={"number"}
                        onChange={(e) =>
                          handleChangeData(e, machine.idMachine, e.target.value)
                        }
                      />
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          updateVehiculeData(machine, data);
                        }}
                        style={{
                          backgroundColor: "#C4161B",
                          color: "#DADAD9",
                        }}
                      >
                        {t("SETTINGS_Machine_Save")}
                      </Button>
                    </ExpansionPanelActions>
                  </ExpansionPanel>
                );
              })}
          </Grid>
        </Grid>
      )}
      {value === 1 && <div> Responsable ! </div>}
    </Grid>
  );
}
export default AppConfiguration;
