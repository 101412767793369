import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienEffectuer } from "../../../../../actions/EntretienProgrammerActions";

type DoneProps = {
  defaultValue?: boolean;
};

function Done(props: DoneProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="IsDone"
      defaultValue={props.defaultValue ?? false}
      as={
        <FormControlLabel
          control={<Checkbox />}
          label={t("QIREPARE_Maintenance_Done")}
        />
      }
      onChange={([event, value]) => {
        dispatch(setNewEntretienEffectuer(value));

        return value;
      }}
    />
  );
}

export default Done;
