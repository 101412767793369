/**
 * Classe principale qui appelle les éléments de la page : header, contenu (en fonction de l'état de la connexion) et footer
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import AppMenu from "../components/AppMenuV3";
import AppLogin from "../components/AppLogin";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "../css/App.css";
import AppResponsable from "./AppResponsable";

import AppConfiguration from "./AppConfiguration";
import AppStatistiques from "./AppStatistiques";
import AppProgress from "./AppProgress";
import AppEntretienProgrammer from "./Maintenance/Schedule/AppEntretienProgrammer";
import AppEntretienCourant from "./Maintenance/Current/AppEntretienCourant";
import AppEntretienPanne from "./Maintenance/Failure/AppEntretienPanne";
import AppMaintenance from "./AppMaintenance";
import AppHistorique from "./AppHistorique.tsx";
import AppHistoriqueExceptionelle from "./History/Schedule.tsx";
import AppHistoriqueCourant from "./AppHistoriqueCourant";
import AppHistoriquePanne from "./AppHistoriquePanne";
import AppGalerie from "./AppGalerie";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <AppHeader />
          <Switch>
            {
              //page d'authentification
            }
            <Route path="/AppLogin" component={AppLogin} />
            {
              //pages de l'application
            }
            <PrivateRoute path="/AppResponsable" component={AppResponsable} />
            <PrivateRoute
              path="/AppHistoriqueExceptionelle"
              component={AppHistoriqueExceptionelle}
            />
            <PrivateRoute
              path="/AppHistoriqueCourant"
              component={AppHistoriqueCourant}
            />
            <PrivateRoute
              path="/AppHistoriquePanne"
              component={AppHistoriquePanne}
            />
            <PrivateRoute
              path="/AppConfiguration"
              component={AppConfiguration}
            />
            <PrivateRoute path="/AppStatistiques" component={AppStatistiques} />
            <PrivateRoute
              path="/AppEntretienPanne"
              component={AppEntretienPanne}
            />
            <PrivateRoute path="/AppProgress" component={AppProgress} />{" "}
            {/*//TODO: Doit on le laisser comme une page ?*/}
            <PrivateRoute path="/AppMaintenance" component={AppMaintenance} />
            <PrivateRoute
              path="/AppEntretienProgrammer"
              component={AppEntretienProgrammer}
            />
            <PrivateRoute
              path="/AppEntretienCourant"
              component={AppEntretienCourant}
            />
            <PrivateRoute path="/AppHistorique" component={AppHistorique} />
            <PrivateRoute path="/AppGalerie" component={AppGalerie} />
            <PrivateRoute component={AppMenu} />
          </Switch>

          <AppFooter />
        </div>
      </BrowserRouter>
    );
  }
}

const PrivateRoute = connect((state) => ({
  isLoggedIn: state.loginForm.isLoggedIn,
}))(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/AppLogin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
));

export default connect((state) => ({}))(App);
