import { Modal, Box, Typography, IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import React from "react";
import { useConfirmation } from "./ConfirmationContext.tsx";
import { askConfirmationCloseReason } from "./useConfirmationModal";

const style = {
  position: "absolute" as "absolute",
  top: "0%",
  left: "0%",
  width: "100%",
  height: "100%",
};

function LightBoxModal() {
  const { lightboxModalProps } = useConfirmation();

  const handleAccept = async (e: React.SyntheticEvent) => {
    lightboxModalProps?.onAccept && lightboxModalProps.onAccept(e);
  };

  const handleClose = (
    e: React.SyntheticEvent,
    reason: askConfirmationCloseReason
  ) => {
    const disableBackdropClick =
      reason === "backdropClick" &&
      lightboxModalProps?.options?.dialogProps?.disableCloseOnBackdropClick;

    !disableBackdropClick &&
      lightboxModalProps?.onClose &&
      lightboxModalProps.onClose(e, reason);
  };

  const handleCancel = (
    e: React.SyntheticEvent,
    reason: askConfirmationCloseReason
  ) => {
    lightboxModalProps?.onCancel && lightboxModalProps.onCancel(e, reason);
  };

  return (
    <Modal
      open={lightboxModalProps?.isOpen ?? false}
      onClose={handleClose}
      {...lightboxModalProps?.options?.dialogProps}
    >
      <Box sx={{ ...style }}>
        <Box
          sx={{ ...style, bgcolor: "#00000099" }}
          onClick={(event) => handleClose(event, "backdropClick")}
        />

        <Box
          sx={{
            height: "calc(100% - 32px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
          {...lightboxModalProps?.options?.dialogContentProps}
        >
          <img
            alt="Content"
            {...lightboxModalProps?.imgProps}
            style={{ zIndex: 1000, height: "100%" }}
          />
        </Box>

        <Box
          {...lightboxModalProps?.options?.dialogTitleProps}
          style={{
            position: "absolute",
            top: "0%",
            left: "0%",
            width: "calc(100% - 32px)",
            padding: "16px",
            display: "flex",
            ...lightboxModalProps?.options?.dialogTitleProps?.sx,
          }}
        >
          <Typography
            variant="overline"
            align="left"
            component={"p"}
            style={{ width: "100%", fontSize: "16px", color: "#DADAD9" }}
          >
            {lightboxModalProps?.title || "Confirmation"}
          </Typography>

          <IconButton
            onClick={(e) => handleClose(e, "escapeKeyDown")}
            style={{ color: "#DADAD9" }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default LightBoxModal;
