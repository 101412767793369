import requestType from "../utils/requestType";
import { serveurRequest, SendFileToServer } from "../api/socket.js";
import { getAllPhotosFromTypeID } from "../utils/EntretiensUtils";
import { getPictures } from "./GalerieActions";
import moment from "moment";
import { formatEngineTimeForServer } from "../utils/format.ts";
import { formatISO } from "date-fns";

export const NEW_ENTRETIEN_COURANT = requestType(
  "AJOUT_NOUVEAU_ENTRETIEN_COURANT"
);

export const SET_ID_MACHINE_COURANT = "SET_ID_MACHINE_COURANT";
export const setIdMachineCourant = (idMachine) => async (dispatch) => {
  dispatch({ type: SET_ID_MACHINE_COURANT, idMachine });
};
export const SET_ID_RESPONSABLE_COURANT = "SET_ID_RESPONSABLE_COURANT";
export const setIdResponsableCourant = (idResponsable) => async (dispatch) => {
  dispatch({ type: SET_ID_RESPONSABLE_COURANT, idResponsable });
};
export const SET_TPS_MOTEUR_COURANT = "SET_TPS_MOTEUR_COURANT";
export const setTpsMoteurCourant = (tpsMoteur) => async (dispatch) => {
  dispatch({ type: SET_TPS_MOTEUR_COURANT, tpsMoteur });
};
export const SET_DATE_CREATION_COURANT = "SET_DATE_CREATION_COURANT";
export const setDateCreationCourant = (dateCreation) => async (dispatch) => {
  dispatch({ type: SET_DATE_CREATION_COURANT, dateCreation });
};
export const SET_COMMENTAIRE_COURANT = "SET_COMMENTAIRE_COURANT";
export const setCommentaireCourant = (commentaire) => async (dispatch) => {
  dispatch({ type: SET_COMMENTAIRE_COURANT, commentaire });
};
export const SET_TYPE_ENTRETIEN_COURANT = "SET_TYPE_ENTRETIEN_COURANT";
export const setTypeEntretienCourant = (typeEntretien) => async (dispatch) => {
  dispatch({ type: SET_TYPE_ENTRETIEN_COURANT, typeEntretien });
};
export const SET_COMMENTAIRE_COLLAPSIBLE = "SET_COMMENTAIRE_COLLAPSIBLE";
export const setCommentaireCollapsible =
  (stateCommentaire) => async (dispatch) => {
    dispatch({ type: SET_COMMENTAIRE_COLLAPSIBLE, stateCommentaire });
  };
export const SET_STATE_ENTRETIEN = "SET_STATE_ENTRETIEN";
export const setStateEntretien = (stateEntretien) => async (dispatch) => {
  dispatch({ type: SET_STATE_ENTRETIEN, stateEntretien });
};
export const REMOVE_STATE_ENTRETIEN = "REMOVE_STATE_ENTRETIEN";
export const removeStateEntretien = (typeID) => async (dispatch) => {
  dispatch({ type: REMOVE_STATE_ENTRETIEN, typeID });
};
export const SET_ACTIF_TAB = "SET_ACTIF_TAB";
export const setActifTab = (actifTab) => async (dispatch) => {
  dispatch({ type: SET_ACTIF_TAB, actifTab });
};
export const SET_IS_FINISH = "SET_IS_FINISH";
export const setIsFinish = (isFinish) => async (dispatch) => {
  dispatch({ type: SET_IS_FINISH, isFinish });
};
export const SET_PHOTOS_FACTURE = "SET_PHOTOS_FACTURE";
export const setPhotosFacture =
  (allPhotosFacture, newPhotosFacture) => async (dispatch) => {
    dispatch({
      type: SET_PHOTOS_FACTURE,
      photosFacture: allPhotosFacture.concat(newPhotosFacture),
    });
  };

export const ADD_PHOTOS_CURRENT = "ADD_PHOTOS_CURRENT";
export const addPhotosCurrent = (files) => async (dispatch) => {
  dispatch({ type: ADD_PHOTOS_CURRENT, files });
};
export const REMOVE_PHOTO_CURRENT = "REMOVE_PHOTO_CURRENT";
export const removePhotoCurrent = (file) => async (dispatch) => {
  dispatch({ type: REMOVE_PHOTO_CURRENT, file });
};
export const SET_PHOTOS_ENTRETIEN = "SET_PHOTOS_ENTRETIEN";
export const setPhotosEntretien = (newPhotosEntretien) => async (dispatch) => {
  dispatch({
    type: SET_PHOTOS_ENTRETIEN,
    photosEntretien: newPhotosEntretien,
  });
};
export const AJOUT_ENTRETIEN_COURANT = "AJOUT_ENTRETIEN_COURANT";
export const newEntretienCourant = () => async (dispatch) => {
  dispatch({ type: AJOUT_ENTRETIEN_COURANT });
};

export const UPLOAD_FACTURES = requestType("UPLOAD_FACTURES");
export const UploadFacture =
  (
    token,
    tenantID,
    entretienID,
    machineID,
    alreadyUploaded,
    newFactures,
    progressCallback,
    progressIndexCallback
  ) =>
  async (dispatch) => {
    dispatch({ type: UPLOAD_FACTURES.Request });

    //On met à jour la liste des factures
    /*     await serveurRequest("updateCourantFacture", {
      entretienID: entretienID,
      facturesAlreadyUploaded: alreadyUploaded,
      token: token,
    }).catch((err) => {
      console.error(err);
      dispatch({ type: UPLOAD_FACTURES.Failure });
    }); */

    var indexPicture = 0;

    //On commence par envoyé nos nouvelles factures, une par une s'il y en à.
    for (var photoIndex = 0; photoIndex < newFactures.length; photoIndex++) {
      //Augmente de 1 l'index de la photo
      indexPicture++;

      //On applique cette modification au state
      progressIndexCallback(indexPicture);

      const file = newFactures[photoIndex];

      const dataInfo = {
        entretienID: entretienID,
        machineID: machineID,
        isFacture: true,
        isEntretienPhoto: true,
        isAnomalie: false,
        anomalieID: null,
        entretienTypeID: null,
      };

      await SendFileToServer(dataInfo, file, progressCallback);
    }

    dispatch({ type: UPLOAD_FACTURES.Success });
    dispatch(getPictures(token, tenantID));
    return alert("Vos factures ont bien été ajoutées");
  };

export const AjoutEntretienCourant =
  (token, tenant, data, progressCallback, progressIndexCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: NEW_ENTRETIEN_COURANT.Request });
    const {
      formattedEntretien,
      photosFacture,
      photosEntretien,
      equipment,
      tpsMoteurInput,
      responsableSelect,
    } = data;

    if (
      equipment.length === 0 ||
      tpsMoteurInput.length === 0 ||
      responsableSelect === 0 ||
      formattedEntretien.length === 0
    ) {
      //Si une info capitale n'est pas renseignée, on retoure une failure est on supprime les photos enregistré par l'utilisateur, car celle ci se transforme sous forme de buffer.
      dispatch({
        type: NEW_ENTRETIEN_COURANT.Failure,
      });
      return alert(
        "Veuillez remplir tout les champs (Machine, Temps Moteur et Responsable). Mais aussi choisir au minimum un type d'entretien"
      );
    }

    const state = getState();

    const selectedEquipment = state.machine.listMachine.find((machine) => {
      return machine.idMachine === equipment;
    });

    serveurRequest("POST:maintenance/current", {
      idDevice: selectedEquipment.geofarmData?.equipment_association?.device_id,
      equipment_id: equipment,
      date: formatISO(new Date()),
      engine_time: formatEngineTimeForServer(tpsMoteurInput),
      repairer_id: responsableSelect,
      mechanicals: formattedEntretien,
    }).then(
      async (res) => {
        //Envoie de l'entretien OK => Envoie des photos
        try {
          //Récupère l'ID de l'entretien ajouté en base, pour lier les photos à cette entretien
          const entretienID = res.id;

          let photoIndexToSend = 0;

          //On commence par envoyer nos factures de manière asynchrones
          for (
            let factureIndex = 0;
            factureIndex < photosFacture.length;
            factureIndex++
          ) {
            //Augmente de 1 l'index de la photo
            photoIndexToSend++;

            //On indique à notre por   ogress bar l'index de la photo que nous envoyons
            progressIndexCallback(photoIndexToSend);

            const currentPhoto = photosFacture[factureIndex];

            const dataInfo = {
              entretienID: entretienID,
              machineID:
                selectedEquipment.geofarmData?.equipment_association
                  ?.equipment_id,
              isFacture: true,
              isEntretienPhoto: true,
              isAnomalie: false,
              anomalieID: null,
              entretienTypeID: null,
            };

            await SendFileToServer(dataInfo, currentPhoto, progressCallback);
          }

          //On envoie les photos des entretiens
          for (
            let entretienPhotosIndex = 0;
            entretienPhotosIndex < photosEntretien.length;
            entretienPhotosIndex++
          ) {
            //Augmente de 1 l'index de la photo
            photoIndexToSend++;

            //On indique à notre porogress bar l'index de la photo que nous envoyons
            progressIndexCallback(photoIndexToSend);

            //Récupération de la photo courante
            const currentPhoto = photosEntretien[entretienPhotosIndex];

            const dataInfo = {
              entretienID: entretienID,
              machineID:
                selectedEquipment.geofarmData?.equipment_association
                  ?.equipment_id,
              isFacture: false,
              isAnomalie: false,
              anomalieID: null,
              isEntretienPhoto: true,
              entretienTypeID: currentPhoto.typeId,
            };

            await SendFileToServer(
              dataInfo,
              currentPhoto.file,
              progressCallback
            );
          }

          dispatch({ type: NEW_ENTRETIEN_COURANT.Success });
          dispatch(setIsFinish(true));
          return alert("Votre entretien courant a bien été ajouté");
        } catch (err) {
          console.error(err);
          dispatch({
            type: NEW_ENTRETIEN_COURANT.Failure,
          });
          console.error(
            "Erreur lors de l'upload de photo :",
            JSON.stringify(err)
          );
          return alert("Erreur lors de l'upload de photo sur le serveur");
        }
      },
      (rej) => {
        dispatch({
          type: NEW_ENTRETIEN_COURANT.Failure,
        });
        return alert("Erreur lors de la création de l'entretien courant");
      }
    );
  };
