import React from "react";
import {
  Avatar,
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AvatarGroup } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import PanelSubcategory from "./PanelSubcategory";
import * as Actions from "../../../../actions/EntretienCourantAction";

type PanelProps = {
  categorie: string,
  categorieId: number,
  equipmentTypeId: number,
};

function Panel(props: PanelProps) {
  const dispatch = useDispatch();
  const allSubcategory = useSelector((state) => state.data.listType);
  const stateEntretien = useSelector(
    (state) => state.entretienCourant.stateEntretien
  );
  const photosEntretien = useSelector(
    (state) => state.entretienCourant.photosEntretien
  );

  const subcategory = React.useMemo(() => {
    return allSubcategory.filter(
      (sub) =>
        sub.idCategorie.toString() === props.categorieId.toString() &&
        sub.equipmentTypeId === props.equipmentTypeId
    );
  }, [allSubcategory, props.categorieId]);

  const NumberOfPhotos = React.useMemo(() => {
    return photosEntretien
      .filter(
        (photo) =>
          subcategory.findIndex((sub) => sub.idType === photo.typeId) !== -1
      )
      .map((photo) => {
        return (
          <Avatar
            key={photo.file.name}
            alt={photo.file.name}
            src={photo.url}
            style={{ width: "30px", height: "30px" }}
          />
        );
      });
  }, [stateEntretien, photosEntretien]);

  const handlePictureAdded = (files: Array<unknown>, typeId: number) => {
    //On dispatch ce nouvel objet
    dispatch(
      Actions.addPhotosCurrent(files.map((file) => ({ ...file, typeId })))
    );
  };

  const handlePictureRemoved = (file: unknown) => {
    //On dispatch ce nouvel objet
    dispatch(Actions.removePhotoCurrent(file));
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={10}>
            <Typography>{props.categorie.toUpperCase()}</Typography>
          </Grid>

          <Grid item xs={2}>
            <AvatarGroup>{NumberOfPhotos}</AvatarGroup>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>

      <ExpansionPanel>
        <Grid container justifyContent="center" alignItems="center">
          {subcategory.map((sub) => (
            <PanelSubcategory
              key={sub.idType}
              subcategory={sub}
              onPictureAdded={(files) => handlePictureAdded(files, sub.idType)}
              onPictureRemoved={handlePictureRemoved}
            />
          ))}
        </Grid>
      </ExpansionPanel>
    </ExpansionPanel>
  );
}

export default Panel;
