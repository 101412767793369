// Fichier d'actions Redux qui gère le login et le logout

import sha256 from "sha256";
import { serveurRequest } from "../api/socket.js";
import { socketsController } from "../index.js";
import { formatProfiles } from "../utils/profile_parser.ts";
import requestType from "../utils/requestType";

var Constants = require("../Constants.json");

export const SET_USER_ID = "LOGINFORM_SET_USER_ID";

export const setUserId = (username) => ({
  type: SET_USER_ID,
  username,
});

export const SET_USER_PASSWORD = "LOGINFORM_SET_USER_PASSWORD";

export const setUserPassword = (password) => ({
  type: SET_USER_PASSWORD,
  password,
});

export const SET_USER_TENANT = "LOGINFORM_SET_USER_TENANT";

export const setUserTenant = (tenant) => ({
  type: SET_USER_TENANT,
  tenant,
});

export const SET_SAVESESSION = "LOGINFORM_SET_SAVESESSION";

export const setSaveSession = (saveSession) => ({
  type: SET_SAVESESSION,
  saveSession,
});

export const SET_LANGUAGE = "LOGINFORM_SET_LANGUAGE";

export const setLanguage = (language) => async (dispatch) => {
  localStorage.setItem("language", language);

  const languageFile = await fetch(
    `${process.env.REACT_APP_HOST}/languages/${language}`
  )
    .then((res) => res.json())
    .catch((e) => {
      console.error(e);
      return null;
    });

  //Save translation to localStorage
  dispatch({ type: SET_LANGUAGE, language, languageFile });
};

export const LOGIN = requestType("LOGIN");

export const login =
  (username, password, tenant, saveSession) => async (dispatch) => {
    if (username === "" || password === "" || tenant === "") {
      return alert("Veuillez remplir tous les champs.");
    }

    dispatch({ type: LOGIN.Request });

    // Tentative de connexion au serveur QTrepare, récupération d'un TokenID si OK
    //cryptage du mot de passe avant envoi au serveur
    const encryptedPassword = sha256(password);

    await socketsController
      .fetchSocket(`get:application`, {
        id: Constants.APPLI_ID,
        //idAppli: Constants.APPLI_ID,
        //version: Constants.VERSION,
      })
      .then((application) => {
        //TODO: Passer aussi en paramètre le tenant et corriger le côté QTrepare et modifier la doc en ligne
        socketsController
          .fetchSocket("/auth/login", {
            username: username,
            password: password,
            tenant: tenant,
          })
          .then(
            (rep) => {
              //si la réponse est vide alors échec
              if (!rep) {
                dispatch({ type: LOGIN.Failure });
                return alert("Les paramètres de connexion ne sont pas bons.");
              }

              const idTenant = rep.user.companyId;
              const company = rep.user.company;
              const profiles = formatProfiles(rep.user.profiles);
              const QIrepareProfile = profiles.views.find(
                (view) => view.id === 21
              );

              //const privilege = JSON.parse(rep.userInfo[0].privilege);
              //Adaptation des privilège pour correspondre avec l'ancien système de droit d'accès
              const privilege = {
                canShowPhoto: company.parameters.qirepare?.withPicture,
                canEditPhoto:
                  company.parameters.qirepare?.withPicture &&
                  ["Both", "Administrateur"].includes(
                    QIrepareProfile?.higherRole
                  ),
                anomalieMultiSubmit: true,
                canAccessToConfiguration: false,
              };
              const token = rep.token;

              socketsController.connect(token);

              dispatch({
                type: LOGIN.Success,
                idTenant,
                token,
                privilege,
                company,
                user: rep.user,
              });

              if (application?.constant?.devVersion === Constants.VERSION) {
                return alert(
                  "Votre version de " +
                    Constants.APPLINAME +
                    " est obsolète, veuillez la mettre à jour."
                );
              }
            },
            (rej) => {
              dispatch({ type: LOGIN.Failure });
              return alert(
                "Un problème a eu lieu lors de la connexion, veuillez réessayer."
              );
            }
          );
      });

    //On envoi à Google Analytics les informations de qui réussi à se connecter
    //ga("send", "event", "login", `${tenant} - ${username}`); // eslint-disable-line no-undef

    // Si on est connecté et que le bouton "Se souvenir de moi" a été coché, les informations sont sauvegardées dans des cookies
    if (saveSession) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      localStorage.setItem("tenant", tenant);
      localStorage.setItem("saveSession", saveSession);
    } else {
      localStorage.setItem("username", "");
      localStorage.setItem("password", "");
      localStorage.setItem("tenant", "");
      localStorage.setItem("saveSession", "");
    }
  };

export const LOGOUT = "LOGINFORM_LOGOUT";

export const logout = () => {
  return { type: LOGOUT };
};
