import {
  ButtonGroup,
  Button,
  Grid,
  Paper,
  Typography,
  IconButton,
  Divider,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@material-ui/core";
import {
  AddAPhotoRounded,
  AddCommentRounded,
  DeleteRounded,
} from "@material-ui/icons";
import { useState } from "react";
import useConfirmationModal from "../../../../context/ConfirmationModal/useConfirmationModal.tsx";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  removeStateEntretien,
  setStateEntretien,
} from "../../../../actions/EntretienCourantAction";
import { useTranslation } from "react-i18next";

type PanelSubcategoryProps = {
  onPictureAdded?: (files: Array<unknown>) => void,
  onPictureRemoved?: (file: unknown) => void,
  subcategory?: Record<String, unknown>,
};

function PanelSubcategory(props: PanelSubcategoryProps) {
  const { askLightbox } = useConfirmationModal();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.loginForm.privilege);

  const [buttonSelected, setButtonSelected] = useState(null);
  const [comment, setComment] = useState("");
  const [canShowComment, setCanShowComment] = useState(false);
  const [pictureUploaded, setPictureUploaded] = useState([]);

  const handleClickButton = (typeOfButton) => {
    setButtonSelected(buttonSelected === typeOfButton ? null : typeOfButton);

    if (buttonSelected === typeOfButton) {
      //Suppression de l'entretien
      dispatch(removeStateEntretien(props.subcategory.idType));
      //Suppression du commentaire
      comment && setComment("");
      //Suprression des photos
      onDeletePhoto(pictureUploaded);
    } else {
      dispatch(
        setStateEntretien({
          typeID: props.subcategory.idType,
          value: typeOfButton,
        })
      );
    }
  };

  const handleCommentsChange = (comment) => {
    setComment(comment);
    dispatch(
      setStateEntretien({
        typeID: props.subcategory.idType,
        commentaire: comment,
      })
    );
  };

  const onUploadPhoto = (event: React.SyntheticEvent) => {
    const files = event.target.files;

    const allFiles = [...pictureUploaded];

    for (const file of files) {
      allFiles.push({
        file: file,
        url: URL.createObjectURL(file),
      });
    }
    props.onPictureAdded &&
      props.onPictureAdded(
        allFiles.filter(
          (file) =>
            pictureUploaded.findIndex((picture) => picture.url === file.url) ===
            -1
        )
      );
    setPictureUploaded(allFiles);
  };

  const onDeletePhoto = (files) => {
    const filesArray = Array.isArray(files) ? files : [files];
    const uploadedPicture = [...pictureUploaded];

    filesArray.forEach((file) => {
      //Nous supprimons l'URL
      URL.revokeObjectURL(file.url);

      //Suppression de notre objet
      const filePosition = uploadedPicture.findIndex(
        (originalFile) => originalFile.url === file.url
      );
      if (filePosition !== -1) {
        props.onPictureRemoved &&
          props.onPictureRemoved(uploadedPicture[filePosition]);
        uploadedPicture.splice(filePosition, 1);
      }
    });
    setPictureUploaded(uploadedPicture);
  };

  const onPictureClick = (file) => {
    const title = `Photo créé le : ${format(
      file.file.lastModified,
      "dd/MM/yyyy"
    )} A ${format(file.file.lastModified, "HH:mm:ss")}`;
    askLightbox(title, {
      src: file.url,
      srcSet: file.url,
      alt: file.file.name,
    });
  };

  const onAddCommentClick = (event) => {
    setCanShowComment(!canShowComment);
  };

  return (
    <Grid item xs={12} style={{ marginTop: "8px" }}>
      <Paper elevation={4} style={{ padding: "8px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* Etat de la sous-catégorie */}
          <Grid item xs={12}>
            <Typography style={{ margin: "8px 0px" }}>
              {props.subcategory.nomType}
            </Typography>
          </Grid>

          <Grid item container alignItems="center" xs={12}>
            <ButtonGroup style={{ flexGrow: 1 }}>
              <Button
                onClick={(event) => handleClickButton("check")}
                color="secondary"
                variant={buttonSelected === "check" ? "contained" : "outlined"}
              >
                {t("WORD_Checked")}
              </Button>
              <Button
                onClick={(event) => handleClickButton("repair")}
                color="secondary"
                variant={buttonSelected === "repair" ? "contained" : "outlined"}
              >
                {t("WORD_Repaired")}
              </Button>
              <Button
                onClick={(event) => handleClickButton("replace")}
                color="secondary"
                variant={
                  buttonSelected === "replace" ? "contained" : "outlined"
                }
              >
                {t("WORD_Replaced")}
              </Button>
            </ButtonGroup>

            {/* Rajout d'un commentaire */}
            <IconButton
              disabled={!buttonSelected}
              color="primary"
              aria-label="add comment"
              onClick={onAddCommentClick}
            >
              <AddCommentRounded />
            </IconButton>

            {/* Rajout d'une photo */}
            <input
              files={""}
              onChange={onUploadPhoto}
              multiple
              accept="image/*"
              hidden
              id={`icon-button-file-${props.subcategory.idType}`}
              type="file"
            />
            {privileges.canEditPhoto ? (
              <label htmlFor={`icon-button-file-${props.subcategory.idType}`}>
                <IconButton
                  disabled={!buttonSelected}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <AddAPhotoRounded />
                </IconButton>
              </label>
            ) : null}
          </Grid>

          {/* Rajout d'un séparateur */}
          {canShowComment || pictureUploaded.length ? (
            <Divider
              flexItem
              style={{ width: "100%", height: "1px", margin: "8px 0px" }}
            />
          ) : null}

          {/* Affichage des photos */}
          {pictureUploaded.length ? (
            <Grid item xs={12}>
              <ImageList
                cols={6}
                style={{
                  flexWrap: "nowrap",
                  transform: "translateZ(0)",
                  marginTop: "0px",
                  marginBottom: "8px",
                }}
              >
                {pictureUploaded.map((picture) => {
                  return (
                    <ImageListItem key={picture.url} style={{ height: "auto" }}>
                      <img
                        src={picture.url}
                        srcSet={picture.url}
                        alt={picture.file.name}
                        style={{
                          cursor: "pointer",
                          display: "block",
                          width: "auto",
                          height: "auto",
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "8px",
                        }}
                        onClick={(event) => onPictureClick(picture)}
                      />
                      <ImageListItemBar
                        title={picture.file.name}
                        position="top"
                        actionIcon={
                          <IconButton
                            aria-label={`delete ${picture.file.name}`}
                            style={{
                              color: "#dadad9",
                            }}
                            onClick={(event) => onDeletePhoto(picture)}
                          >
                            <DeleteRounded />
                          </IconButton>
                        }
                        actionPosition="right"
                        style={{
                          borderTopRightRadius: "8px",
                          borderTopLeftRadius: "8px",
                        }}
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Grid>
          ) : null}

          {/* Champs commentaire */}
          {canShowComment ? (
            <Grid item xs={12}>
              <TextField
                disabled={!buttonSelected}
                fullWidth
                label={t("LOGISTIC_comment")}
                id={`${props.subcategory.idType}_commentaire`}
                name={`${props.subcategory.idType}_commentaire`}
                margin="none"
                variant="filled"
                multiline
                value={comment}
                onChange={(event) => handleCommentsChange(event.target.value)}
              />
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default PanelSubcategory;
