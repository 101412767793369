import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  LockOutlined,
  AccountCircle,
  Business,
  Send,
} from "@material-ui/icons";

import * as Actions from "../actions/loginFormActions";
import { useTranslation } from "react-i18next";

//TODO: rajouter dans le submit une demande GA de login
export default function LoginForm(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      saveSession: props.saveSession ? true : false,
      username: props.saveSession ? props.username : "",
      password: props.saveSession ? props.password : "",
      tenant: props.saveSession ? props.tenant : "",
    },
  });
  const onSubmit = (data) => {
    //Traitement des données et demande de connexion
    dispatch(
      Actions.login(data.username, data.password, data.tenant, data.saveSession)
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/*  USERNAME  */}
      <TextField
        inputRef={register({ required: t("WORD_Obligatoire") })}
        helperText={errors.username && errors.username.message}
        error={errors.username}
        fullWidth
        autoComplete="section-quest username"
        label={t("LOGIN_identifiant")}
        id="username"
        name="username"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
        onChange={(event) => dispatch(Actions.setUserId(event.target.value))}
      />
      {/*  PASSWORD  */}
      <TextField
        helperText={errors.password && errors.password.message}
        error={errors.password}
        fullWidth
        label={t("LOGIN_password")}
        inputRef={register({ required: t("WORD_Obligatoire") })}
        autoComplete="section-quest current-password"
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="material-icons prefix">
                <LockOutlined />
              </i>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Afficher/Cacher le mot de passe"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(event) =>
          dispatch(Actions.setUserPassword(event.target.value))
        }
      />

      {/* TENANT */}
      <TextField
        helperText={errors.tenant && errors.tenant.message}
        error={errors.tenant}
        inputRef={register({ required: t("WORD_Obligatoire") })}
        fullWidth
        autoComplete="section-quest organization"
        label={t("LOGIN_tenant")}
        id="tenant"
        name="tenant"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="material-icons prefix">
                <Business />
              </i>
            </InputAdornment>
          ),
        }}
        onChange={(event) =>
          dispatch(
            Actions.setUserTenant(event.target.value.toLocaleLowerCase())
          )
        }
      />

      {/*  REMEMBER ME  */}
      <FormControlLabel
        control={<Checkbox defaultChecked={props.saveSession ? true : false} />}
        label={t("LOGIN_rememberme")}
        style={{ width: "100%" }}
        inputRef={register}
        name="saveSession"
        onChange={(event) => {
          dispatch(Actions.setSaveSession(event.target.checked));
        }}
      />

      {/* SUBMIT */}
      <Button
        variant="contained"
        type="submit"
        style={{ backgroundColor: "#C4161B", color: "#DADAD9" }}
      >
        <Typography style={{ color: "#dadad9" }}>
          {t("LOGIN_log-in")}
        </Typography>
        <Send style={{ paddingLeft: "10px" }} />
      </Button>
    </form>
  );
}
