/**
 * It takes a string or a number, converts it to a number then in seconds
 * @param {number | string} engineTime - The engine time in hours.
 * @returns The engine time in seconds.
 */
export const formatEngineTimeForServer = (engineTime: number | string) => {
  const localEngineTime = parseInt(engineTime);

  return localEngineTime * 3600;
};

/**
 * It takes a string or a number, converts it to a number then in hours
 * @param {number | string | null} engineTime - The engine time in seconds.
 * @returns The engine time in hours or null.
 */
export const formatEngineTimeForClient = (engineTime: number | string) => {
  if (!engineTime) {
    return null;
  }

  const localEngineTime = parseInt(engineTime);

  return Math.round(localEngineTime / 3600);
};
