/* CLASS POUR L'ECRAN GED*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Fab } from "@material-ui/core";

import MySnackbar from "../hook/useMUISnackbar";
import FiltersGED from "../hook/useFiltersGED";
import ViewerGED from "../hook/useViewerGED";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

import * as Actions from "../actions/GalerieActions";
import * as MenuActions from "../actions/MenuActions";
import downloadPicture from "../api/File";

class AppGalerie extends Component {
  componentDidMount() {
    //On change le nom de la page
    this.props.dispatch(MenuActions.setPageName("GED"));
  }

  FABclick(e) {
    //Lorsque nous cliquons sur le boutton de téléchargement des imges, nous allons regarder si le mode permettatn de séléctionner les images est deja activé
    if (this.props.selectMode) {
      //S'il est deja activé, nous allons télécharger les images séléctionnées, puis désactiver le mode
      //On commence par boucler sur toutes les images séléctionnées, pour les télécharger.
      this.props.picturesToDownload.forEach((picture) => {
        downloadPicture(picture);
      });

      //On vide le tableau de photos séléctionnées et nous mettons le select mode à false
      this.props.dispatch(Actions.allPictureWasDownloaded());
    } else {
      //S'il n'est pas activé, nous l'activons
      this.props.dispatch(Actions.setSelectMode(true));
    }
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "16px" }}
      >
        {/* SECTION SNACKBAR */}
        <MySnackbar
          autoHideDuration={null}
          message={"Photos en cours de chargement..."}
          isOpen={this.props.isLoading}
        />

        {/* SECTION D'AFFICHAGE DES FILTRES*/}
        <Grid item xs={12} lg={10}>
          <FiltersGED />
        </Grid>

        {/* SECTION D'AFFICHAGE DES PHOTOS*/}
        <Grid item xs={12} lg={10} style={{ paddingTop: "16px" }}>
          <ViewerGED
            SCategories={this.props.SCategories}
            selectMode={this.props.selectMode}
            Machines={this.props.Machines}
            PannesList={this.props.PannesList}
          />
        </Grid>

        {/*SECTION DU BOUTTON FAB DOWNLOAD*/}

        <Fab
          color="secondary"
          aria-label="select-download"
          style={{
            position: "fixed",
            right: "16px",
            bottom: "46px",
            backgroundColor: this.props.selectMode
              ? "#C4161B"
              : "rgb(45, 44, 44)",
          }}
          onClick={(event) => this.FABclick(event)}
        >
          <CloudDownloadOutlinedIcon />
        </Fab>
      </Grid>
    );
  }
}

export default connect((state) => ({
  token: state.loginForm.token,
  tenantID: state.loginForm.idTenant,
  SCategories: state.data.listType,
  PannesList: state.data.listAlertePanne,
  selectMode: state.galerie.selectMode,
  picturesToDownload: state.galerie.picturesToDownload,
  Machines: state.machine.listMachine,
  isLoading: state.galerie.isLoading,
}))(AppGalerie);
