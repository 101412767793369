import React from "react";

import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getLastTpsMoteurForMachineID } from "../../../../utils/EntretiensUtils";

import moment from "moment";
import { parseISO } from "date-fns";

import * as Actions from "../../../../actions/EntretienProgrammerActions";
import CreateDate from "./Input/CreateDate.tsx";
import Description from "./Input/Description.tsx";
import Done from "./Input/Done.tsx";
import Equipment from "./Input/Equipment.tsx";
import FinalComment from "./Input/FinalComment.tsx";
import FinalEngineTime from "./Input/FinalEngineTime.tsx";
import Manager from "./Input/Manager.tsx";
import Repeat from "./Input/Repeat.tsx";
import ScheduleType from "./Input/ScheduleType.tsx";
import ReminderForms from "./ReminderForm.tsx";
import { t } from "i18next";

export default function EntretienProgrammerForm(props) {
  //On invoque le dispatch
  const dispatch = useDispatch();

  const { allCompteur, EntretienToModify, token, tenantID } = props;

  const storeRenouveler = useSelector(
    (state) => state.entretienProgrammer.renouveler
  );
  const isLoading = useSelector(
    (state) => state.entretienProgrammer.InProgress
  );

  const methods = useForm({
    defaultChecked: {
      Renouveler: storeRenouveler,
    },
  });
  const { handleSubmit, watch, getValues } = methods;

  const watchIsDone = watch("IsDone", EntretienToModify?.etat === 3);
  const watchReminder = watch(
    "reminder_type",
    EntretienToModify?.reminder_date_max ? "date" : "engineTime"
  );

  const submit = (data) => {
    if (!EntretienToModify) {
      //Si nous avons un entretien à modifer, alors on va faire une requete de modification d'entretien
      dispatch(
        Actions.AjoutEntretienProgrammer(
          token,
          tenantID,
          data,
          getLastTpsMoteurForMachineID(allCompteur, data.Machine.idMachine)
        )
      );
    } else {
      //Sinon nous en créons un nouveau
      dispatch(
        Actions.ModifierEntretienProgrammer(
          token,
          EntretienToModify.idAlert,
          tenantID,
          data,
          getLastTpsMoteurForMachineID(allCompteur, data.Machine.idMachine)
        )
      );
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <fieldset style={{ border: "2px solid green", borderRadius: "8px" }}>
          <legend>
            <Typography variant="overline">
              {t("QIREPARE_Maintenance_Configuration")}
            </Typography>
          </legend>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* CHAMP CATEGORIE */}
            <Grid item xs={6} style={{ padding: "10px" }}>
              {/* UTILISATION DU CONTROLLER AFIN DE POUOIR UTILISER DES VALEURS PAR DEFAUTS*/}
              <ScheduleType defaultValue={EntretienToModify?.idAlertType} />
            </Grid>

            {/* CHAMP MACHINE */}
            <Grid item xs={6} style={{ padding: "10px" }}>
              {/* UTILISATION DU CONTROLLER AFIN DE POUOIR UTILISER DES VALEURS PAR DEFAUTS*/}
              <Equipment defaultValue={EntretienToModify?.idMachine} />
            </Grid>

            {/* CHAMP DATE DE CREATION */}
            <Grid item xs={12} lg={3} style={{ padding: "10px" }}>
              <CreateDate
                defaultValue={
                  EntretienToModify?.dateCreation
                    ? parseISO(EntretienToModify.dateCreation)
                    : null
                }
              />
            </Grid>

            {/* CHAMP DESCRIPTION */}
            <Grid item xs={12} lg={9} style={{ padding: "10px" }}>
              <Description defaultValue={EntretienToModify?.description} />
            </Grid>

            <fieldset
              style={{
                border: "2px solid green",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <legend>
                <Typography variant="overline">
                  {t("QIREPARE_Reminder_Configuration")}
                </Typography>
              </legend>

              <ReminderForms entretienToModify={EntretienToModify} />
            </fieldset>
          </Grid>
        </fieldset>

        <fieldset style={{ border: "2px solid green", borderRadius: "8px" }}>
          <legend>
            <Typography variant="overline">
              {t("QIREPARE_Maintenance_completed")}
            </Typography>
          </legend>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* Champ Effectuer */}
            <Grid item xs={12}>
              <Done defaultValue={EntretienToModify?.etat === 3} />
            </Grid>
            {/* CHAMP FAIT LE */}
            {(watchIsDone || null) && (
              <Grid item xs={6} style={{ padding: "10px" }}>
                <FinalEngineTime
                  defaultValue={EntretienToModify?.compteurMachineFin || ""}
                />
              </Grid>
            )}
            {/* CHAMP RESPONSABLE */}
            {(watchIsDone || null) && (
              <Grid item xs={6} style={{ padding: "10px" }}>
                {/* UTILISATION D'UN CONTROLLER CAR NOUS AVONS L'OPTION MULTIPLE DE COCHE*/}
                <Manager defaultValue={EntretienToModify?.idResponsable} />
              </Grid>
            )}
            {/* CHAMP COMMENTAIRE */}
            {(watchIsDone || null) && (
              <Grid item xs={12} style={{ padding: "10px" }}>
                <FinalComment
                  defaultValue={EntretienToModify?.commentaire || ""}
                />
              </Grid>
            )}

            {/* CHAMP RENOUVELER */}
            {(watchIsDone || null) && watchReminder !== "date" && (
              <Grid item xs={12}>
                <Repeat />
              </Grid>
            )}
          </Grid>
        </fieldset>

        {/* BUTTON SUBMIT */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ padding: "12px" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading ? true : false}
              type="submit"
            >
              {isLoading
                ? t("WORD_SendProgress")
                : EntretienToModify
                ? t("QIRECORD_Edit")
                : t("QIRECORD_Send")}
              {isLoading ? (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "25%",
                    bottom: "50%",
                  }}
                />
              ) : (
                <Icon style={{ paddingLeft: "12px" }}>send</Icon>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContext>
  );
}
