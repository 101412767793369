import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienRenouveler } from "../../../../../actions/EntretienProgrammerActions";

type RepeatProps = {};

function Repeat(props: RepeatProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeRenouveler = useSelector(
    (state) => state.entretienProgrammer.renouveler
  );

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="Renouveler"
      defaultChecked={storeRenouveler ?? false}
      as={
        <FormControlLabel
          control={<Checkbox />}
          label={`${t("QIREPARE_Renew_Maintenance")} ?`}
        />
      }
      onChange={([event, value]) => {
        dispatch(setNewEntretienRenouveler(value));

        return value;
      }}
    />
  );
}

export default Repeat;
