/**
 * Classe principale qui se charge du formulaire d'un entretien
 */

import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";

import * as Actions from "../../../actions/EntretienCourantAction";
import * as MenuActions from "../../../actions/MenuActions.js";

import EntretienCourantForm from "./Form/EntretienCourantForm";
import { useTranslation } from "react-i18next";

function AppEntretienCourant() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = useSelector((state) => state.loginForm.token);
  const tenant = useSelector((state) => state.loginForm.idTenant);
  const privilege = useSelector((state) => state.loginForm.privilege);
  const listResponsable = useSelector(
    (state) => state.responsable.listResponsable
  );
  const listType = useSelector((state) => state.data.listType);
  const listTypeCategorie = useSelector(
    (state) => state.data.listTypeCategorie
  );
  const listMachine = useSelector((state) => state.machine.listMachine);
  const compteurHoraire = useSelector((state) => state.data.listCompteurHeure);
  const isFinish = useSelector((state) => state.entretienCourant.isFinish);

  React.useEffect(() => {
    dispatch(MenuActions.setPageName(t("WORD_Current")));
  }, []);

  React.useEffect(() => {
    isFinish && dispatch(Actions.setIsFinish(false));
  }, [isFinish]);

  if (isFinish) {
    return <Redirect to="/" />;
  } else {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Hidden smDown>
          <Grid item xs={2} />
        </Hidden>
        <Grid item xs={12} md={8}>
          <EntretienCourantForm
            token={token}
            tenant={tenant}
            privilege={privilege}
            machines={listMachine}
            responsables={listResponsable}
            allCompteur={compteurHoraire}
            allCategorie={listTypeCategorie}
            allSousCategorie={listType}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} />
        </Hidden>
      </Grid>
    );
  }
}

export default AppEntretienCourant;
