// Fichier de reducers Redux qui gère les machines
import * as LoginFormActions from "../actions/loginFormActions";
import * as Actions from "../actions/MachinesActions";

export default function MachinesReducer(
  state = {
    listMachine: [],
  },

  action
) {
  switch (action.type) {
    case Actions.GET_MACHINES.Success: {
      return {
        ...state,
        listMachine: action.machine,
      };
    }

    case Actions.SET_HEURE_DEBUT: {
      return {
        ...state,
        listMachine: action.newListMachines,
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        listMachine: [],
      };
    }
    default: {
      return state;
    }
  }
}
