import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienModifyIdAlert } from "../../actions/NewEntretienActions";
import { AccessAlarmRounded, WarningRounded } from "@material-ui/icons";

type CardMaintenanceProps = {
  sheduleMaintenace: Record<string, unknown>;
  showEquipmentName?: boolean;
};
function CardMaintenance(props: CardMaintenanceProps) {
  const dispatch = useDispatch();

  const allType = useSelector((state) => state.data.listType);
  const allEquipment = useSelector((state) => state.machine.listMachine);

  const cardTitle = React.useMemo(() => {
    return (
      (allType &&
        allType.find(
          (type) => type.idType === props.sheduleMaintenace.idAlertType
        )?.nomType) ||
      ""
    );
  }, [props, allType]);

  const cardEquipmentName = React.useMemo(() => {
    return (
      (allEquipment &&
        allEquipment.find(
          (machine) => machine.idMachine === props.sheduleMaintenace.idMachine
        )?.nomMachine) ||
      ""
    );
  }, [props, allEquipment]);

  const handleCardClick = (event: React.SyntheticEvent) => {
    dispatch(setNewEntretienModifyIdAlert(props.sheduleMaintenace.idAlert));
  };

  return (
    <Card
      component={Link}
      to={{
        pathname: "/AppEntretienProgrammer",
        state: { alertToModify: props.sheduleMaintenace },
      }}
      onClick={handleCardClick}
      style={{ display: "block", width: "350px" }}
    >
      <CardHeader
        title={`${
          props.showEquipmentName ? `${cardEquipmentName} - ` : ""
        }${cardTitle}`}
        avatar={
          <Avatar
            aria-label="Card"
            style={{
              backgroundColor:
                props.sheduleMaintenace.etat === 2 ? "#C4140B" : "#FF9800",
            }}
          >
            {props.sheduleMaintenace.etat === 2 ? (
              <WarningRounded />
            ) : (
              <AccessAlarmRounded />
            )}
          </Avatar>
        }
        subheader={props.sheduleMaintenace.description || ""}
        style={{ backgroundColor: "#DADAD9" }}
      />

      {/* <CardContent>
          <Typography color="secondary">{cardEquipmentName}</Typography>
        </CardContent> */}
    </Card>
  );
}

export default CardMaintenance;
