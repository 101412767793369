import { TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienFaitA } from "../../../../../actions/EntretienProgrammerActions";

type FinalEngineTimeProps = {
  defaultValue: string;
};

function FinalEngineTime(props: FinalEngineTimeProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = ([event, value]: [React.SyntheticEvent, string]) => {
    const numericValue = parseInt(
      (value.match(/[0-9]*/gm) || []).join("") || 0
    );

    dispatch(setNewEntretienFaitA(numericValue));

    return numericValue;
  };

  return (
    <Controller
      control={control}
      name="DoAt"
      onChange={handleChange}
      defaultValue={props.defaultValue || ""}
      as={(props) => (
        <TextField
          {...props}
          onChange={(event) => props.onChange(event, event.target.value)}
          fullWidth
          label={t("QIREPARE_Real_Engine_Time")}
          variant="outlined"
          error={!!errors.DoAt}
          helperText={errors.DoAt?.message || ""}
          inputProps={{ min: 0, inputMode: "numeric", pattern: "[0-9]*" }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

export default FinalEngineTime;
