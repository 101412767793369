import React from "react";
import {
  useConfirmation,
  ConfirmationProviderContent,
  ConfirmationProviderOptions,
} from "./ConfirmationContext.tsx";

export type askConfirmationCloseReason = "backdropClick" | "escapeKeyDown";

export type askConfirmationProps<T> = {
  title?: React.ReactNode;
  content?: ConfirmationProviderContent;
  options?: ConfirmationProviderOptions<T>;
};

function useConfirmationModal() {
  const confirmationContext = useConfirmation();

  const askLightbox = async (
    title?: React.ReactNode,
    imgProps?: React.InputHTMLAttributes<HTMLInputElement>,
    options?: ConfirmationProviderOptions<{}>
  ): Promise<[React.SyntheticEvent, {}]> => {
    return new Promise((resolve, reject) => {
      const privateOnResolve = (event: React.SyntheticEvent, values?: {}) => {
        resolve([event, values || {}]);
        confirmationContext.updateContext &&
          confirmationContext.updateContext((prevCtx) => {
            return {
              ...prevCtx,
              lightboxModalProps: {
                ...prevCtx.lightboxModalProps,
                isOpen: false,
              },
            };
          });
      };

      const privateOnReject = (event: React.SyntheticEvent) => {
        reject(event);
        confirmationContext.updateContext &&
          confirmationContext.updateContext((prevCtx) => {
            return {
              ...prevCtx,
              lightboxModalProps: {
                ...prevCtx.lightboxModalProps,
                isOpen: false,
              },
            };
          });
      };

      if (confirmationContext.updateContext) {
        confirmationContext.updateContext((prevCtx) => {
          return {
            ...prevCtx,
            lightboxModalProps: {
              ...prevCtx.lightboxModalProps,
              title: title || prevCtx.lightboxModalProps?.title || null,
              isOpen: true,
              onAccept: privateOnResolve,
              onClose: privateOnReject,
              onCancel: privateOnReject,
              options: options || prevCtx.lightboxModalProps?.options || null,
              imgProps:
                imgProps || prevCtx.lightboxModalProps?.imgProps || null,
            },
          };
        });
      }
    });
  };

  return {
    askLightbox,
  };
}

export default useConfirmationModal;
