import { Button, ButtonGroup, Chip } from "@material-ui/core";
import { Build } from "@material-ui/icons";
import moment from "moment";
import { parseISO, format, differenceInDays } from "date-fns";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienModifyIdAlert } from "../../actions/NewEntretienActions";
import DeleteButton from "../../utils/deleteButton";
import CustomToolbar from "../CustomToolbar";
import { useTranslation } from "react-i18next";

type HistoryScheduleProps = {
  beginDate: string;
  endDate: string;
  filters: Record<string, unknown>;
};

function HistorySchedule(props: HistoryScheduleProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const listMachine = useSelector((state) => state.machine.listMachine);
  const listAllType = useSelector((state) => state.data.listType);
  const listCompteurHeure = useSelector(
    (state) => state.data.listCompteurHeure
  );
  const listAlertSchedule = useSelector((state) => state.data.listAlerte);
  const historyTypeIdFilter = useSelector(
    (state) => state.historique.TypeMaintenanceFilter
  );
  const listResponsable = useSelector(
    (state) => state.responsable.listResponsable
  );

  const [data, setData] = useState([]);

  const etatSwitch = (param) => {
    switch (param) {
      case 0: //si l'état est à faire
        return "À faire";
      case 1: //si l'état est urgent
        return "Urgent";
      case 2: //si l'état est dépassé
        return "Dépassé";
      default:
        //si l'état est fait
        return "Réalisé";
    }
  };

  useEffect(() => {
    const allData: Array<Array<unknown>> = [];

    listAlertSchedule
      .filter((el) => {
        const entretienFinished =
          props.filters.entretienFinishtoShow || el.etat !== 3;
        const entretienDate = moment(
          el.dateCreation,
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ).isBetween(
          moment(props.beginDate, "YYYY-MM-DD"),
          moment(props.endDate, "YYYY-MM-DD"),
          "day",
          "[]"
        );

        return entretienFinished && entretienDate;
      })
      .forEach((currentData) => {
        const currentDataArray: Array<unknown> = [];
        const equipment = listMachine.find(
          (machine) => machine.idMachine === currentData.idMachine
        );

        const engineTime = listCompteurHeure.find(
          (compteur) => compteur.idMachine === equipment.idMachine
        );

        if (
          historyTypeIdFilter === "all" ||
          moment(
            currentData.dateCreation,
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ).isBetween(
            moment(props.beginDate, "YYYY-MM-DD"),
            moment(props.endDate, "YYYY-MM-DD"),
            "day",
            "[]"
          )
        ) {
          //Ajout de l'id
          currentDataArray.push(currentData.IDstat);

          //Ajout de l'état
          currentDataArray.push(etatSwitch(currentData.etat));

          //Ajout du type
          currentDataArray.push(
            listAllType.find((type) => type.idType === currentData.idAlertType)
              ?.nomType || ""
          );

          //Ajout de la description
          currentDataArray.push(currentData.description || "-");

          //Ajout du nom de la machine
          currentDataArray.push(equipment.nomMachine);

          //Ajout du temps restant
          currentDataArray.push(
            currentData.etat <= 2
              ? currentData.heureLimite
                ? `${
                    currentData.heureLimite -
                    (Math.round(engineTime.tempsMoteur) -
                      currentData.heureActuel)
                  } h`
                : `${differenceInDays(
                    parseISO(currentData.reminder_date_max),
                    new Date()
                  )} jours`
              : "-"
          );

          //Ajout du temps moteur
          currentDataArray.push(engineTime.tempsMoteur);

          //Ajout du temps moteur lors de la création
          currentDataArray.push(currentData.heureActuel);

          //Ajout de l'heure d'Alert
          currentDataArray.push(
            currentData.heureLimite
              ? `${currentData.heureLimite} h (${
                  currentData.heureActuel + currentData.heureLimite
                } h)`
              : ""
          );

          //Ajout de l'heure de rappel
          currentDataArray.push(
            currentData.rappel
              ? `${currentData.rappel} h (${
                  currentData.heureLimite - currentData.rappel
                } h)`
              : ""
          );

          //Ajout de la date maximum
          currentDataArray.push(
            currentData.reminder_date_max
              ? parseISO(currentData.reminder_date_max).toLocaleDateString(
                  undefined,
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                  }
                )
              : ""
          );

          //Ajout de la date de rappel
          currentDataArray.push(
            currentData.reminder_date
              ? parseISO(currentData.reminder_date).toLocaleDateString(
                  undefined,
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                  }
                )
              : ""
          );

          //Ajout du commentaire
          currentDataArray.push(currentData.commentaire || "-");

          //Ajout du responsable de l'entretien
          const allManager = currentData.idResponsable
            .split(",")
            .map((respID) => {
              const user = listResponsable.find(
                (manager) =>
                  parseInt(manager.idResponsable) === parseInt(respID)
              );

              return user ? `${user.nom} ${user.prenom}` : "";
            })
            .filter((el) => el);

          currentDataArray.push(allManager.join(","));

          //Ajout de la date de fin
          currentDataArray.push(
            currentData.dateFin
              ? moment(currentData.dateFin, "YYYY-MM-DDTHH:mm:ss.SSSZ")
                  .utcOffset(2)
                  .format("DD/MM/YYYY HH:mm")
              : "-"
          );

          //Ajout du compteur horaire de la réalisation
          currentDataArray.push(currentData.compteurMachineFin || "-");

          //On ajoute l'id de la stat et le nom de la machine pour l'option de supression de l'entretien
          currentDataArray.push([currentData.idAlert, equipment.nomMachine]);

          allData.push(currentDataArray);
        }
      });

    setData(allData);
  }, [listAlertSchedule, props]);

  const columns = useMemo(
    () => [
      {
        name: "idAlert",
        options: { filter: false, sort: false, display: "excluded" },
      },
      {
        name: t("WORD_State"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip label={value} />;
          },
        },
      },
      { name: t("QINAV_Filter_Type"), options: { filter: true, sort: true } },
      { name: t("WORD_Description"), options: { filter: false, sort: false } },
      { name: t("WORK_device"), options: { filter: true, sort: true } },
      {
        name: t("ACTIVE_ISLET_Remaining"),
        options: { filter: false, sort: true },
      },
      {
        name: t("WORD_Engine_Time"),
        options: { filter: false, sort: true },
      },
      {
        name: t("QIREPARE_Creation_Engine_Time"),
        options: { filter: false, sort: true },
      },
      { name: t("WORD_Alert"), options: { filter: false, sort: true } },
      { name: t("WORD_Reminder"), options: { filter: false, sort: true } },
      {
        name: t("QIREPARE_Maximum_Date"),
        options: { filter: false, sort: true },
      },
      {
        name: t("QIREPARE_Reminder_Date"),
        options: { filter: false, sort: true },
      },
      { name: t("LOGISTIC_comment"), options: { filter: false, sort: true } },
      {
        name: t("WORD_Manager"),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                children={value.split(",").map((resp) => {
                  if (resp.trim().length > 0) {
                    return (
                      <Chip label={resp} key={resp} style={{ margin: "1%" }} />
                    );
                  }
                  return null;
                })}
              />
            );
          },
        },
      },
      {
        name: t("QIREPARE_Realization_Date"),
        options: { filter: true, sort: true },
      },
      {
        name: t("QIREPARE_Realization_Engine_Time"),
        options: { filter: true, sort: true },
      },
      {
        name: t("WORD_Options"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonGroup orientation="vertical">
                <Button
                  component={Link}
                  to={{
                    pathname: "/AppEntretienProgrammer",
                    state: {
                      alertToModify: listAlertSchedule.find(
                        (elem) =>
                          elem.idAlert ===
                          tableMeta.rowData[tableMeta.rowData.length - 1][0]
                      ),
                    },
                  }}
                  onClick={(event) =>
                    dispatch(
                      setNewEntretienModifyIdAlert(
                        tableMeta.rowData[tableMeta.rowData.length - 1][0]
                      )
                    )
                  }
                >
                  <Build style={{ color: "#414040" }} />
                </Button>
                <Button>
                  <DeleteButton
                    idAlert={value[0]}
                    machineName={value[1]}
                    entretienType={0}
                  />
                </Button>
              </ButtonGroup>
            );
          },
        },
      },
    ],
    []
  );

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "simple",
    rowsPerPage: 10,
    customToolbar: () => <CustomToolbar />,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: t("DATATABLE_No_Data"),
        toolTip: t("WORD_Sort"),
      },
      pagination: {
        next: t("DATATABLE_Next_Page"),
        previous: t("DATATABLE_Previous_Page"),
        rowsPerPage: t("DATATABLE_Rows_Per_Page"),
        displayRows: t("DATATABLE_Display_Rows"),
      },
      toolbar: {
        search: t("DATATABLE_Search"),
        downloadCsv: t("DATATABLE_Download_CSV"),
        print: t("DATATABLE_Print"),
        viewColumns: t("DATATABLE_Columns"),
        filterTable: t("DATATABLE_Filters"),
      },
      filter: {
        title: t("DATATABLE_Filters").toUpperCase(),
        reset: "RESET",
      },
      viewColumns: {
        title: t("DATATABLE_View_Columns"),
        titleAria: t("DATATABLE_Columns"),
      },
      selectedRows: {
        text: t("DATATABLE_SelectedRows_Text"),
        delete: t("DATATABLE_SelectedRows_Delete"),
        deleteAria: t("DATATABLE_SelectedRows_DeleteRows"),
      },
    },
  };

  return (
    <MUIDataTable
      title={t("QIREPARE_Schedule_Maintenance")}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

export default HistorySchedule;
