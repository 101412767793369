/**
 * Classe principale qui se charge du formulaire d'un entretien
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { formatAlertTomModify } from "../../../utils/EntretiensUtils";
import { Redirect } from "react-router-dom";
import { setisFinish } from "../../../actions/EntretienProgrammerActions";

import EntretienProgrammerForm from "./Form/EntretienProgrammerForm";

import * as MenuActions from "../../../actions/MenuActions.js";

class AppEntretienProgrammer extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.dispatch(MenuActions.setPageName("Programmé"));

    if (this.props.location.state?.alertToModify) {
      //Nous formattons l'entretien à modifier afin que le schamps du formulaire puisssent comprendre les données
      this.entretienToModify = formatAlertTomModify(
        this.props.location.state.alertToModify,
        this.props.listResponsable,
        this.props.listType,
        this.props.listMachine
      );
    }
  };

  render() {
    if (this.props.isFinish) {
      //Si le store nous indique l'état isFinish => redirige l'utilisateur vers le menu
      this.props.dispatch(setisFinish(false));
      return <Redirect to="/" />;
    } else {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ transition: "all 2s" }}
        >
          <Grid item xs={10} md={6} style={{ width: "100%", marginTop: "2%" }}>
            <Paper
              elevation={3}
              style={{
                marginTop: "20px",
                width: "100%",
                padding: "5px",
              }}
            >
              <EntretienProgrammerForm
                allCompteur={this.props.listCompteurHeure}
                tenantID={this.props.tenant}
                machines={this.props.listMachine}
                token={this.props.token}
                EntretienToModify={
                  this.entretienToModify ? this.entretienToModify : null
                }
              />
            </Paper>
          </Grid>
        </Grid>
      );
    }
  }
}

export default connect((state) => ({
  token: state.loginForm.token,
  tenant: state.loginForm.idTenant,
  listResponsable: state.responsable.listResponsable,
  listType: state.data.listType,
  listCompteurHeure: state.data.listCompteurHeure,
  listMachine: state.machine.listMachine,
  listAlerte: state.data.listAlerte,
  listTypeCategorie: state.data.listTypeCategorie,
  isFinish: state.entretienProgrammer.isFinish,
}))(AppEntretienProgrammer);
