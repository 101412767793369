import { InputAdornment, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienAFaire } from "../../../../../../actions/EntretienProgrammerActions";
import MyDatePicker from "../../../../../../utils/datePicker";

type ReminderDateMaxProps = {
  defaultValue?: string | null;
};

function ReminderDateMax(props: ReminderDateMaxProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = ([date]) => {
    //dispatch(setNewEntretienAFaire(numericValue));

    return date;
  };

  return (
    <Controller
      control={control}
      name="maximum_date"
      onChange={handleChange}
      defaultValue={props.defaultValue ? moment(props.defaultValue) : moment()}
      as={(props) => {
        return (
          <MyDatePicker
            {...props}
            label={t("WORD_Maximum")}
            inputVariant="outlined"
            style={{ margin: "8px auto" }}
          />
        );
      }}
    />
  );
}

export default ReminderDateMax;
