import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setIdMachineCourant } from "../../../../../actions/EntretienCourantAction";

export type EquipmentProps = {
  /**
   * Id de la machine par défautx
   */
  defaultValue?: string;
};
function Equipment() {
  const { control, errors } = useFormContext();

  const dispatch = useDispatch();
  const allEquipment = useSelector((state) => state.machine.listMachine);

  const allEquipmentSorted = React.useMemo(() => {
    const result: Array<{
      name: string;
      equipments: Array<Record<string, string | number>>;
    }> = [];

    for (const equipment of allEquipment) {
      if (
        !result.length ||
        result.findIndex(
          (type) => type.name === equipment.geofarmData?.equipment_type?.name
        ) === -1
      ) {
        //Tableau final vide ou type manquant
        result.push({
          name: equipment.geofarmData?.equipment_type?.name,
          equipments: [equipment],
        });
      } else {
        const typeIndex = result.findIndex(
          (type) => type.name === equipment.geofarmData?.equipment_type?.name
        );

        if (typeIndex >= 0) {
          result[typeIndex].equipments.push(equipment);
        }
      }
    }

    return result;
  }, []);

  return (
    <FormControl fullWidth error={errors.equipment ? true : false}>
      <InputLabel id="select-machine-label">Machine</InputLabel>
      <Controller
        as={
          <Select>
            {allEquipmentSorted.map((equipentsType) => {
              return [
                <ListSubheader>{equipentsType.name}</ListSubheader>,
                equipentsType.equipments.map((equipment) => (
                  <MenuItem
                    key={equipment.idBoitier}
                    value={equipment.idMachine}
                  >
                    {equipment.nomMachine}
                  </MenuItem>
                )),
              ];
            })}
          </Select>
        }
        defaultValue=""
        name="equipment"
        control={control}
        labelId="select-machine-label"
        id="select-machine"
        onChange={(event) => {
          //Met à jour le store
          dispatch(setIdMachineCourant(event[1].props.value));

          // React Select return object instead of value for selection

          return event[1].props.value;
        }}
        rules={{ required: "Veuillez choisir une machine" }}
        //defaultValue={idMachineSelected ? idMachineSelected : ""}
      />
      {errors.equipment && (
        <FormHelperText>{errors.equipment.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default Equipment;
