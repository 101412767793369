import React, { useEffect } from "react";

import Snackbar from "@material-ui/core/Snackbar";

export default function MySnackbar(props) {
  const { isOpen, autoHideDuration, message } = props;
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //Mise à jour de l'état du snackbar
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={message}
    />
  );
}
