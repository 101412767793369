import {
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { AttachFileRounded, Send } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFileFromArray,
  deletePhotoFromEntretienPhoto,
  getLastTpsMoteurForMachineID,
} from "../../../../utils/EntretiensUtils";

import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import ImageLightBox from "../../../../hook/useImageLightBox";
import SecureCompteur from "../../../../utils/secureData";

import { useTranslation } from "react-i18next";
import * as Actions from "../../../../actions/EntretienCourantAction";
import EngineTime from "./Input/EngineTime.tsx";
import Equipment from "./Input/Equipment.tsx";
import Manager from "./Input/Manager.tsx";
import Panel from "./Panel";

//TODO: Trouver un autre moyen pour envoyer le formulaire que le setTimeOut
export default function EntretienCourantForm(props) {
  //On invoke le dispatch
  const dispatch = useDispatch();

  //On crée un référence qui vas nous ervir à enregistrer le boutton submit
  const submitButtonEl = useRef(null);

  //On récupère les paramètres de notre props
  const {
    machines,
    responsables,
    allCompteur,
    allCategorie,
    allSousCategorie,
    token,
    tenant,
    privilege,
  } = props;

  const { t } = useTranslation();

  //On créé deux state qui vont nous permettre d'identifier les erreurs liées aux temps moteurs
  const [tpsMoteurError, setTpsMoteurError] = useState(false);
  const [acceptTpsMoteurError, decideTpsMoteurError] = useState(false);

  //Création d'un état pour controller ouverture de l'image lightbox
  const [openLightBox, setOpenLightBox] = useState(false);

  //Création de l'objet picture qui alimentera notre objet lightbox
  const [pictureLightBox, setPictureLightBox] = useState("");

  //Création du state qui va gérer l'avancement le barre de progression
  const [uploadPercent, setUploadPercent] = useState(0);

  //Création du state qui va gérer l'index de la photo que nous somme entrein d'envoyer
  const [uploadPictureIndex, setUploadPictureIndex] = useState(0);

  //Caté"gorie d'entretiens sélectionnable en fonction du vehicule choisie
  const [categorieOfEquipment, setCategorieOfEquipment] = useState([]);

  //Hook selector, qui nous permet de récuperer une variable dans le store au meme titre que le "connect"
  const stateEntretien = useSelector(
    (state) => state.entretienCourant.stateEntretien
  );
  const isLoading = useSelector((state) => state.entretienCourant.isLoading);
  const photosFacture = useSelector(
    (state) => state.entretienCourant.photosFacture
  );
  const photosEntretien = useSelector(
    (state) => state.entretienCourant.photosEntretien
  );

  //on crée notre objet de formulaire
  const methods = useForm();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    control,
    setValue,
    getValues,
    watch,
  } = methods;

  const equipmentIDWatch = watch("equipment");

  const machine = React.useMemo(() => {
    return machines.find((machine) => machine.idMachine === equipmentIDWatch);
  }, [machines, equipmentIDWatch]);

  React.useEffect(() => {
    if (equipmentIDWatch) {
      //Récupération de l'objet machine
      const machine = machines.find(
        (machine) => machine.idMachine === equipmentIDWatch
      );

      const allCategorieId = allSousCategorie
        .filter(
          (type) =>
            type.equipmentTypeId === machine?.geofarmData?.equipment_type?.id
        )
        .map((type) => type.idCategorie);

      setCategorieOfEquipment(
        allCategorie
          .filter((categorie) => {
            return allCategorieId.includes(categorie.idCategorie);
          })
          .sort((catA, catB) =>
            catA.nomCategorie.localeCompare(catB.nomCategorie)
          )
      );
    }
  }, [equipmentIDWatch]);

  //Fonction d'envoie du formualire
  const onSubmit = (data) => {
    //Variable qui définit si le formulaire est prêt à être envoyé
    var sendForm = false;

    //Regarde si nous avons une erreur sur le temps moteur et si il à été accepté
    if (tpsMoteurError && acceptTpsMoteurError) {
      //Si nous avons une erreur est qu'elle à était accepté par l'utilisateur alors on valide la valeur en spécifiant que le formualaire est valide
      sendForm = true;
    } else if (tpsMoteurError && !acceptTpsMoteurError) {
      //Si nous aovns uen erreur sur le temps moteur et qu'elle à était refusé par l'utilisateur, alors on créé une erreur avec une message
      setError(
        "tpsMoteurInput",
        "validate",
        "Veuillez corriger le temps moteur de la machine"
      );
    } else {
      //Si nous n'avons pas d'erreur sur le temps moteur, on procéde à la vérification
      //Récupère le compteur horaire de la machine séléctionné
      const tpsMoteurRef = getLastTpsMoteurForMachineID(
        allCompteur,
        data.machineSelect,
        true
      );

      //on rcupère sa date que l'on convertit en moment
      const tpsMoteurRefDate = moment(
        tpsMoteurRef.statDate,
        "YYYY-MM-DD HH:mm:ss"
      );

      //On calcule combien de temps il y à (en heure) entre la date de mise à jour du compteur et la date actuelle
      const diffDateHour = moment().diff(tpsMoteurRefDate, "hours");

      //On regarde si nous avons deja un compteur horaire pour cette machine d'enregistré, et si le temps moteur renseigné est dans la limite de l'acceptable
      if (
        tpsMoteurRef !== 0 &&
        data.tpsMoteurInput - tpsMoteurRef.tempsMoteur >= diffDateHour * 10
      ) {
        //Si la difference entre les deux est supérieur au temps que le vehicule peut parcourir sur une moyenne de 10km/h, alors on indique qu'il y à une erreur ce qui va entrainer une demande de confirmation à l'utilisateur

        //On spécifie que nosu avons une erreur sur le temps moteur
        setTpsMoteurError(true);

        //on créé un mesage d'erreur
        setError(
          "tpsMoteurInput",
          "validate",
          "Le temps moteur de la machine est incohérent"
        );
      } else {
        //Tout vas bien on peut envoyer le formulaire
        sendForm = true;
      }
    }

    if (sendForm) {
      //On rejoute dans nos données les entretiens réalisés formatés
      data.formattedEntretien = Object.values(stateEntretien);
      //On rajotue la/les photos de facture
      data.photosFacture = photosFacture;
      //On rajotue les photos d'entretien
      data.photosEntretien = photosEntretien;
      //Si le formulaire est valide on l'envoie
      dispatch(
        Actions.AjoutEntretienCourant(
          token,
          tenant,
          data,
          setUploadPercent,
          setUploadPictureIndex
        )
      );
    }
  };

  const lightboxOpen = (event, picture) => {
    setPictureLightBox({
      URL: URL.createObjectURL(picture),
      uploadDate: picture.lastModifiedDate,
    });
    setOpenLightBox(true);
  };

  const lightboxClose = (e) => {
    setOpenLightBox(false);
    setPictureLightBox({});
  };

  return (
    <React.Fragment>
      {tpsMoteurError && !acceptTpsMoteurError && (
        <SecureCompteur
          compteurRef={
            allCompteur.find(
              (compteur) => compteur.idMachine === getValues().machineSelect
            ).tempsMoteur
          }
          compteur={getValues().tpsMoteurInput}
          machine={machines.find(
            (machine) => machine.idMachine === getValues().machineSelect
          )}
          acceptCallback={() => {
            decideTpsMoteurError(true);
            clearError("tpsMoteurInput");
            setTimeout(() => submitButtonEl.current.click(), 1000);
          }}
          declineCallback={() => decideTpsMoteurError(false)}
        />
      )}
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* MODAL QUI AFFICHE LES IMAGES EN FULLSCREEN */}
          <ImageLightBox
            picture={pictureLightBox}
            isOpen={openLightBox}
            closeRequest={(e) => lightboxClose(e)}
            SCategories={allSousCategorie}
            Machines={machines}
            isNotGED
          />
          <Paper elevation={4} style={{ margin: "10px", padding: "10px" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {/* CHAMP DE SELECTION D'UNE MACHINE */}
              <Grid item xs={10} md={3}>
                <Equipment />
              </Grid>

              {/* ESPACE ENTRE CHAQUE CHAMPS */}
              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>

              {/* CHAMP DE SELECTION D'UN RESPONSABLE */}
              <Grid item xs={10} md={3}>
                <Manager />
              </Grid>

              {/* ESPACE ENTRE CHAQUE CHAMPS */}
              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>

              {/* CHAMP DE SELECTION DU TEMPS MOTEUR */}
              <Grid item xs={10} md={3}>
                <EngineTime />
              </Grid>

              {/* On check la permissions de l'tilisateur d'ajouter des photos, et on lui affiche le boutton lui permettant d'en ajouter*/}
              {privilege.canEditPhoto && (
                <React.Fragment>
                  <Grid item xs={12}>
                    {/* Rajout d'un séparateur */}
                    <Divider
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  </Grid>

                  {/* RAJOUT DU TITRE FACTURE && Rajout du champs input de type file, on l'ont authorise tout les formats d'images, NON SUIVIE PAR REACT-HOOK-FORM */}
                  <Grid item>
                    <input
                      multiple
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      files={photosFacture}
                      onChange={(e) => {
                        dispatch(
                          Actions.setPhotosFacture(
                            photosFacture,
                            Array.from(e.target.files)
                          )
                        );
                      }}
                      name="icon-button-file"
                      style={{ display: "none" }}
                    />

                    <label htmlFor="icon-button-file">
                      <Button
                        variant="contained"
                        startIcon={<AttachFileRounded />}
                        component="span"
                      >
                        {t("WORD_Invoice")}
                      </Button>
                    </label>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Preview des photos à uploader*/}
                    <ImageList
                      cols={6}
                      style={{ flexWrap: "nowrap", transform: "translateZ(0)" }}
                    >
                      {photosFacture.length > 0 &&
                        photosFacture.map((photoFile, index) => {
                          return (
                            <ImageListItem
                              key={index}
                              style={{ height: "auto" }}
                            >
                              <img
                                src={URL.createObjectURL(photoFile)}
                                alt={photoFile.name}
                                width="100%"
                                height="100%"
                                onClick={(event) =>
                                  lightboxOpen(event, photoFile)
                                }
                                style={{
                                  cursor: "pointer",
                                  display: "block",
                                  width: "auto",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  borderRadius: "8px",
                                }}
                              />
                              <ImageListItemBar
                                title={photoFile.name}
                                titlePosition="top"
                                actionIcon={
                                  <IconButton
                                    aria-label={`delete ${photoFile.name}`}
                                    style={{ color: "#dadad9" }}
                                    onClick={(e) =>
                                      dispatch(
                                        Actions.setPhotosFacture(
                                          [],
                                          deleteFileFromArray(
                                            photosFacture,
                                            photoFile
                                          )
                                        )
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                }
                                actionPosition="right"
                                style={{
                                  borderTopRightRadius: "8px",
                                  borderTopLeftRadius: "8px",
                                }}
                              />
                            </ImageListItem>
                          );
                        })}
                    </ImageList>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {categorieOfEquipment.map((categorie) => {
              return categorie.canShowInCourant ? (
                <Grid
                  item
                  xs={12}
                  ms={10}
                  key={categorie.idCategorie}
                  style={{ padding: "8px" }}
                >
                  <Panel
                    categorie={categorie.nomCategorie}
                    categorieId={categorie.idCategorie}
                    equipmentTypeId={machine?.geofarmData?.equipment_type?.id}
                  />
                </Grid>
              ) : null;
            })}
          </Grid>

          {/* SUBMIT */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {!isLoading ? (
              <React.Fragment>
                <Grid item xs={5} />
                <Grid item xs={2}>
                  <Button
                    ref={submitButtonEl}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#C4161B",
                      color: "#DADAD9",
                      transform: "TranslateX(33%)",
                    }}
                  >
                    <Typography style={{ color: "#dadad9" }}>
                      VALIDER
                    </Typography>
                    <Send style={{ paddingLeft: "10px" }} />
                  </Button>
                </Grid>
                <Grid item xs={5} />
              </React.Fragment>
            ) : (
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="overline">{`Envoie en cours (${uploadPictureIndex}/${
                  photosEntretien.length + photosFacture.length
                })`}</Typography>
                {/* Si nous aovns des photos on affiche une barre de progression lineaire*/}
                {photosEntretien.length || photosFacture.length ? (
                  <LinearProgress
                    variant={"determinate"}
                    value={uploadPercent}
                  />
                ) : (
                  <LinearProgress variant={"query"} />
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </FormContext>
    </React.Fragment>
  );
}

function deletePhotoEntretienButton(
  photosEntretien,
  dispatch,
  photoFile,
  idType
) {
  //on fait une nouvelle copie du store que nous allons modifier => nécessaire sinon l'écran ne se met pas à jour par soucis d'immutabilité
  //const localState = Object.assign({}, stateEntretien);

  const newPhotosEntretien = deletePhotoFromEntretienPhoto(
    photosEntretien,
    photoFile,
    idType
  );

  //On dispatch ce nouvel objet
  dispatch(Actions.setPhotosEntretien(newPhotosEntretien));
}
