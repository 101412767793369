import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFileRounded from "@material-ui/icons/AttachFileRounded";
import Slide from "@material-ui/core/Slide";
import {
  Grid,
  Divider,
  GridList,
  GridListTileBar,
  GridListTile,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFacturesFromEntretienID,
  deleteFileFromArray,
} from "../utils/EntretiensUtils";
import { getCompleteURLFromPictureObject } from "../utils/GEDutils";
import {
  setPhotosFacture,
  setIsFinish,
  UploadFacture,
} from "../actions/EntretienCourantAction";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialogAddPicture(props) {
  const { entretienID, machineID, ...otherProps } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector((state) => state.loginForm.token);
  const tenant = useSelector((state) => state.loginForm.idTenant);
  const allPhotos = useSelector((state) => state.galerie.pictures);
  const photosCourantFacture = useSelector(
    (state) => state.entretienCourant.photosFacture
  );
  const isFinish = useSelector((state) => state.entretienCourant.isFinish);
  const isLoading = useSelector((state) => state.entretienCourant.isLoading);

  const [open, setOpen] = React.useState(false);

  //Création du state qui va gérer l'avancement le barre de progression
  const [uploadPercent, setUploadPercent] = React.useState(0);

  //Création du state qui va gérer l'index de la photo que nous somme entrein d'envoyer
  const [uploadPictureIndex, setUploadPictureIndex] = React.useState(0);

  const [factureAlreadyUploaded, setfactureAlreadyUploaded] = React.useState(
    getAllFacturesFromEntretienID(entretienID, allPhotos)
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const uploadFactures = () => {
    dispatch(
      UploadFacture(
        token,
        tenant,
        entretienID,
        machineID,
        factureAlreadyUploaded,
        photosCourantFacture,
        setUploadPercent,
        setUploadPictureIndex
      )
    );
  };

  const deletePicture = (picture) => {
    //On regarde si notre photo à déjà été upload ou non
    if (picture instanceof File) {
      //On reagrde si la photo est une facture
      var isFound = photosCourantFacture.findIndex(
        (_picture) => _picture === picture
      );

      //Si c'est le cas on la suppirme du tableau des factures
      if (isFound !== -1) {
        dispatch(
          setPhotosFacture(
            [],
            deleteFileFromArray(photosCourantFacture, picture)
          )
        );
      }
    } else {
      setfactureAlreadyUploaded(
        deleteFileFromArray(factureAlreadyUploaded, picture)
      );
    }
  };

  useEffect(() => {
    //on met à jour la liste des photos si on vient d'en uploader une
    setfactureAlreadyUploaded(
      getAllFacturesFromEntretienID(entretienID, allPhotos)
    );
  }, [allPhotos]);

  useEffect(() => {
    if (isFinish) {
      dispatch(setIsFinish(false));
      setOpen(false);
    }
  }, [isFinish]);

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} {...otherProps}>
        <AttachFileRounded />
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Importer une facture à votre entretien courant
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          style={{ overflowX: "hidden" }}
        >
          <input
            multiple
            accept="image/*"
            id="icon-facture-file"
            type="file"
            files={photosCourantFacture}
            onChange={(e) => {
              dispatch(
                setPhotosFacture(
                  photosCourantFacture,
                  Array.from(e.target.files)
                )
              );
            }}
            name="icon-facture-file"
            style={{ display: "none" }}
          />
          <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
            <Typography variant="h4">{`Factures associées (${factureAlreadyUploaded.length})`}</Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
            <ButtonGroup>
              <Button variant="outlined" size="small">
                <label htmlFor="icon-facture-file">
                  <IconButton
                    color="primary"
                    aria-label="upload facture panne"
                    component="span"
                  >
                    <AttachFileRounded />
                  </IconButton>
                  <Typography variant="button">Factures</Typography>
                </label>
              </Button>

              {isLoading ? (
                <Button disabled={true}>
                  <Typography variant="overline">{`Envoi en cours (${uploadPictureIndex}/${photosCourantFacture.length})`}</Typography>
                  <CircularProgress
                    variant="static"
                    value={uploadPercent}
                    style={{ color: "#dadad9", verticalAlign: "middle" }}
                  />
                </Button>
              ) : (
                <Button onClick={uploadFactures}>Envoyer</Button>
              )}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <GridList cols={4}>
              {factureAlreadyUploaded.map((photoFile, index) => {
                //PhotoFile = pictures store
                return (
                  <GridListTile key={index} style={{ cursor: "pointer" }}>
                    <img
                      src={photoFile.pathName}
                      alt={photoFile.name}
                      style={{
                        display: "block",
                        width: "auto",
                        height: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <GridListTileBar
                      title={photoFile.name}
                      titlePosition="top"
                      actionIcon={
                        <IconButton
                          aria-label={`delete ${photoFile.name}`}
                          style={{ color: "#dadad9" }}
                          onClick={(e) => deletePicture(photoFile)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                    />
                  </GridListTile>
                );
              })}
              {photosCourantFacture.map((photoFile, index) => {
                //photoFile = File Object
                return (
                  <GridListTile key={index} style={{ cursor: "pointer" }}>
                    <img
                      src={URL.createObjectURL(photoFile)}
                      alt={photoFile.name}
                      style={{
                        display: "block",
                        width: "auto",
                        height: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <GridListTileBar
                      title={photoFile.name}
                      titlePosition="top"
                      actionIcon={
                        <IconButton
                          aria-label={`delete ${photoFile.name}`}
                          style={{ color: "#dadad9" }}
                          onClick={(e) => deletePicture(photoFile)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                    />
                  </GridListTile>
                );
              })}
            </GridList>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
