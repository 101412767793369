/**
 * Classe principale qui gère la partie statistiques
 */

import React, { Component } from "react";
import { connect } from "react-redux";

class AppStatistiques extends Component {
  render() {
    return (
      <div className="container">
        <p>Statistiques</p>
      </div>
    );
  }
}

export default connect(state => ({}))(AppStatistiques);
