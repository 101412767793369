/**
 * Classe de header, si la connexion n'est pas effective, le nom de l'application d'affiche, sinon c'est le tenant, plus le bouton de déconnexion et le bouton de tutoriel (pas dans cette classe mais dans l'espace header)
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/AppHeader.css";
import { Link } from "react-router-dom";
import * as Actions from "../actions/loginFormActions";
import { AppBar, Toolbar, Typography, Grid } from "@material-ui/core";

const Constants = require("../Constants");

class AppHeader extends Component {
  render() {
    const styles = {
      ToolbarStyles: {
        alignItems: "center",
        justifyContent: "center",
      },
      Logout: {
        textAlign: "right",
      },
      Home: {
        textAlign: "left",
      },
      PageName: {
        textAlign: "center",
      },
    };

    if (this.props.isLoggedIn) {
      return (
        <AppBar position="static" style={{ zIndex: "999" }}>
          <Toolbar style={styles.ToolbarStyles} disableGutters={true}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={1} style={{ paddingLeft: "1.5%" }}>
                <Link to="/" role="button" style={styles.Home}>
                  <i
                    className="material-icons right"
                    style={{ color: "#dadad9", padding: "20%" }}
                  >
                    home
                  </i>
                </Link>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" style={styles.PageName}>
                  {this.props.pageName}
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: "1.5%" }}>
                <div style={styles.Logout}>
                  <a
                    href="#!"
                    className="brand-logo right"
                    onClick={async (e) => {
                      e.preventDefault();
                      this.props.dispatch(Actions.logout());
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ color: "#dadad9", padding: "20%" }}
                    >
                      lock
                    </i>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      );
    }

    return (
      <AppBar position="static">
        <Toolbar style={styles.ToolbarStyles}>
          <Typography variant="h3">{Constants.APPLINAME}</Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

export default connect((state) => ({
  isLoggedIn: state.loginForm.isLoggedIn,
  pageName: state.menu.pageName,
}))(AppHeader);
