import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { ConfirmationProvider } from "./context/ConfirmationModal/ConfirmationContext.tsx";

//import * as notifWorker from "./api/push-notification";
//import * as serviceWorker from "./serviceWorker";
import "./i18n";

import store from "./store";

import App from "./components/App";
import Sockets from "./api/sockets.ts";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c4161b",
    },
    secondary: {
      main: "#414040",
    },
  },
  typography: {
    h3: { color: "#dadad9" },
  },
});

export const socketsController = new Sockets();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConfirmationProvider>
        <App />
      </ConfirmationProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./api/push-notification.js")
    .then(registration => console.log("Le service worker est enregistré"))
    .catch(console.error);
} else {
  console.warn("Le navigateur ne prend pas en charge les services worker");
} */
//serviceWorker.register();
//initializeFirebase();
