import { saveAs } from "file-saver";
import { getCompleteURLFromPictureObject } from "../utils/GEDutils.js";

//Funciton qui va recevoir une url d'image provenant de qicture, pour la télécharger
export default async function downloadPicture(picture) {
  const URL = getCompleteURLFromPictureObject(picture.pathName);

  //obliger de désactiver le cors pour que la requête fonctionne
  fetch(URL, { mode: "cors", cache: "reload" })
    .then(res => {
      return res.blob();
    })
    .then(blob => saveAs(blob, picture.name))
    .catch(error => alert(error));
}
