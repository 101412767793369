import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { CalendarTodayRounded, ScheduleRounded } from "@material-ui/icons";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { getLastTpsMoteurForMachineID } from "../../../../utils/EntretiensUtils";
import FirstReminder from "./Input/Reminder/FirstReminder.tsx";
import FirstDateReminder from "./Input/Reminder/FirstDateReminder.tsx";
import ReminderDateMax from "./Input/Reminder/ReminderDateMax.tsx";
import ReminderMax from "./Input/Reminder/ReminderMax.tsx";
import { useTranslation } from "react-i18next";

export type ReminderType = "engineTime" | "date";

type ReminderFormsProps = {
  entretienToModify?: Record<string, unknown>;
};

function ReminderForms(props: ReminderFormsProps) {
  const { watch, register, setValue } = useFormContext();
  const { t } = useTranslation();

  const watchMachine = watch("Machine");

  const allCompteur = useSelector((state) => state.data.listCompteurHeure);

  const [reminderType, setReminderType] = React.useState<ReminderType>(
    props.entretienToModify
      ? props.entretienToModify.heureLimite
        ? "engineTime"
        : "date"
      : "engineTime"
  );
  const [showFirstDateReminder, setShowFirstDateReminder] =
    React.useState<boolean>(!!props.entretienToModify?.reminder_date);

  const currentEngineTime = React.useMemo(() => {
    return getLastTpsMoteurForMachineID(allCompteur, watchMachine?.idMachine);
  }, [watchMachine, allCompteur]);

  const handleReminderTypeClick = (type: ReminderType) => {
    if (reminderType !== type) {
      //Set des valuers dans le state et dans le formulaire
      setReminderType(type);
      setValue("reminder_type", type);
    }
  };

  return (
    <Box style={{ padding: "8px" }}>
      <Grid container alignItems="center" style={{ width: "100%" }}>
        <Grid item xs={6}>
          <Button
            variant={reminderType === "engineTime" ? "contained" : "outlined"}
            startIcon={<CalendarTodayRounded />}
            style={{ margin: "0 auto", display: "flex" }}
            onClick={(event) => handleReminderTypeClick("engineTime")}
          >
            {t("WORD_Engine_Time")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant={reminderType === "date" ? "contained" : "outlined"}
            startIcon={<ScheduleRounded />}
            style={{ margin: "0 auto", display: "flex" }}
            onClick={(event) => handleReminderTypeClick("date")}
          >
            {t("WORD_Date")}
          </Button>
        </Grid>

        <Divider
          flexItem
          style={{ width: "100%", height: 1, marginTop: "16px" }}
        />
      </Grid>

      {/* Input du type choisie */}
      <input
        name="reminder_type"
        defaultValue={reminderType}
        ref={register}
        type={"hidden"}
      />

      {reminderType === "engineTime" && (
        <>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ margin: "16px 0px" }}
          >
            {`${t("WORD_Engine_Time")} : ${currentEngineTime}`}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* CHAMP A FAIRE DANS */}
            <Grid item xs={12} lg={6} style={{ paddingRight: "8px" }}>
              <ReminderMax
                defaultValue={props.entretienToModify?.heureLimite || ""}
              />
            </Grid>

            {/* CHAMP RAPPEL */}
            <Grid item xs={12} lg={6} style={{ paddingLeft: "8px" }}>
              <FirstReminder
                defaultValue={props.entretienToModify?.rappel || ""}
              />
            </Grid>
          </Grid>
        </>
      )}

      {reminderType === "date" && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "16px" }}
        >
          {/* CHAMP A FAIRE DANS */}
          <Grid item container xs={12}>
            <Grid item container xs={6}>
              <ReminderDateMax
                defaultValue={
                  props.entretienToModify?.reminder_date_max || null
                }
              />
            </Grid>

            {showFirstDateReminder && (
              <Grid item container xs={6}>
                <FirstDateReminder
                  defaultValue={props.entretienToModify?.reminder_date || null}
                />
              </Grid>
            )}
          </Grid>

          {/* CHAMP RAPPEL */}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox name="rappel" />}
              label={t("QIREPARE_Add_Reminder")}
              style={{ width: "100%" }}
              checked={showFirstDateReminder}
              onChange={(event) =>
                setShowFirstDateReminder(event.target.checked)
              }
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default ReminderForms;
