import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Build, Visibility } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addHours, differenceInDays, format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../../utils/deleteButton";
import ShowType from "../../utils/modalEntretien";
import { Link } from "react-router-dom";
import { setNewEntretienModifyIdAlert } from "../../actions/EntretienProgrammerActions";

export type ModalDetailsProps = {
  value: any;
  hiddenButtonModal?: boolean | undefined;
  openProps?: boolean | undefined;
};

export default function ModalDetails(props: ModalDetailsProps) {
  const { t } = useTranslation();
  const { value, hiddenButtonModal, openProps } = props;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  // --------- Store ---------
  const listMachine = useSelector((state: any) => state.machine.listMachine);
  const listCompteurHeure = useSelector(
    (state: any) => state.data.listCompteurHeure
  );
  const listTypeCategorie = useSelector(
    (state: any) => state.data.listTypeCategorie
  );
  const listAllType = useSelector((state: any) => state.data.listType);
  const listResponsable = useSelector(
    (state: any) => state.responsable.listResponsable
  );

  useEffect(() => {
    openProps && setOpen(true);
  }, [openProps]);
  //---------- Tableau d'etat pour entretiens programmés ---------
  const etatSwitch = (param) => {
    switch (param) {
      case 0: //si l'état est à faire
        return "À faire";
      case 1: //si l'état est urgent
        return "Urgent";
      case 2: //si l'état est dépassé
        return "Dépassé";
      default:
        //si l'état est fait
        return "Réalisé";
    }
  };

  const commonData = {
    type: {
      name: t("WORD_Type"),
      value: value.idPanne
        ? `${t("WORD_Failure")}`
        : value.IDstat
        ? `${t("WORD_Current")}`
        : `${t("WORD_Schedule")}`,
    },
    nomMachine: { name: t("WORK_device"), value: value.equipment.nomMachine },
    responsable: {
      name: t("WORD_Manager"),
      value: value.idAlert
        ? !value.idResponsable
          ? t("WORD_All_People")
          : value.idResponsable
              .split(",")
              .map((respID) => {
                const user = listResponsable.find(
                  (manager) =>
                    parseInt(manager.idResponsable) === parseInt(respID)
                );

                return user ? `${user.nom} ${user.prenom}` : "";
              })
              .filter((el) => el)
        : value?.responsable?.nom
        ? value.responsable.nom + " " + value.responsable.prenom
        : "-",
    },
    tpsMoteur: {
      name: t("WORD_Engine_Time"),
      value: Math.round(value.engineTime.tempsMoteur),
    },
    date: value.date
      ? {
          name: t("WORD_Date"),
          value: format(parseISO(value.date), "dd/MM/yyyy HH:mm"),
        }
      : value.dateFin
      ? {
          name: t("MODAL_DATE_CHOICE_End_Date"),
          value: format(parseISO(value.dateFin), "dd/MM/yyyy HH:mm"),
        }
      : {
          name: t("QIREPARE_Creation_Date"),
          value: format(parseISO(value.dateCreation), "dd/MM/yyyy HH:mm"),
        },
  };

  const currentIntervenant = value.idPanne
    ? listResponsable.find(
        (manager) => manager.idResponsable === value.idIntervenant
      ) || null
    : null;

  const engineTimeRounded =
    !value.IDstat && !value.idPanne
      ? Math.round(
          listCompteurHeure.find(
            (compteur) =>
              compteur.idMachine ===
              listMachine.find(
                (machine) => machine.idMachine === value.idMachine
              ).idMachine
          ).tempsMoteur
        )
      : null;

  const entretiens = value.IDstat //Ajout des entretiens
    ? listTypeCategorie.map((categorie) => {
        const categorieData: never[] = [];
        //Peux t-on voir la catégorie dans un entretien courant ?
        if (categorie.canShowInCourant) {
          value.idType[0].forEach((entretien) => {
            //Nous recherchons tout les types de cette catégorie
            listAllType.forEach((entretienType) => {
              if (
                entretien &&
                entretien[entretienType.idType] &&
                entretienType.idCategorie === categorie.idCategorie
              ) {
                entretien[entretienType.idType].nomType = entretienType.nomType;
                categorieData.push({ ...entretien[entretienType.idType] });
              }
            });
          });
        }
        return categorieData;
      })
    : null;

  const getEntretiensArr = (entretiens) => {
    const entretiensArr: any[] = [];
    entretiens?.forEach(
      (entretien, index) =>
        (entretiensArr[index] = [
          ...entretien?.map((attr, index) => (
            <ShowType
              key={index}
              nomType={attr.nomType}
              commentaire={attr.commentaire || ""}
              value={attr.value}
            />
          )),
        ])
    );
    return entretiensArr;
  };

  const specialData: any =
    // ---------- Panne ---------
    value.idPanne
      ? {
          title: {
            name: t("WORD_Title"),
            value: value.title,
          },
          description: {
            name: t("WORD_Description"),
            value: value.description || "-",
          },
          status: {
            name: t("WORD_Status"),
            value: value?.faultstatus && (
              <div
                style={{
                  width: "35%",
                  marginLeft: "50%",
                  transform: "translate(-50%)",
                }}
              >
                <p style={{ width: "100%" }}>{t("WORD_Status")}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "normal",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: value.faultstatus.color,
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  />
                  {value.faultstatus.name}
                </div>
                <Divider style={{ backgroundColor: "grey", left: 0 }} />
              </div>
            ),
          },
          heureReparation: {
            name: t("QIREPARE_Realization_Engine_Time"),
            value:
              value?.faultstatus ||
              value.faultstatus?.name === "Panne réparée" ||
              value.faultstatus?.name === "Dépanage"
                ? value.tpsMoteurFin
                : "-",
          },
          dateReparation: {
            name: t("QIREPARE_Realization_Date"),
            value:
              (value?.faultstatus ||
                value?.faultstatus?.name === "Panne réparée" ||
                value?.faultstatus?.name === "Dépanage") &&
              value.dateFin &&
              value.dateFin !== "0000-00-00 00:00:00"
                ? format(addHours(parseISO(value.dateFin), 2), "dd/MM/yyyy")
                : "-",
          },
          intervenant: {
            name: t("WORD_Contributor"),
            value: currentIntervenant
              ? currentIntervenant.nom + " " + currentIntervenant.prenom
              : "-",
          },
        }
      : // ---------- Entretien ---------
      value.IDstat
      ? {
          entretiens: {
            name: "entretiens",
            value: getEntretiensArr(entretiens),
          },
        }
      : // ---------- Entretien programmé ---------
        {
          state: {
            name: t("WORD_State"),
            value: etatSwitch(value.etat),
          },
          type: {
            name: t("WORD_Type"),
            value:
              listAllType.find((type) => type.idType === value.idAlertType)
                ?.nomType || "-",
          },
          tpsRestant: {
            name: t("ACTIVE_ISLET_Remaining"),
            value:
              value.etat <= 2
                ? value.heureLimite && engineTimeRounded && value.heureActuel
                  ? `${
                      value.heureLimite -
                      (engineTimeRounded - value.heureActuel)
                    } h`
                  : `${differenceInDays(
                      parseISO(value.reminder_date_max),
                      new Date()
                    )} jours`
                : "-",
          },
          tpsCreation: {
            name: t("QIREPARE_Creation_Engine_Time"),
            value: value.heureActuel || "-",
          },
          tpsAlert: {
            name: t("WORD_Alert"),
            value: value.heureLimite
              ? `${value.heureLimite} h (${
                  value.heureActuel + value.heureLimite
                } h)`
              : "-",
          },
          tpsRappel: {
            name: t("WORD_Reminder"),
            value: value.rappel
              ? `${value.rappel} h (${
                  value.heureActuel + value.heureLimite - value.rappel
                } h)`
              : "-",
          },
          dateMax: {
            name: t("QIREPARE_Maximum_Date"),
            value: value.reminder_date_max
              ? parseISO(value.reminder_date_max).toLocaleDateString(
                  undefined,
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                  }
                )
              : "-",
          },
          dateRappel: {
            name: t("QIREPARE_Reminder_Date"),
            value: value.reminder_date
              ? parseISO(value.reminder_date).toLocaleDateString(undefined, {
                  day: "numeric",
                  month: "numeric",
                  year: "2-digit",
                })
              : "-",
          },
        };

  return (
    <React.Fragment>
      {!hiddenButtonModal && (
        <Tooltip
          arrow={true}
          title={
            (
              <Typography style={{ fontSize: "1rem" }}>
                {t("WORD_Detailed_View")}
              </Typography>
            ) || ""
          }
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Visibility />
          </Button>
        </Tooltip>
      )}

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant="h3" color="primary" align="center">
            {t("WORD_Detailed_View")}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "5%" }}>
          <Grid container spacing={2}>
            {Object.values(commonData).map((item: any, index: number) => {
              return (
                <Grid item key={index + "itemText"} xs={index === 0 ? 12 : 6}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    key={index}
                    style={{
                      transform: "translatex(-50%)",
                      marginLeft: "50%",
                    }}
                    label={item.name}
                    value={item.value}
                  />
                </Grid>
              );
            })}
            {Object.values(specialData).map((item: any, index: number) => {
              return typeof item?.value === "boolean" ? (
                <Grid item xs={6} key={index + "itemPreMap"}>
                  <Checkbox checked={item.value} disabled />
                </Grid>
              ) : item.name === "entretiens" ? (
                item.value.map(
                  (entretien, index) =>
                    entretien.length > 0 && (
                      <Grid
                        item
                        key={index + "itemMap"}
                        xs={5}
                        style={{
                          backgroundColor: "#eeeeee",
                          margin: "auto",
                          marginTop: "2%",
                          borderRadius: "10px",
                          padding: "2%",
                        }}
                      >
                        <Typography variant="h6" color="primary">
                          {listTypeCategorie[index].nomCategorie}
                        </Typography>
                        {entretien}
                      </Grid>
                    )
                )
              ) : typeof item?.value === "object" ? (
                <Grid item xs={6} key={index + "itemMap"}>
                  {item.value}
                </Grid>
              ) : (
                <Grid item xs={6} key={index + "itemMap"}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    style={{
                      transform: "translatex(-50%)",
                      marginLeft: "50%",
                    }}
                    key={index}
                    label={item?.name}
                    value={item?.value}
                  />
                </Grid>
              );
            })}
            <Grid
              item
              xs={12}
              style={{
                marginTop: "3%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ButtonGroup orientation="horizontal">
                {value.idAlert && (
                  <Button
                    component={Link}
                    to={{
                      pathname: "/AppEntretienProgrammer",
                      state: {
                        alertToModify: value,
                      },
                    }}
                    onClick={(event) =>
                      dispatch(setNewEntretienModifyIdAlert(value.IDstat))
                    }
                  >
                    <Build style={{ color: "#414040" }} />
                  </Button>
                )}
                {value.idPanne && (
                  <Button
                    component={Link}
                    to={{
                      pathname: "/AppEntretienPanne",
                      state: {
                        panneToModify: value,
                      },
                    }}
                    onClick={(event) =>
                      dispatch(setNewEntretienModifyIdAlert(value.idPanne))
                    }
                  >
                    <Build style={{ color: "#414040" }} />
                  </Button>
                )}
                <Button>
                  <DeleteButton
                    idAlert={value.IDstat || value.idPanne || value.idAlert}
                    machineName={value.equipment.nomMachine}
                    entretienType={value.IDstat ? 1 : value.idPanne ? 2 : 0}
                    setOpenParent={setOpen}
                  />
                </Button>
              </ButtonGroup>
              <Button
                style={{ marginTop: "2%" }}
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t("QIRECORD_Close")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
