//https://kentcdodds.com/blog/how-to-use-react-context-effectively
import React from "react";
import { FormContextValues } from "react-hook-form";
import {
  DialogProps,
  ButtonProps,
  CircularProgress,
  DialogActionsProps,
  DialogContentProps,
  DialogTitleProps,
} from "@material-ui/core";
import { askConfirmationProps } from "./useConfirmationModal";
import LightBoxModal from "./LightboxModal.tsx";

export type ConfirmationProviderContent =
  | React.ReactNode
  | ((useFormProps: FormContextValues) => React.ReactNode);

export type CustomDialogProps = DialogProps & {
  disableCloseOnBackdropClick: boolean;
};

export type ConfirmationProviderOptions<T extends Record<string, unknown>> = {
  dialogProps?: Partial<CustomDialogProps>;
  dialogContentProps?: Partial<DialogContentProps>;
  dialogTitleProps?: Partial<DialogTitleProps>;
  acceptButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  dialogActionsProps?: Partial<DialogActionsProps>;
  /**
   * @function onAcceptConfirmation
   * @description Fonction middleware qui est appelé lors du clique sur le bouton valider.
   * @returns {askConfirmationProps} une Promise qui indique si nous pouvons passer la validation
   */
  onAcceptConfirmation?: (values: T) => askConfirmationProps<T>;
  /**
   * @function onCancelConfirmation
   * @description Fonction middleware qui est appelé lors du clique sur le bouton annuler. Return false pour annuler
   * * @returns {askConfirmationProps} une Promise qui indique si nous pouvons passer la validation
   */
  onCancelConfirmation?: (values: T) => askConfirmationProps<T>;
};

export type ModalProviderProps = {
  isOpen: boolean;
  title?: React.ReactNode;
  content?: ConfirmationProviderContent;
  options?: ConfirmationProviderOptions<Record<string, unknown>>;
  onAccept?: (
    event: React.SyntheticEvent,
    values?: Record<string, unknown>
  ) => void;
  onClose?: (event: React.SyntheticEvent) => void;
  onCancel?: (event: React.SyntheticEvent) => void;
};
export type LightBoxProviderProps = Omit<ModalProviderProps, "content"> & {
  imgProps: React.InputHTMLAttributes<HTMLInputElement>;
};
type ConfirmationProviderProps = {
  lightboxModalProps: LightBoxProviderProps;
  updateContext: React.Dispatch<
    React.SetStateAction<ConfirmationProviderProps>
  >;
};

const ConfirmationContext = React.createContext<
  Partial<ConfirmationProviderProps>
>({ isOpen: false });
ConfirmationContext.displayName = "ConfirmationController";

function ConfirmationProvider(
  props: ConfirmationProviderProps & { children?: React.ReactChild }
) {
  const { children, ...otherProps } = props;
  const [contextValue, setContextValue] =
    React.useState<ConfirmationProviderProps>(otherProps);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  return (
    <ConfirmationContext.Provider
      value={{ ...contextValue, updateContext: setContextValue }}
    >
      <React.Suspense fallback={<CircularProgress />}>
        <LightBoxModal />
        {props.children}
      </React.Suspense>
    </ConfirmationContext.Provider>
  );
}
function useConfirmation() {
  const context = React.useContext(ConfirmationContext);
  if (context === undefined) {
    throw new Error(
      "useConfirmation must be used within a ConfirmationProvider"
    );
  }
  return context;
}

export { ConfirmationProvider, useConfirmation };
