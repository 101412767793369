import * as Actions from "../actions/HistoriqueAction";
import * as LoginFormActions from "../actions/loginFormActions";

export default function HistoriqueReducer(
  state = {
    typeToShow: "",
    EntretienFinishtoShow: true,
    MachineFilter: "",
    TypeMaintenanceFilter: "",
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case Actions.SET_TYPE_TO_SHOW: {
      return {
        ...state,
        typeToShow: action.typeToShow
      };
    }
    case Actions.SET_FILTER_HISTORIQUE_TYPE_MAINTENANCE: {
      return {
        ...state,
        TypeMaintenanceFilter: action.TypeMaintenanceFilter
      };
    }
    case Actions.SET_FILTER_HISTORIQUE_MACHINE: {
      return {
        ...state,
        MachineFilter: action.MachineFilter
      };
    }
    case Actions.SET_IS_LOADING_HISTORIQUE: {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }
    case Actions.SET_ENTRETIEN_FINISH_TO_SHOW: {
      return {
        ...state,
        EntretienFinishtoShow: action.show
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        typeToShow: "",
        EntretienFinishtoShow: true,
        MachineFilter: "",
        TypeMaintenanceFilter: "",
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
}
