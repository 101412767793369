import { Socket, io } from "socket.io-client";

//const ADDR = "http://192.168.50.88:5010";
//const ADDR = "http://127.0.0.1:5010";
//const ADDR = "https://qtrepare.questinnov.com";
const ADDR = process.env.REACT_APP_HOST;

class Sockets {
  socket: Socket | null;

  constructor() {
    this.socket = null;

    this.connect();
  }

  async connect(token?: string) {
    if (this.socket) {
      //Kill the current socket
      this.socket.close();
    }

    this.socket = io(ADDR, {
      transports: ["websocket"],
      auth: {
        "x-token": token || null,
      },
    });
  }

  async fetchSocket(event: string, data: Record<string, unknown>) {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        return reject("Socket is not initialized");
      }
      this.socket.emit(event, data, (result) => {
        if (result?.Error) {
          return reject(result);
        }
        resolve(result);
      });
    });
  }
}

export default Sockets;
