/**
 * Classe principale qui gère la partie responsable
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import { serveurRequest } from "../api/socket.js";
import * as Actions from "../actions/ResponsableActions.js";
import * as MenuActions from "../actions/MenuActions.js";

class AppResponsable extends Component {
  componentWillMount = async => {
    this.props.dispatch(
      Actions.getResponsable(this.props.token, this.props.tenant)
    );

    this.props.dispatch(MenuActions.setPageName("Responsable"));
  };

  render() {
    return (
      <div className="container">
        {this.props.listResponsable.length !== 0 ? (
          <select className="form-control" onChange={this.modifierState}>
            <option key="null" value="null" defaultValue />
            {this.props.listResponsable.map(responsable => {
              return (
                <option
                  key={responsable.idResponsable}
                  value={responsable.idResponsable}
                >
                  {responsable.nom} {responsable.prenom}
                </option>
              );
            })}
          </select>
        ) : null}
        <br />
        {//si aucun responsable n'est sélectionné
        this.props.idSelect === "null" ? (
          <form
            onSubmit={async e => {
              e.preventDefault();
              serveurRequest("newResponsable", {
                token: this.props.token,
                nom: this.props.nom,
                prenom: this.props.prenom,
                mail: this.props.mail,
                idTenant: this.props.tenant
              }).then(
                res => {},
                rej => {
                  alert("Echec lors de la création du nouveau responsable");
                }
              );
              this.props.dispatch(
                Actions.getResponsable(this.props.token, this.props.tenant)
              );

              return (
                /* formulaire du responsable  */
                <div className="alert alert-success" role="alert">
                  Le responsable à été enregistré
                </div>
              );
            }}
          >
            <div className="row">
              <div className="col">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={event =>
                    this.props.dispatch(Actions.setNom(event.target.value))
                  }
                />
              </div>
              <div className="col">
                <label>Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={event =>
                    this.props.dispatch(Actions.setPrenom(event.target.value))
                  }
                />
              </div>
            </div>
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              onChange={event =>
                this.props.dispatch(Actions.setMail(event.target.value))
              }
            />
            <br />
            <button type="submit" className="btn btn-success">
              Enregistrer
            </button>
          </form>
        ) : (
          this.props.listResponsable.map(responsable => {
            if (responsable.idResponsable === this.props.idSelect) {
              return (
                <div key={responsable.idResponsable}>
                  <form
                    onSubmit={async e => {
                      e.preventDefault();
                      //modifie la valeur dans la BD
                      serveurRequest("modifResponsable", {
                        token: this.props.token,
                        nom: this.props.nom,
                        prenom: this.props.prenom,
                        mail: this.props.mail,
                        idResponsable: responsable.idResponsable
                      }).then(
                        res => {
                          alert("Le responsable a été modifié avec succès ");
                        },
                        rej => {
                          alert("Echec lors de la modification du responsable");
                        }
                      );
                      //Mise à jour de la liste des responsables
                      this.props.dispatch(
                        Actions.getResponsable(
                          this.props.token,
                          this.props.tenant
                        )
                      );

                      //affiche un alert box pour dire que le responsable est modifié
                      return (
                        <div className="alert alert-success" role="alert">
                          Le responsable à été modifié
                        </div>
                      );
                    }}
                  >
                    <div>
                      <div className="row">
                        <div className="col">
                          <label>Nom</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.props.nom}
                            onChange={event =>
                              this.props.dispatch(
                                Actions.setNom(event.target.value)
                              )
                            }
                          />
                        </div>
                        <div className="col">
                          <label>Prénom</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.props.prenom}
                            onChange={event =>
                              this.props.dispatch(
                                Actions.setPrenom(event.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.props.mail}
                        onChange={event =>
                          this.props.dispatch(
                            Actions.setMail(event.target.value)
                          )
                        }
                      />
                      <br />
                      <div className="row">
                        <div
                          className="col" //bouton de modification
                        >
                          <button type="submit" className="btn btn-success">
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <br />
                  {/*bouton de suppression*/}
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      data-toggle="modal"
                      data-target="#verifSuppression"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              );
            }
            return null;
          })
        )}
        {/*message de confirmation de suppression*/}
        <div
          className="modal fade"
          id="verifSuppression"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                Voulez-vous supprimer ce responsable?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={async e => {
                    e.preventDefault();
                    //Supression du responsable dans la BD
                    serveurRequest("dropResponsable", {
                      token: this.props.token,
                      idResponsable: this.props.idSelect
                    }).then(
                      res => {
                        alert("Le responsable a bien été effacé !");
                      },
                      rej => {
                        alert("Echec lors de la suppression du responsbale !");
                      }
                    );
                    //Mise à jour de la liste des responsables
                    this.props.dispatch(
                      Actions.getResponsable(
                        this.props.token,
                        this.props.tenant
                      )
                    );
                  }}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  modifierState = event => {
    /*Dès qu'on sélectionne un responsable on enregistre immédiatement ses valeurs dans le store  */
    this.props.dispatch(Actions.setSelected(event.target.value));
    if (event.target.value === "null") {
      this.props.dispatch(Actions.setNom(""));
      this.props.dispatch(Actions.setPrenom(""));
      this.props.dispatch(Actions.setMail(""));
    } else {
      for (var i = 0; i < this.props.listResponsable.length; i++) {
        if (
          this.props.listResponsable[i].idResponsable === event.target.value
        ) {
          this.props.dispatch(
            Actions.setNom(this.props.listResponsable[i].nom)
          );
          this.props.dispatch(
            Actions.setPrenom(this.props.listResponsable[i].prenom)
          );
          this.props.dispatch(
            Actions.setMail(this.props.listResponsable[i].mail)
          );
        }
      }
    }
  };
}

export default connect(state => ({
  token: state.loginForm.token,
  tenant: state.loginForm.idTenant,
  listResponsable: state.responsable.listResponsable,
  idSelect: state.responsable.idSelect,
  nom: state.responsable.nom,
  prenom: state.responsable.prenom,
  mail: state.responsable.mail
}))(AppResponsable);
