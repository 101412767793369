import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienType } from "../../../../../actions/EntretienProgrammerActions";
import { getAllTypeVisibleInProgrammer } from "../../../../../utils/EntretiensUtils";

type ScheduleTypeProps = { defaultValue?: Record<string, unknown> };

function ScheduleType(props: ScheduleTypeProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const category = useSelector((state) => state.data.listTypeCategorie);
  const subcategory = useSelector((state) => state.data.listType);

  const subcategoryForSchedule = React.useMemo(
    () => getAllTypeVisibleInProgrammer(category, subcategory),
    [category, subcategory]
  );

  return (
    <Controller
      name="SousCategorie"
      control={control}
      rules={{
        required: "Veuillez renseigner la categorie de l'entretien !",
      }}
      onChange={([event, value]) => {
        //Dispatch de la nouvelle valeur
        dispatch(setNewEntretienType(value));

        return value;
      }}
      defaultValue={props.defaultValue || ""}
      as={
        <Autocomplete
          options={subcategoryForSchedule}
          getOptionLabel={(SCAT) => (SCAT ? SCAT.nomType : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={t("WORD_Type")}
              error={!!errors.SousCategorie}
              helperText={errors.SousCategorie?.message || ""}
            />
          )}
        />
      }
    />
  );
}

export default ScheduleType;
