import React, { useState } from "react";

import {
  Paper,
  Grid,
  Typography,
  GridList,
  GridListTile,
  GridListTileBar,
  Checkbox,
  useTheme,
} from "@material-ui/core";
import { deleteFileFromArray } from "../utils/EntretiensUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  getNumberOfAnomalieWithFilters,
  isPictureInFilters,
  getNumberOfCourantWithFilters,
  isPictureInCategorie,
  getCompleteURLFromPictureObject,
} from "../utils/GEDutils";

import * as Actions from "../actions/GalerieActions";

import AspectRatio from "react-aspect-ratio";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import ImageLightBox from "./useImageLightBox";

export default function ViewerGED(props) {
  const { SCategories, selectMode, Machines, PannesList } = props;

  //Création d'un état pour controller ouverture de l'image lightbox
  const [openLightBox, setOpenLightBox] = useState(false);

  //Création de l'objet picture qui alimentera notre objet lightbox
  const [pictureLightBox, setPictureLightBox] = useState("");

  //Création de notre objet de dispatch
  const dispatch = useDispatch();

  //Création d'un theme et des breakpoint pour rendre les composants responsive
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  //Récupération de l'état isLoading
  const isLoading = useSelector((state) => state.galerie.isLoading);

  //Récupération de nos filtres
  const filters = useSelector((state) => state.galerie.filters);

  //Récupération des images liée au anomalies
  const pictures = useSelector((state) => state.galerie.pictures);

  //Récupéraiton des images que l'utilisateru souhaite télécharger
  const picturesToDownload = useSelector(
    (state) => state.galerie.picturesToDownload
  );

  const lightboxOpen = (event, picture) => {
    setPictureLightBox(picture);
    setOpenLightBox(true);
  };

  const lightboxClose = (e) => {
    setOpenLightBox(false);
    setPictureLightBox("");
  };

  return (
    <Paper elevation={4}>
      {/* MODAL QUI AFFICHE LES IMAGES EN FULLSCREEN */}
      <ImageLightBox
        picture={pictureLightBox}
        isOpen={openLightBox}
        closeRequest={(e) => lightboxClose(e)}
        SCategories={SCategories}
        PannesList={PannesList}
        Machines={Machines}
      />

      {/* GRID UTILISE POUR AFFICHER LE TITRE && LES VIGNETTES DES ANOMALIES*/}
      {!isLoading ? (
        getNumberOfAnomalieWithFilters(pictures, filters, SCategories) > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* GRID UTILISE POUR AFFICHER LE TITRE*/}
            <Grid item xs={12}>
              <Typography
                variant="overline"
                style={{ fontSize: sm ? "6vw" : "2vw", padding: "16px" }}
              >
                Anomalies
              </Typography>
            </Grid>
            {/* GRID UTILISE POUR AFFICHER LES VIGNETTES*/}
            <Grid item xs={12} style={{ padding: "12px" }}>
              <GridList spacing={2} cols={12} style={{ width: "100%" }}>
                {pictures.map((picture) => {
                  if (
                    isPictureInCategorie(picture, true, false) &&
                    isPictureInFilters(picture, filters, SCategories)
                  ) {
                    return (
                      <GridListTile cols={sm ? 6 : 3} key={picture.name}>
                        <label htmlFor={picture.name}>
                          <div
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={(event) =>
                              !selectMode && lightboxOpen(event, picture)
                            }
                          >
                            <img
                              src={picture.pathName}
                              alt={picture.name}
                              style={{
                                display: "block",
                                width: "auto",
                                height: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                          <GridListTileBar
                            titlePosition="top"
                            title={`${moment(picture.uploadDate).format(
                              "YYYY/MM/DD HH:mm:ss"
                            )}`}
                            actionIcon={
                              selectMode ? (
                                <Checkbox
                                  name={picture.name}
                                  id={picture.name}
                                  onChange={(event) => {
                                    const newPictureSelected = pictureSelected(
                                      event,
                                      picture,
                                      picturesToDownload
                                    );
                                    dispatch(
                                      Actions.setPicturesToDownload(
                                        newPictureSelected
                                      )
                                    );
                                  }}
                                  value={picture}
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      style={{ color: "white" }}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon style={{ color: "white" }} />
                                  }
                                />
                              ) : null
                            }
                          />
                        </label>
                      </GridListTile>
                    );
                  } else {
                    return null;
                  }
                })}
              </GridList>
            </Grid>
          </Grid>
        )
      ) : (
        /* SKELETON A AFFICHER PENDANT LE CHARGEMENT DES PHOTOS */
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* GRID UTILISE POUR AFFICHER LE TITRE*/}
          <Grid item xs={12}>
            <Typography
              variant="overline"
              style={{ fontSize: sm ? "6vw" : "2vw", padding: "16px" }}
            >
              Anomalies...
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "12px" }}>
            <GridList spacing={2} cols={12} style={{ width: "100%" }}>
              {[0, 1, 2, 3].map((element) => {
                return (
                  <GridListTile cols={sm ? 6 : 3} key={element}>
                    <Skeleton variant="rect" width="100%" height="100%" />
                  </GridListTile>
                );
              })}
            </GridList>
          </Grid>
        </Grid>
      )}

      {/* GRID UTILISE POUR AFFICHER LE TITRE && LES VIGNETTES DES ENTRETIENS COURANTS*/}
      {!isLoading ? (
        getNumberOfCourantWithFilters(pictures, filters, SCategories) > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* GRID UTILISE POUR AFFICHER LE TITRE*/}
            <Grid item xs={12}>
              <Typography
                variant="overline"
                style={{ fontSize: sm ? "6vw" : "2vw", padding: "19px" }}
              >
                Entretiens courants
              </Typography>
            </Grid>
            {/* GRID UTILISE POUR AFFICHER LES VIGNETTES*/}
            <Grid item xs={12} style={{ padding: "12px" }}>
              <GridList spacing={2} cols={12} style={{ width: "100%" }}>
                {pictures.map((picture) => {
                  if (
                    isPictureInCategorie(picture, false, true) &&
                    isPictureInFilters(picture, filters, SCategories)
                  ) {
                    return (
                      <GridListTile cols={sm ? 6 : 3} key={picture.name}>
                        <label htmlFor={picture.name}>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={(event) =>
                              !selectMode && lightboxOpen(event, picture)
                            }
                          >
                            <img
                              src={picture.pathThumbnail}
                              style={{
                                display: "block",
                                width: "auto",
                                height: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                              alt={picture.name}
                            />
                          </div>
                          <GridListTileBar
                            titlePosition="top"
                            title={`${moment(picture.uploadDate).format(
                              "YYYY/MM/DD HH:mm:ss"
                            )}`}
                            actionIcon={
                              selectMode ? (
                                <Checkbox
                                  name={picture.name}
                                  id={picture.name}
                                  value={picture}
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      style={{ color: "white" }}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon style={{ color: "white" }} />
                                  }
                                  onChange={(event) => {
                                    const newPictureSelected = pictureSelected(
                                      event,
                                      picture,
                                      picturesToDownload
                                    );
                                    dispatch(
                                      Actions.setPicturesToDownload(
                                        newPictureSelected
                                      )
                                    );
                                  }}
                                />
                              ) : null
                            }
                          />
                        </label>
                      </GridListTile>
                    );
                  } else {
                    return null;
                  }
                })}
              </GridList>
            </Grid>
          </Grid>
        )
      ) : (
        /* SKELETON A AFFICHER PENDANT LE CHARGEMENT DES PHOTOS */
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* GRID UTILISE POUR AFFICHER LE TITRE*/}
          <Grid item xs={12}>
            <Typography
              variant="overline"
              style={{ fontSize: sm ? "6vw" : "2vw", padding: "16px" }}
            >
              Entretiens courants...
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "12px" }}>
            <GridList spacing={2} cols={12} style={{ width: "100%" }}>
              {[0, 1, 2, 3].map((element) => {
                return (
                  <GridListTile cols={sm ? 6 : 3} key={element}>
                    <Skeleton variant="rect" width="100%" height="100%" />
                  </GridListTile>
                );
              })}
            </GridList>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

function pictureSelected(event, picture, pictureSelected) {
  if (event.target.checked) {
    //Si l'utilisateur à coché la checkbox, nous allons rajouter la photo au tableau des photos séléctionnées
    pictureSelected.push(picture);
    return pictureSelected;
  } else {
    //Si l'utilisateur décoche la checkbox, nous allons supprimer, si elle existe, la photo du tableau des photos séléctionnées
    return deleteFileFromArray(pictureSelected, picture);
  }
}
