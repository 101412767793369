import { TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienCommentaire } from "../../../../../actions/EntretienProgrammerActions";

type FinalCommentProps = {
  defaultValue?: string;
};

function FinalComment(props: FinalCommentProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <Controller
      control={control}
      name="Commentaire"
      defaultValue={props.defaultValue || ""}
      onChange={([event, value]) => {
        dispatch(setNewEntretienCommentaire(value));

        return value;
      }}
      as={(props) => (
        <TextField
          {...props}
          onChange={(event) => props.onChange(event, event.target.value)}
          fullWidth
          label={t("LOGISTIC_comment")}
          variant="outlined"
          placeholder={t("LOGISTIC_comment")}
          multiline
        />
      )}
    />
  );
}

export default FinalComment;
