import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import Badge from "@material-ui/core/Badge";
import {
  Avatar,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@material-ui/core";
import {
  BuildRounded,
  CheckCircleOutlineRounded,
  SyncRounded,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

function SimpleDialog(props) {
  const { onClose, open, Commentaire, value } = props;

  const { t } = useTranslation();

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {t("LOGISTIC_comment")} - {value}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{Commentaire}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ShowType(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const ChipIcon = React.useMemo(() => {
    switch (props.value) {
      case "check":
        return <CheckCircleOutlineRounded style={{ color: "#414040" }} />;

      case "repair":
        return <BuildRounded style={{ color: "#414040" }} />;

      case "replace":
        return <SyncRounded style={{ color: "#DADAD9" }} />;

      default:
        return <CheckCircleOutlineRounded style={{ color: "#414040" }} />;
    }
  }, [props]);

  const ChipStyle = React.useMemo(() => {
    switch (props.value) {
      case "check":
        return {};
      case "repair":
        return { backgroundColor: "#FF8C00" };
      case "replace":
        return { backgroundColor: "#C4161B", color: "#DADAD9" };
      default:
        return {};
    }
  }, [props]);

  const FormattedValue = React.useMemo(() => {
    switch (props.value) {
      case "check":
        return t("WORD_Checked");
      case "repair":
        return t("WORD_Repaired");
      case "replace":
        return t("WORD_Replaced");
      default:
        return props.value || "";
    }
  }, [props.value]);

  return (
    <div>
      <Badge
        color="secondary"
        badgeContent={props.commentaire ? 1 : 0}
        style={{ margin: "1%" }}
        onClick={handleClickOpen}
        overlap="rectangular"
      >
        <Tooltip title={props.commentaire || FormattedValue || ""}>
          <Chip
            label={props.nomType}
            key={props.nomType}
            style={{ cursor: "pointer", ...ChipStyle }}
            icon={ChipIcon}
          />
        </Tooltip>
      </Badge>
      {props.commentaire && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          value={FormattedValue}
          Commentaire={props.commentaire}
        />
      )}
    </div>
  );
}
