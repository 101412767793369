import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";

export default function SecureCompteur(props) {
  const [open, setOpen] = useState(true);

  const {
    compteurRef,
    compteur,
    machine,
    declineCallback = function() {},
    acceptCallback = function() {}
  } = props;

  function Decline() {
    //dispatch(setVerification(false));
    open && setOpen(false);
    declineCallback(false);
  }

  function Accept() {
    //dispatch(setVerification(false));
    //dispatch(setVerificationFinish(true));
    open && setOpen(false);
    acceptCallback(true);
  }
  //Supérieur à la limite on demande confirmation à l'utilisateur
  return (
    <Dialog aria-labelledby="alert-dialog-title" open={open}>
      <DialogTitle id="alert-dialog-title">
        {"Confirmation du compteur horaire de la machine"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Le dernier compteur horaire connu de " +
            machine.nomMachine +
            " est de " +
            compteurRef +
            ". Vous avez rentré un compteur horaire pour cette machine de " +
            compteur +
            ". Cela est-il correct ?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={Decline}>NON</Button>
        <Button onClick={Accept} autoFocus>
          OUI
        </Button>
      </DialogActions>
    </Dialog>
  );
}
