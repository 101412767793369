/**
 * Contenu du site par défaut : s'affiche lorsque la connexion n'est pas effective. Lors de la tentative de la connexion, la classe de chargement est appelée
 */

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginForm from "../hook/useLoginForm";
import AppProgress from "./AppProgress";
import LanguageChoice from "./Login/LanguageChoice.tsx";

function AppLogin(props) {
  const { t } = useTranslation();
  const username = useSelector((state) => state.loginForm.username);
  const password = useSelector((state) => state.loginForm.password);
  const tenant = useSelector((state) => state.loginForm.tenant);
  const saveSession = useSelector((state) => state.loginForm.saveSession);
  const localLanguage = useSelector((state) => state.loginForm.language);
  const isLoginInProgress = useSelector(
    (state) => state.loginForm.isLoginInProgress
  );
  const isLoggedIn = useSelector((state) => state.loginForm.isLoggedIn);

  // Si la tentative de connexion est en cours, on affiche le rond de chargement
  if (isLoginInProgress) {
    return <AppProgress />;
  }

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      align="center"
    >
      {isLoggedIn && <Redirect to="/" />}

      {/*Message d'accueil*/}
      <Grid
        item
        container
        xs={10}
        justifyContent="center"
        alignItems="center"
        style={{ margin: "16px 0", columnGap: "16px" }}
      >
        <Typography variant="subtitle1" gutterBottom align="center">
          {t("LOGIN_Qirepare_Description")}
          <br />
        </Typography>
        <LanguageChoice
          language={
            localLanguage || navigator.language || navigator.userLanguage
          }
        />
      </Grid>

      <Grid item xs={10} md={4}>
        <LoginForm
          username={username}
          password={password}
          tenant={tenant}
          saveSession={saveSession}
        />
      </Grid>
    </Grid>
  );
}

export default AppLogin;
