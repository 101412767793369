import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNewEntretienMachine } from "../../../../../actions/EntretienProgrammerActions";

export type EquipmentProps = { defaultValue?: Record<string, unknown> };

function Equipment(props: EquipmentProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const allEquipment = useSelector((state) => state.machine.listMachine);

  return (
    <Controller
      name="Machine"
      control={control}
      rules={{
        required: t("QIREPARE_Select_Device"),
      }}
      defaultValue={props.defaultValue || ""}
      as={
        <Autocomplete
          options={allEquipment}
          getOptionLabel={(equipment) => equipment.nomMachine || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={t("WORK_device")}
              error={!!errors.Machine}
              helperText={errors.Machine?.message || ""}
            />
          )}
        />
      }
      onChange={([event, value]) => {
        //Dispatch de la nouvelle valeur
        dispatch(setNewEntretienMachine(value));

        return value;
      }}
    />
  );
}

export default Equipment;
