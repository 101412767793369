// Fichier de reducers Redux qui gère la page principale après authentification
import * as LoginFormActions from "../actions/loginFormActions";
import * as Actions from "../actions/MenuActions";

export default function menuReducer(
  state = {
    derniereMAJ: "",
    pageName: "",
    verification: false,
    verificationFinish: false
  },
  action
) {
  switch (action.type) {
    case Actions.SET_DER_MAJ: {
      return {
        ...state,
        derniereMAJ: action.derMAJ
      };
    }
    case Actions.SET_PAGE_NAME: {
      return {
        ...state,
        pageName: action.Pname
      };
    }
    case Actions.SET_VERIFICATION: {
      return {
        ...state,
        verification: action.verification
      };
    }
    case Actions.SET_VERIFICATION_FINISH: {
      return {
        ...state,
        verificationFinish: action.verificationFinish
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        derniereMAJ: "",
        pageName: "",
        verification: false,
        verificationFinish: false
      };
    }
    default: {
      return state;
    }
  }
}
