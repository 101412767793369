import { Box, Typography } from "@material-ui/core";
import { LocalShippingRounded } from "@material-ui/icons";
import React from "react";

type CardDividerProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  failureColor?: string;
};
function CardDivider(props: CardDividerProps) {
  return (
    <Box
      style={{
        width: "100%",
        height: "22px",
        backgroundColor: "#414040",
        borderRadius: "8px",
        padding: "8px",
        display: "flex",
        justifyContent: props.failureColor ? "center" : "auto",
        columnGap: "8px",
      }}
    >
      {props.failureColor ? (
        <div
          style={{
            height: "15px",
            width: "15px",
            marginTop: "4px",
            borderRadius: "50%",
            backgroundColor: props.failureColor,
            marginRight: "3px",
          }}
        />
      ) : (
        <LocalShippingRounded style={{ color: "#DADAD9" }} />
      )}
      <Typography style={{ color: "#dadad9" }}>{props.title}</Typography>
    </Box>
  );
}

export default CardDivider;
