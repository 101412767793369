import requestType from "../utils/requestType";
import { serveurRequest } from "../api/socket";
import moment from "moment";
import { formatEngineTimeForServer } from "../utils/format.ts";

export const ADD_ENTRETIEN = requestType("AJOUT_ENTRETIEN_PROGRAMMER");
export const MODIFY_ENTRETIEN = requestType("MODIFIER_ENTRETIEN_PROGRAMMER");

export const SET_TYPE_NEW_ENTRETIEN = "SET_TYPE_NEW_ENTRETIEN";
export const setNewEntretienType = (typeEntretien) => async (dispatch) => {
  dispatch({ type: SET_TYPE_NEW_ENTRETIEN, typeEntretien });
};

export const SET_MACHINE_NEW_ENTRETIEN = "SET_MACHINE_NEW_ENTRETIEN";
export const setNewEntretienMachine = (machine) => async (dispatch) => {
  dispatch({ type: SET_MACHINE_NEW_ENTRETIEN, machine });
};

export const SET_DATE_CREATION_NEW_ENTRETIEN =
  "SET_DATE_CREATION_NEW_ENTRETIEN";
export const setNewEntretienDateCreation = (date) => async (dispatch) => {
  dispatch({ type: SET_DATE_CREATION_NEW_ENTRETIEN, date });
};

export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const setNewEntretienDescription = (description) => async (dispatch) => {
  dispatch({ type: SET_DESCRIPTION, description });
};

export const SET_COMMENTAIRE = "SET_COMMENTAIRE";
export const setNewEntretienCommentaire = (commentaire) => async (dispatch) => {
  dispatch({ type: SET_COMMENTAIRE, commentaire });
};

export const SET_RESPONSABLE_NEW_ENTRETIEN = "SET_RESPONSABLE_NEW_ENTRETIEN";
export const setNewEntretienResponsable =
  (listResponsable) => async (dispatch) => {
    dispatch({ type: SET_RESPONSABLE_NEW_ENTRETIEN, listResponsable });
  };

export const SET_A_FAIRE = "SET_A_FAIRE";
export const setNewEntretienAFaire = (aFaire) => async (dispatch) => {
  dispatch({ type: SET_A_FAIRE, aFaire });
};

export const SET_RAPPEL = "SET_RAPPEL";
export const setNewEntretienRappel = (rappel) => async (dispatch) => {
  dispatch({ type: SET_RAPPEL, rappel });
};

export const SET_FAIT_A = "SET_FAIT_A";
export const setNewEntretienFaitA = (DoAt) => async (dispatch) => {
  dispatch({ type: SET_FAIT_A, DoAt });
};

export const SET_RENOUVELER = "SET_RENOUVELER";
export const setNewEntretienRenouveler = (renouveler) => async (dispatch) => {
  dispatch({ type: SET_RENOUVELER, renouveler });
};

export const SET_EFFECTUER = "SET_EFFECTUER";
export const setNewEntretienEffectuer = (effectuer) => async (dispatch) => {
  dispatch({ type: SET_EFFECTUER, effectuer });
};

export const SET_INPROGRESS = "SET_INPROGRESS";
export const setNewEntretienInProgress = (InProgress) => async (dispatch) => {
  dispatch({ type: SET_INPROGRESS, InProgress });
};

export const SET_MODIFY_ID_ALERT = "SET_MODIFY_ID_ALERT";
export const setNewEntretienModifyIdAlert = (idAlert) => async (dispatch) => {
  dispatch({ type: SET_MODIFY_ID_ALERT, idAlert });
};

export const SET_IS_FINISH = "SET_IS_FINISH";
export const setisFinish = (isFinish) => async (dispatch) => {
  dispatch({ type: SET_IS_FINISH, isFinish });
};

export const RESET_ENTRETIEN = "RESET_ENTRETIEN";
export const resetEntretien = () => async (dispatch) => {
  dispatch({ type: RESET_ENTRETIEN });
};

export const AjoutEntretienProgrammer =
  (token, idTenant, data, tempsMoteur) => async (dispatch, getState) => {
    dispatch({ type: ADD_ENTRETIEN.Request });

    const {
      SousCategorie,
      Machine,
      BeginDate,
      description,
      Responsables,
      Commentaire,
      TimeToDo,
      Rappel,
      DoAt,
      Renouveler,
      reminder_date,
      maximum_date,

      IsDone,
    } = data;

    /*   if (
    SousCategorie.idType.length === 0 ||
    idMachine.length === 0 ||
    dateCreation.length === 0 ||
    idResponsable.length === 0 ||
    TimeToDo.length === 0 ||
    rappel.length === 0 ||
    (effectuer && DoAt.length === 0)
  ) {
    dispatch({ type: ADD_ENTRETIEN.Failure });
    return alert("Veuillez remplir tout les champs.");
  } */

    const etat = IsDone ? 3 : 0;

    const ResponsablesID =
      Responsables?.length > 0
        ? Responsables.map((responsable) => {
            return responsable.idResponsable;
          })
        : null;

    const state = getState();

    const selectedEquipment = state.machine.listMachine.find((machine) => {
      return machine.idMachine === Machine.idMachine;
    });

    serveurRequest("POST:maintenance/schedule", {
      idResponsable: ResponsablesID,
      idType: SousCategorie.idType,
      idMachine: Machine.idMachine,
      idDevice: selectedEquipment.geofarmData?.equipment_association?.device_id,
      idTenant: idTenant,
      reminder_max_date: maximum_date
        ? maximum_date.hour(8).toISOString()
        : null,
      reminder_date: reminder_date ? reminder_date.hour(8).toISOString() : null,
      dateCreation: BeginDate.toISOString(),
      description: description || "",
      heureLimite: TimeToDo || null,
      rappel: Rappel || null,
      heureActuel: formatEngineTimeForServer(tempsMoteur),
      etat: etat,
      commentaire: Commentaire,
      dateFin: IsDone ? moment().toISOString() : null,
      compteurMachineFin: DoAt ? formatEngineTimeForServer(DoAt) : null,
      idIntervenant: IsDone ? state.loginForm.user.id : null,
      renew: Renouveler,
    }).then(
      (res) => {
        dispatch({ type: ADD_ENTRETIEN.Success });
        return alert("Votre entretien a bien été ajouté");
      },
      (rej) => {
        dispatch({ type: ADD_ENTRETIEN.Failure });
        return alert("Erreur lors de la création de l'entretien");
      }
    );

    return;
  };

export const ModifierEntretienProgrammer =
  (token, alertID, tenantID, data, tempsMoteur) =>
  async (dispatch, getState) => {
    dispatch({ type: MODIFY_ENTRETIEN.Request });

    const {
      SousCategorie,
      Machine,
      BeginDate,
      description,
      Responsables,
      Commentaire,
      TimeToDo,
      Rappel,
      DoAt,
      Renouveler,
      reminder_date,
      maximum_date,
      IsDone,
    } = data;

    const state = getState();

    const etat = IsDone ? 3 : 0;

    const ResponsablesID =
      Responsables?.length > 0
        ? Responsables.map((responsable) => {
            return responsable.idResponsable;
          })
        : null;

    const selectedEquipment = state.machine.listMachine.find((machine) => {
      return machine.idMachine === Machine.idMachine;
    });

    serveurRequest("PUT:maintenance/schedule", {
      idAlert: alertID,
      idResponsable: ResponsablesID,
      idType: SousCategorie.idType,
      idMachine: Machine.idMachine,
      idDevice: selectedEquipment.geofarmData?.equipment_association?.device_id,
      idTenant: tenantID,
      reminder_date_max: maximum_date
        ? maximum_date.hour(8).toISOString()
        : null,
      reminder_date: reminder_date ? reminder_date.hour(8).toISOString() : null,
      dateCreation: BeginDate.toISOString(),
      description: description || "",
      heureLimite: TimeToDo || null,
      rappel: Rappel || null,
      heureActuel: formatEngineTimeForServer(tempsMoteur),
      etat: etat,
      commentaire: Commentaire,
      dateFin: IsDone ? moment().toISOString() : null,
      compteurMachineFin: DoAt ? formatEngineTimeForServer(DoAt) : null,
      idIntervenant: IsDone ? state.loginForm.user.id : null,
      renew: Renouveler,
    }).then(
      (res) => {
        dispatch({ type: MODIFY_ENTRETIEN.Success });
        if (Renouveler) {
          alert("Votre entretien à bien été modifié et renouvelé");
        } else {
          alert("Votre entretien à bien été modifié");
        }
      },
      (rej) => {
        dispatch({ type: MODIFY_ENTRETIEN.Failure });
        return alert("Erreur lors de la modification de l'entretien");
      }
    );
  };
