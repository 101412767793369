// Fichier de reducers Redux qui gère la partie entretien
import * as LoginFormActions from "../actions/loginFormActions";
import * as Actions from "../actions/EntretienPanneActions";

export default function EntretienPanneReducer(
  state = {
    title: "",
    faultStatus: {},
    idMachine: "",
    tpsMoteur: "",
    idResponsable: "",
    description: "",
    isDone: false, //permet de savoir si une anomalie à ou non été traitée
    tpsMoteurFin: "",
    dateFin: "",
    photosPanne: [],
    photosPanneFacture: [],
    isLoading: false,
    isFinish: false,
    idIntervenant: null,
    commentaire: null,
  },
  action
) {
  switch (action.type) {
    case Actions.SET_TITLE_PANNE: {
      return {
        ...state,
        title: action.title,
      };
    }
    case Actions.SET_FAULT_STATUS_PANNE: {
      return {
        ...state,
        faultStatus: action.faultStatus,
      };
    }
    case Actions.SET_ID_MACHINE_PANNE: {
      return {
        ...state,
        idMachine: action.idMachine,
      };
    }
    case Actions.SET_TPS_MOTEUR_PANNE: {
      return {
        ...state,
        tpsMoteur: action.tpsMoteur,
      };
    }
    case Actions.SET_TPS_MOTEUR_FIN_PANNE: {
      return {
        ...state,
        tpsMoteurFin: action.tpsMoteur,
      };
    }
    case Actions.SET_ID_RESPONSABLE_PANNE: {
      return {
        ...state,
        idResponsable: action.idResponsable,
      };
    }
    case Actions.SET_ID_INTERVENANT_PANNE: {
      return {
        ...state,
        idIntervenant: action.idResponsable,
      };
    }
    case Actions.SET_PHOTOS_PANNE: {
      return {
        ...state,
        photosPanne: action.photosPanne,
      };
    }
    case Actions.SET_PHOTOS_PANNE_FACTURE: {
      return {
        ...state,
        photosPanneFacture: action.photosPanneFacture,
      };
    }
    case Actions.SET_DESCRIPTION_PANNE: {
      return {
        ...state,
        description: action.description,
      };
    }
    case Actions.SET_COMMENTAIRE_PANNE: {
      return {
        ...state,
        commentaire: action.commentaire,
      };
    }
    case Actions.SET_DATE_FIN: {
      return {
        ...state,
        dateFin: action.dateFin,
      };
    }
    case Actions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case Actions.SET_IS_FINISH: {
      return {
        ...state,
        isFinish: action.isFinish,
      };
    }
    case Actions.RESET_ENTRETIEN_PANNE: {
      return {
        ...state,
        title: "",
        faultStatus: {},
        idMachine: "",
        tpsMoteur: "",
        idResponsable: "",
        description: "",
        isDone: false,
        tpsMoteurFin: "",
        dateFin: "",
        photosPanne: [],
        isLoading: false,
        isFinish: false,
        idIntervenant: null,
        commentaire: null,
      };
    }
    case Actions.AJOUT_ENTRETIEN_PANNE.Request: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.AJOUT_ENTRETIEN_PANNE.Success: {
      return {
        ...state,
        title: "",
        faultStatus: {},
        idMachine: "",
        tpsMoteur: "",
        idResponsable: "",
        description: "",
        isDone: false,
        tpsMoteurFin: "",
        dateFin: "",
        photosPanne: [],
        photosPanneFacture: [],
        isLoading: false,
        isFinish: false,
        idIntervenant: null,
        commentaire: null,
      };
    }

    case Actions.AJOUT_ENTRETIEN_PANNE.Failure: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        title: "",
        faultStatus: {},
        idMachine: "",
        tpsMoteur: "",
        idResponsable: "",
        description: "",
        isDone: false, //permet de savoir si une anomalie à ou non été traitée
        tpsMoteurFin: "",
        dateFin: "",
        photosPanne: [],
        isLoading: false,
        isFinish: false,
        idIntervenant: null,
        commentaire: null,
      };
    }

    default: {
      return state;
    }
  }
}
