import { getBlobFromURL } from "../api/File";
import Constants from "../Constants";
import moment from "moment";

export function getLastTpsMoteurForMachineID(
  allCompteur,
  machineID,
  AllData = false
) {
  if (machineID !== undefined) {
    //eslint-disable-next-line array-callback-return
    const compteur = allCompteur.find((element) => {
      if (element.idMachine.toString() === machineID.toString()) {
        return element;
      }
    });

    if (compteur !== undefined) {
      return AllData ? compteur : parseInt(compteur.tempsMoteur);
      //this.props.dispatch(Actions.setTpsMoteurCourant(Math.round(compteur.tempsMoteur)));
    }
    return 0;
    //this.props.dispatch(Actions.setTpsMoteurCourant(0));
  } else {
    return "-";
  }
}
export function addPhotoToPhotoEntretien(photosEntretien, photos, typeID) {
  //On copie le trableau par soucis d'immuabilité
  var _photosEntretien = Object.assign([], photosEntretien);

  //Recherche si le typeID existe déjà dans photosEntretien
  const isPresent = _photosEntretien.findIndex(
    (element) => element.typeID === typeID
  );

  if (isPresent !== -1) {
    //Il y à deja des photos pour cette entretien
    //On rajoute la photo
    if (photos instanceof File) {
      //Si nous avons uniquement une seul photo à rajouter
      _photosEntretien[isPresent].photos.push(photos);
    } else if (photos instanceof FileList) {
      //Si nous en avons plusieurs

      _photosEntretien[isPresent].photos = _photosEntretien[
        isPresent
      ].photos.concat(Array.from(photos));
    }
  } else {
    //Il n'y à aucune photo => on crée le nouvel élément
    if (photos.length === 1) {
      //Si nous avons uniquement une seul photos à rajouter
      _photosEntretien.push({
        typeID: typeID,
        photos: Array.from(photos),
      });
    } else {
      //Sinon
      _photosEntretien.push({
        typeID: typeID,
        photos: Array.from(photos),
      });
    }
  }

  return _photosEntretien;
}

export function getAllPhotosFromTypeID(photosEntretien, typeID) {
  if (photosEntretien) {
    return photosEntretien.filter((photo) => photo.typeID === typeID);
    const findEntretien = photosEntretien.findIndex(
      (element) => element.typeID === typeID
    );

    if (findEntretien !== -1) {
      //entretien trouvé

      return photosEntretien[findEntretien].file;
    }
    //Entretien inexistant

    return [];
  }
  //photosEntretien non valide

  return [];
}

export function getNumberOfPhotoForCategoryID(
  SousCategorie,
  photosEntretien,
  categorieID
) {
  var numberOfPhotos = 0;

  SousCategorie.forEach((SC) => {
    const SC_type = SC.idType;
    const SC_categorie = SC.idCategorie;

    if (
      photosEntretien &&
      SousCategorie &&
      categorieID &&
      SC_categorie === categorieID
    ) {
      //Si la SC fait partie de la categorie chosie
      //Alors on compte le nombre de photos presents dans cette SC

      //On cherche d'abord s'il y à un entretien liéé à cette SC
      const isPresent = photosEntretien.findIndex(
        (element) => element.typeID === SC_type
      );

      if (isPresent !== -1) {
        //si il existe des photos pour cette SC, on additione le tout
        numberOfPhotos += photosEntretien[isPresent].photos.length;
      }
    }
  });

  return numberOfPhotos;
}

export function getAllPhotosFromActiveStateByCategory(
  SousCategorie,
  photosEntretien,
  stateEntretien,
  categorieID
) {
  //Tableau qui contiendras toutes nos photos
  var photos = [];

  //On boucle sur les entretiens pour extraire les photos
  SousCategorie.forEach((SC) => {
    //Si la SC fait partie de la categorie et le type de la sous categorie est le même que celui de l'entretien
    if (
      SC.idCategorie === categorieID &&
      stateEntretien[SC.idType] !== undefined
    ) {
      //Si l'entretien est checker
      if (stateEntretien[SC.idType].value) {
        //On additione ces photos
        photos = photos.concat(
          getAllPhotosFromTypeID(photosEntretien, SC.idType)
        );
      }
    }
  });

  return photos;
}

export function getNumberOfPhotoOfPanneID(panneID, allPhotos) {
  var numberOfPhotos = 0;

  allPhotos.forEach((picture) => {
    picture.isAnomalie && picture.idAnomalie === panneID && numberOfPhotos++;
  });

  return numberOfPhotos;
}

export function getNumberOfPhotoOfPanneEntretien(photos) {
  var numberOfPhotos = 0;

  photos.forEach((picture) => {
    numberOfPhotos += picture.photos.length;
  });

  return numberOfPhotos;
}

export function getAllPhotosFromPanneID(panneID, allPhotos) {
  //Initialisation d notre tableau qui va contenire nos photos
  var pictures = [];

  //Récupère le nombre total de photos qu enosu avons pour la panne
  const numberOfPhotos = getNumberOfPhotoOfPanneID(panneID, allPhotos);

  if (numberOfPhotos) {
    //Si nous avons des photos alors nous allons les mettres dans un tableau au format File()
    allPhotos.forEach((picture) => {
      if (picture.isAnomalie && picture.idAnomalie === panneID) {
        //Si la photo correspond on récupère les données et on l'ajoute au tableau
        pictures.push(picture);
      }
    });
  }

  return pictures;
}

export function getAllFacturesFromEntretienID(entretienID, allPhotos) {
  //Initialisation d notre tableau qui va contenire nos photos
  var pictures = [];

  //Si nous avons des photos alors nous allons les mettres dans un tableau au format File()
  allPhotos.forEach((picture) => {
    if (
      picture.isEntretien &&
      picture.isFacture &&
      picture.idEntretien === entretienID
    ) {
      //Si la photo correspond on récupère les données et on l'ajoute au tableau
      pictures.push(picture);
    }
  });

  return pictures;
}

export function getAllPhotosFromCategory(
  SousCategorie,
  photosEntretien,
  categorieID
) {
  var photos = [];
  SousCategorie.forEach((SC) => {
    const SC_type = SC.idType;
    const SC_categorie = SC.idCategorie;

    if (SC_categorie === categorieID) {
      //Si la SC fait partie de la categorie chosie
      //Alors on ajout la/les photos à notre tableau

      //On cherche d'abord s'il y à un entretien liéé à cette SC
      const isPresent = photosEntretien.findIndex(
        (element) => element.typeID === SC_type
      );

      if (isPresent !== -1) {
        //si il existe des photos pour cette SC, on additione le tout
        photos = photos.concat(photosEntretien[isPresent].photos);
      }
    }
  });
  return photos;
}
export function deletePhotoFromEntretienPhoto(photosEntretien, photo, typeID) {
  //On copie le trableau par soucis d'immuabilité
  var _photosEntretien = Object.assign([], photosEntretien);

  const findEntretien = _photosEntretien.findIndex(
    (element) => element.typeID === typeID
  );
  if (findEntretien !== -1) {
    //Entretien existant => on supprime la photo voulue
    const _photos = _photosEntretien[findEntretien];

    //Cherche l'index de la photo à supprimer
    const indexPhoto = _photos.photos.findIndex((_photo) => _photo === photo);

    if (indexPhoto !== -1) {
      //Si la photo à été trouvé => on la supprime
      _photos.photos.splice(indexPhoto, 1);
      if (_photos.photos.length === 0) {
        //Si nous n'avons plus de photos dans l'entretien concerner nous supprimons la ligne

        _photosEntretien.splice(findEntretien, 1);
      }
      return _photosEntretien;
    }

    //Sinon on en suppirme rien du tout
    return _photosEntretien;
  }
  //Entretien inexistant => on supprime rien du tout

  return _photosEntretien;
}

export function deleteFileFromArray(ListOfFiles, File) {
  const localListOfFiles = Object.assign([], ListOfFiles);
  const indexOfFile = localListOfFiles.findIndex((_File) => _File === File);

  indexOfFile !== -1 && localListOfFiles.splice(indexOfFile, 1);

  return localListOfFiles;
}

export function isEntretienChecked(allStateEntretien, idSousCategorie) {
  if (allStateEntretien[idSousCategorie] === undefined) {
    return false;
  }
  return allStateEntretien[idSousCategorie].value;
}

export function deleteAllPhotosFromStateEntretien(stateEntretien) {
  var newStateEntretien = [];

  stateEntretien.forEach((entretien) => {
    entretien.photos = [];
    newStateEntretien.push(entretien);
  });

  return newStateEntretien;
}

export function getCategorieByCategorieID(categories, categorieID) {
  const categorieObject = categories.find(
    (cat) => cat.idCategorie === categorieID
  );

  return categorieObject;
}

export function getAllTypeVisibleInCourant(Categorie, SousCategorie) {
  var categorieAcceptInCourant = [];
  var sousCategorieAcceptInCourant = [];

  Categorie.forEach(
    (CAT) => CAT.canShowInCourant && categorieAcceptInCourant.push(CAT)
  );

  SousCategorie.forEach(
    (SC) =>
      categorieAcceptInCourant.some(
        (CAT) => CAT.idCategorie === SC.idCategorie
      ) && sousCategorieAcceptInCourant.push(SC)
  );

  return sousCategorieAcceptInCourant;
}

export function getAllTypeVisibleInProgrammer(Categorie, SousCategorie) {
  var categorieAcceptInProgrammer = [];
  var sousCategorieAcceptInProgrammer = [];

  Categorie.forEach(
    (CAT) => CAT.canShowInProgrammer && categorieAcceptInProgrammer.push(CAT)
  );

  SousCategorie.forEach(
    (SC) =>
      categorieAcceptInProgrammer.some(
        (CAT) => CAT.idCategorie === SC.idCategorie
      ) && sousCategorieAcceptInProgrammer.push(SC)
  );

  return sousCategorieAcceptInProgrammer;
}

export function formatAlertTomModify(
  alertToModify,
  responsables,
  sousCategories,
  machines
) {
  const arrayOfResponsablesID = alertToModify?.idResponsable?.split(",") || [];

  const formattedResponsables = responsables.filter((responsable) =>
    arrayOfResponsablesID.includes(responsable.idResponsable.toString())
  );

  const formattedMachine = machines.find(
    (machine) => machine.idMachine === alertToModify.idMachine
  );

  const formattedSousCategorie = sousCategories.find(
    (SC) => SC.idType === alertToModify.idAlertType
  );

  return {
    ...alertToModify,
    idResponsable: formattedResponsables,
    idMachine: formattedMachine,
    idAlertType: formattedSousCategorie,
  };
}

export function formatPanneToModify(panneToModify) {
  return panneToModify
    ? { ...panneToModify, title: { title: panneToModify.title } }
    : {};
}

function getMIMEtypeForExtension(extension) {
  switch (extension) {
    case "jpg":
      return "image/jpeg";
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    default:
      return "";
  }
}
