import { FormattedProfile, Profile, UserRole } from "../types/User.ts";

export const formatProfiles = (profiles: Array<Profile>): FormattedProfile => {
  let finalProfile: FormattedProfile = {
    views: [],
  };
  const profilesFiltered = (profiles || []).filter((el) => el);

  if (profilesFiltered.length) {
    const reducer = (
      previousValue: FormattedProfile,
      currentValue: FormattedProfile
    ): FormattedProfile => {
      const allViews = previousValue.views.concat(currentValue.views);

      const allViewsFiltered = allViews.filter((view) => {
        //Nous regardons si nous avons notre vue est en double
        //et nous retournons notre vue que si elle à un role suppérieur
        //Both > Admin > User
        let viewOccurence = 0;
        allViews.forEach((occ_view) => {
          if (occ_view.id === view.id) {
            viewOccurence++;
          }
        });

        if (viewOccurence > 1) {
          //Nous regardons si nous avons déjà ou non la view dans notre tableau de sortie
          if (
            !previousValue.views.some((added_view) => added_view.id === view.id)
          ) {
            //il n'y ai pas, nous renvoyons notre vue
            return true;
          }

          //Nous avons plus d'une fois l'écran de dispo,
          //Nous gardons le plus haut en terme de role
          const isHigherRole = allViews.some(
            (occ_view) =>
              occ_view.id === view.id &&
              UserRole[view.roles[0].role] > UserRole[occ_view.roles[0].role]
          );

          if (isHigherRole) {
            return true;
          }
          return false;
        }

        //Une seule occurence
        return true;
      });

      return { views: allViewsFiltered };
    };

    const profilesFormatted: Array<FormattedProfile> = profilesFiltered.map(
      (pf) => {
        return {
          views: pf.views.map((view) => {
            return {
              ...view,
              higherRole: pf.role,
              roles: [
                {
                  role: pf.role,
                  group: pf.group,
                  groupName: pf.name,
                },
              ],
            };
          }),
        };
      }
    );

    const reducedProfiles = profilesFormatted.reduce(reducer);

    const mergedProfiles = reducedProfiles.views
      .map((profile, profileIndex, originalArray) => {
        const allProfile = reducedProfiles.views.filter(
          (el) => el.id === profile.id
        );

        if (
          !allProfile ||
          originalArray.findIndex((oe) => oe.id === profile.id) !== profileIndex
        )
          return null;

        if (allProfile.length > 1) {
          const highestRole =
            allProfile
              .map((_profile) =>
                UserRole[_profile.higherRole] > UserRole[profile.higherRole]
                  ? _profile.higherRole
                  : null
              )
              .filter((el) => el)[0] || profile.higherRole;

          //We merged profile
          return {
            id: profile.id,
            application_id: profile.application_id,
            name: profile.name,
            path: profile.path,
            higherRole: highestRole,
            roles: allProfile.map((profile) => profile.roles[0]),
          };
        }

        return profile;
      })
      .filter((el) => el);

    return { views: mergedProfiles };
  }

  return finalProfile;
};
