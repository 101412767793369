// Fichier de reducers Redux qui gère la partie entretien

/* import * as Actions from "../actions/NewEntretienActions";
import * as ModifActions from "../actions/ModifEntretienActions"; */

import * as Actions from "../actions/EntretienProgrammerActions";
import * as LoginFormActions from "../actions/loginFormActions";

export default function EntretienProgrammerReducer(
  state = {
    typeEntretien: "",
    machine: "",
    date: "",
    listResponsable: "",
    description: "",
    commentaire: "",
    aFaire: "",
    rappel: "",
    faitA: "",
    renouveler: false,
    effectuer: false,
    InProgress: false,
    modifyIdAlert: "",
    isFinish: false
  },
  action
) {
  switch (action.type) {
    case Actions.SET_TYPE_NEW_ENTRETIEN: {
      return {
        ...state,
        typeEntretien: action.typeEntretien
      };
    }
    case Actions.SET_MACHINE_NEW_ENTRETIEN: {
      return {
        ...state,
        machine: action.machine
      };
    }
    case Actions.SET_DATE_CREATION_NEW_ENTRETIEN: {
      return {
        ...state,
        date: action.date
      };
    }
    case Actions.SET_RESPONSABLE_NEW_ENTRETIEN: {
      return {
        ...state,
        listResponsable: action.listResponsable
      };
    }
    case Actions.SET_DESCRIPTION: {
      return {
        ...state,
        description: action.description
      };
    }
    case Actions.SET_COMMENTAIRE: {
      return {
        ...state,
        commentaire: action.commentaire
      };
    }
    case Actions.SET_A_FAIRE: {
      return {
        ...state,
        aFaire: action.aFaire
      };
    }
    case Actions.SET_RAPPEL: {
      return {
        ...state,
        rappel: action.rappel
      };
    }
    case Actions.SET_FAIT_A: {
      return {
        ...state,
        faitA: action.faitA
      };
    }
    case Actions.SET_RENOUVELER: {
      return {
        ...state,
        renouveler: action.renouveler
      };
    }
    case Actions.SET_EFFECTUER: {
      return {
        ...state,
        effectuer: action.effectuer
      };
    }
    case Actions.SET_INPROGRESS: {
      return {
        ...state,
        InProgress: action.InProgress
      };
    }
    case Actions.SET_MODIFY_ID_ALERT: {
      return {
        ...state,
        modifyIdAlert: action.idAlert
      };
    }
    case Actions.SET_IS_FINISH: {
      return {
        ...state,
        isFinish: action.isFinish
      };
    }
    case Actions.ADD_ENTRETIEN.Request: {
      return {
        ...state,
        InProgress: true
      };
    }
    case Actions.MODIFY_ENTRETIEN.Request: {
      return {
        ...state,
        InProgress: true
      };
    }
    case Actions.MODIFY_ENTRETIEN.Success: {
      return {
        ...state,
        typeEntretien: "",
        machine: "",
        date: "",
        listResponsable: "",
        description: "",
        commentaire: "",
        aFaire: "",
        rappel: "",
        faitA: "",
        renouveler: "",
        effectuer: "",
        InProgress: false,
        modifyIdAlert: "",
        isFinish: true
      };
    }

    case Actions.ADD_ENTRETIEN.Success: {
      return {
        ...state,
        typeEntretien: "",
        machine: "",
        date: "",
        listResponsable: "",
        description: "",
        commentaire: "",
        aFaire: "",
        rappel: "",
        faitA: "",
        renouveler: "",
        effectuer: "",
        InProgress: false,
        modifyIdAlert: "",
        isFinish: true
      };
    }
    case Actions.ADD_ENTRETIEN.Failure: {
      return {
        ...state,
        InProgress: false
      };
    }
    case Actions.MODIFY_ENTRETIEN.Failure: {
      return {
        ...state,
        InProgress: false
      };
    }
    case Actions.RESET_ENTRETIEN: {
      return {
        ...state,
        typeEntretien: "",
        machine: "",
        date: "",
        listResponsable: "",
        description: "",
        commentaire: "",
        aFaire: "",
        rappel: "",
        faitA: "",
        renouveler: "",
        effectuer: "",
        InProgress: false,
        modifyIdAlert: "",
        isFinish: false
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        typeEntretien: "",
        machine: "",
        date: "",
        listResponsable: "",
        description: "",
        commentaire: "",
        aFaire: "",
        rappel: "",
        faitA: "",
        renouveler: "",
        effectuer: "",
        InProgress: false,
        modifyIdAlert: "",
        isFinish: false
      };
    }

    default: {
      return state;
    }
  }
}
