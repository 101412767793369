type geofarm_token = string;

type geofarm_company = {
	id: number;
	logo: null;
	name: string;
	parameters: any;
};

type geofarm_user = {
	appId: number;
	companyId: number;
	company: geofarm_company;
	email: string;
	firstName: string;
	id: number;
	isAdmin: boolean;
	lastName: string;
	parameter: {
		role: string;
	};
	partnerId: number;
	partnerName: string;
	username: string;
};

export interface user {
	appId: null | number;
	expirationDate: string;
	token: geofarm_token;
	user: geofarm_user;
	userId: number;
}

export type SECTOR_GROUP = "Machine" | "Terrain" | "Transport" | "Usine";
export type USER_ROLE = "Administrateur" | "Utilisateur" | "Both";
export enum UserRole {
	Utilisateur = 1,
	Administrateur,
	Both,
}

export type Views = {
	id: number;
	application_id: number;
	name: string;
	path: string;
	app_id?: number;
};

export type Profile = {
	id: number;
	name: string;
	group: SECTOR_GROUP;
	role: USER_ROLE;
	views: Array<Views>;
};

export type FormattedProfile = {
	views: Array<
		Views & {
			higherRole: USER_ROLE;
			roles: Array<{
				role: USER_ROLE;
				group: SECTOR_GROUP;
				groupName: string;
			}>;
		}
	>;
};
