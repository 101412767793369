// Fichier de reducers Redux qui gère les états des variables de connexion

import * as Actions from "../actions/loginFormActions";

export default function loginFormReducer(
  state = {
    username: localStorage.getItem("username") || "",
    password: localStorage.getItem("password") || "",
    tenant: localStorage.getItem("tenant") || "",
    saveSession: localStorage.getItem("saveSession") || false,
    privilege: "",
    idTenant: "",
    token: "",
    isLoginInProgress: false,
    isLoggedIn: false,
    error: false,
    language: localStorage.getItem("language") || "en",
    languageFile: null,
    company: null,
    user: null,
  },
  action
) {
  switch (action.type) {
    case Actions.SET_USER_ID: {
      return { ...state, username: action.username };
    }
    case Actions.SET_USER_PASSWORD: {
      return { ...state, password: action.password };
    }
    case Actions.SET_USER_TENANT: {
      return { ...state, tenant: action.tenant };
    }
    case Actions.SET_SAVESESSION: {
      return { ...state, saveSession: action.saveSession };
    }
    case Actions.SET_LANGUAGE: {
      return {
        ...state,
        language: action.language,
        languageFile: action.languageFile,
      };
    }
    case Actions.LOGIN.Request: {
      return { ...state, isLoginInProgress: true };
    }
    case Actions.LOGIN.Failure: {
      return { ...state, isLoginInProgress: false, error: true };
    }
    case Actions.LOGIN.Success: {
      return {
        ...state,
        idTenant: action.idTenant,
        token: action.token,
        privilege: action.privilege,
        company: action.company,
        isLoginInProgress: false,
        isLoggedIn: true,
        error: false,
        user: action.user || null,
      };
    }
    case Actions.LOGOUT: {
      return {
        ...state,
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        tenant: localStorage.getItem("tenant"),
        saveSession: localStorage.getItem("saveSession"),
        privilege: "",
        idTenant: "",
        token: "",
        isLoginInProgress: false,
        isLoggedIn: false,
        error: false,
        company: null,
      };
    }
    default: {
      return state;
    }
  }
}
