// Fichier de reducers Redux qui gère la partie entretien
import * as LoginFormActions from "../actions/loginFormActions";
import * as Actions from "../actions/EntretienCourantAction";

export default function EntretieCourantReducer(
  state = {
    idMachine: null,
    tpsMoteur: null,
    dateCreation: "",
    idResponsable: null,
    actifTab: 1,
    stateEntretien: {},
    photosFacture: [],
    photosEntretien: [],
    commentaire: [],
    typeEntretien: [],
    stateCommentaire: [],
    isLoading: false,
    isFinish: false
  },
  action
) {
  switch (action.type) {
    case Actions.SET_ID_MACHINE_COURANT: {
      return {
        ...state,
        idMachine: action.idMachine
      };
    }
    case Actions.SET_TPS_MOTEUR_COURANT: {
      return {
        ...state,
        tpsMoteur: action.tpsMoteur
      };
    }
    case Actions.SET_DATE_CREATION_COURANT: {
      return {
        ...state,
        dateCreation: action.dateCreation
      };
    }
    case Actions.SET_STATE_ENTRETIEN: {
      return {
        ...state,
        stateEntretien: {
          ...state.stateEntretien,
          [action.stateEntretien.typeID]: {
            ...state.stateEntretien[action.stateEntretien.typeID],
            ...action.stateEntretien
          }
        }
      };
    }
    case Actions.REMOVE_STATE_ENTRETIEN: {
      const allCurrentEntretien = {... state.stateEntretien};
      delete allCurrentEntretien[action.typeID]
      return {
        ...state,
        stateEntretien: allCurrentEntretien
      }
    }
    case Actions.SET_COMMENTAIRE_COURANT: {
      return {
        ...state,
        commentaire: action.commentaire
      };
    }
    case Actions.SET_TYPE_ENTRETIEN_COURANT: {
      return {
        ...state,
        typeEntretien: action.typeEntretien
      };
    }
    case Actions.SET_ID_RESPONSABLE_COURANT: {
      return {
        ...state,
        idResponsable: action.idResponsable
      };
    }
    case Actions.SET_COMMENTAIRE_COLLAPSIBLE: {
      return {
        ...state,
        stateCommentaire: action.stateCommentaire
      };
    }
    case Actions.SET_ACTIF_TAB: {
      return {
        ...state,
        actifTab: action.actifTab
      };
    }
    case Actions.SET_IS_FINISH: {
      return {
        ...state,
        isFinish: action.isFinish
      };
    }
    case Actions.SET_PHOTOS_FACTURE: {
      return {
        ...state,
        photosFacture: action.photosFacture
      };
    }
    case Actions.ADD_PHOTOS_CURRENT: {
      return {
        ...state,
        photosEntretien: state.photosEntretien.concat(action.files || [])
      }
    }
    case Actions.REMOVE_PHOTO_CURRENT: {
      return {
        ...state,
        photosEntretien: state.photosEntretien.filter(picture => picture.url !== action.file.url)
      }
    }
    case Actions.SET_PHOTOS_ENTRETIEN: {
      return {
        ...state,
        photosEntretien: action.photosEntretien
      };
    }
    case Actions.UPLOAD_FACTURES.Request: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.UPLOAD_FACTURES.Success: {
      return {
        ...state,
        isLoading: false,
        isFinish: true,
        photosFacture: []
      };
    }
    case Actions.UPLOAD_FACTURES.Failure: {
      return {
        ...state,
        isLoading: false,
        isFinish: true,
        photosFacture: []
      };
    }
    case Actions.NEW_ENTRETIEN_COURANT.Request: {
      return {
        ...state,
        isLoading: true
      };
    }

    case Actions.NEW_ENTRETIEN_COURANT.Failure: {
      return {
        ...state,
        isLoading: false,
        photosFacture: [],
        photosEntretien: []
      };
    }
    case Actions.NEW_ENTRETIEN_COURANT.Success: {
      return {
        ...state,
        idMachine: "",
        tpsMoteur: "",
        dateCreation: "",
        idResponsable: "",
        commentaire: [],
        typeEntretien: [],
        stateEntretien: {},
        photosFacture: [],
        photosEntretien: [],
        stateCommentaire: [],
        isLoading: false
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        idMachine: null,
        tpsMoteur: null,
        dateCreation: "",
        idResponsable: null,
        actifTab: 1,
        stateEntretien: {},
        photosFacture: [],
        photosEntretien: [],
        commentaire: [],
        typeEntretien: [],
        stateCommentaire: [],
        isLoading: false,
        isFinish: false
      };
    }

    default: {
      return state;
    }
  }
}
