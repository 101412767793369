import { TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienDescription } from "../../../../../actions/EntretienProgrammerActions";

export type DescriptionProps = {
  defaultValue?: string;
};

function Description(props: DescriptionProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <Controller
      name="description"
      control={control}
      defaultValue={props.defaultValue || ""}
      as={
        <TextField
          fullWidth
          multiline
          placeholder={t("WORD_Description")}
          variant="outlined"
          error={!!errors.description}
          helperText={errors.description?.message || ""}
        />
      }
      onChange={([event]) => {
        //Dispatch de la nouvelle valeur
        dispatch(setNewEntretienDescription(event.target.value));

        return event.target.value;
      }}
    />
  );
}

export default Description;
