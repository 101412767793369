import { serveurRequest } from "../api/socket.js";
import requestType from "../utils/requestType";
import { getCompteurHeure } from "./DataActions.js";

export const GET_MACHINES = requestType("GET_MACHINES");
export const getMachine = (token, idTenant) => async (dispatch, getState) => {
  dispatch({ type: GET_MACHINES.Request });

  serveurRequest("GET:device/qtrepare", {}).then(
    (rep) => {
      const machine = rep
        .map((equipment) => {
          //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
          return {
            idMachine: equipment.equipment_association?.equipment_id,
            heureDebut: 0,
            idBoitier: equipment.serial_number,
            idTenant: equipment.company_id,
            nomMachine: equipment.friendly_name,
            geofarmData: equipment,
          };
        })
        .filter(
          (equipment) =>
            equipment.geofarmData.equipment_association &&
            equipment.geofarmData.device_type_id !== 5
        )
        .sort((a, b) => {
          if (a.nomMachine === b.nomMachine) {
            return 0;
          } else if (a.nomMachine > b.nomMachine) {
            return 1;
          } else {
            return -1;
          }
        });
      dispatch({ type: GET_MACHINES.Success, machine });

      //NOTE - Récupération des copmpteur horaires
      dispatch(getCompteurHeure());
    },
    (rej) => {
      dispatch({ type: GET_MACHINES.Failure });
      return alert("Echec lors de la récupération des machines");
    }
  );
};

export const SET_HEURE_DEBUT = "SET_HEURE_DEBUT";
export const setHeureDebut =
  (idMac, heureDeb) => async (dispatch, getState) => {
    const state = getState();
    var newListMachines = [];
    //je récupère la liste du store puis je met à jour le compteur horaire de la machine concerné
    state.machine.listMachine.forEach((machine) => {
      if (machine.idMachine === idMac) {
        machine.heureDebut = heureDeb;
        newListMachines.push(machine);
      } else {
        newListMachines.push(machine);
      }
    });
    dispatch({ type: SET_HEURE_DEBUT, newListMachines });
  };
