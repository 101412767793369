// Actions liées à la page d'accueil

export const SET_DER_MAJ = "SET_DER_MAJ";
export const setDerMAJ = derMAJ => async dispatch => {
  //TODO: Remettre l'heure en heure locale et pas UTC
  //TODO: Centrer l'heure sur l'écran
  dispatch({ type: SET_DER_MAJ, derMAJ });
};

export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const setPageName = Pname => async dispatch => {
  dispatch({ type: SET_PAGE_NAME, Pname });
};

export const SET_VERIFICATION = "SET_VERIFICATION";
export const setVerification = verification => async dispatch => {
  dispatch({ type: SET_VERIFICATION, verification });
};

export const SET_VERIFICATION_FINISH = "SET_VERIFICATION_FINISH";
export const setVerificationFinish = verificationFinish => async dispatch => {
  dispatch({ type: SET_VERIFICATION_FINISH, verificationFinish });
};
