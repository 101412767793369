/**
 * Classe principale qui gère la partie entretien
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { generateReport as reportActions } from "../actions/ReportActions";
import CustomToolbar from "./CustomToolbar";
import ShowType from "../utils/modalEntretien";
import { Chip, Button, ButtonGroup } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import DeleteButton from "../utils/deleteButton";
import FullScreenDialogAddPicture from "../hook/useFullScreenDialogAddFacture";

class AppHistoriqueCourant extends Component {
  buildRow = (row, data, mach) => {
    var arrayRow = [];
    var machineName;
    //On ajoute l'ID de la maintenance
    arrayRow.push(row.IDstat);

    //On ajoute le nom de la machine
    this.props.listMachine.length !== 0 &&
      this.props.listMachine.map(machine => {
        if (machine.idMachine === row.idMachine) {
          machineName = machine.nomMachine;
          arrayRow.push(machineName);
        }
        return null;
      });

    //On ajoute le temps moteur
    arrayRow.push(row.tpsMoteur);

    //On ajoute le nom du responsable
    this.props.listResponsable.forEach(responsable => {
      responsable.idResponsable.toString() === row.idResponsable.toString() &&
        arrayRow.push(responsable.nom + " " + responsable.prenom);
    });

    //on ajoute la date où il à était effectué
    arrayRow.push(
      moment(row.date, "YYYY-MM-DDTHH:mm:ss.SSSZ")
        .utcOffset(2)
        .format("DD/MM/YYYY HH:mm")
    );

    //Ajoute les données liée aux entretiens
    this.props.listTypeCategorie.forEach(categorie => {
      var categorieData = [];
      if (categorie.canShowInCourant) {
        row.idType[0].forEach(entretien => {
          this.props.listType.forEach(entretienType => {
            if (
              entretien[entretienType.idType] !== undefined &&
              entretienType.idCategorie === categorie.idCategorie
            ) {
              entretien[entretienType.idType].nomType = entretienType.nomType;
              categorieData.push({ ...entretien[entretienType.idType] });
            }
          });
        });

        arrayRow.push(categorieData);
      }
    });

    //On ajoute l'id de la stat et le nom de la machine pour l'option de supression de l'entretien
    arrayRow.push([row.IDstat, machineName, row.idMachine]);

    //On ajoute le tout dans nos data
    data.push(arrayRow);
  };

  render() {
    var columns = [
      {
        name: "idAlert",
        options: { filter: false, sort: false, display: "excluded" }
      },
      { name: "Machine", options: { filter: true, sort: true } },
      { name: "Temps Moteur", options: { filter: false, sort: true } },
      { name: "Responsable", options: { filter: true, sort: true } },
      { name: "Effectuer le", options: { filter: true, sort: true } }
    ];
    this.props.listTypeCategorie.forEach(cat => {
      if (cat.canShowInCourant) {
        columns.push({
          name: cat.nomCategorie,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return value.map((attr, index) => {
                if (attr !== undefined) {
                  if (
                    attr.commentaire !== undefined &&
                    attr.commentaire.length > 0
                  ) {
                    return (
                      <ShowType
                        nomType={attr.nomType}
                        commentaire={attr.commentaire}
                        key={index}
                      />
                    );
                  }
                  return (
                    <Chip
                      label={attr.nomType}
                      key={index}
                      style={{ margin: "1%" }}
                    />
                  );
                }
                return null;
              });
            }
          }
        });
      }
    });
    columns.push({
      name: "Option",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup orientation="vertical">
              {this.props.privileges.canEditPhoto && (
                <FullScreenDialogAddPicture
                  machineID={value[2]}
                  entretienID={value[0]}
                  tenantID={this.props.tenant}
                />
              )}
              <Button>
                <DeleteButton
                  token={this.props.token}
                  tenant={this.props.tenant}
                  idAlert={value[0]}
                  machineName={value[1]}
                  entretienType={1}
                  dispatch={this.props.dispatch}
                />
              </Button>
            </ButtonGroup>
          );
        }
      }
    });
    const options = {
      filter: true,
      filterType: "dropdown",
      selectableRows: "none",
      responsive: "scroll",
      rowsPerPage: 10,
      customToolbar: () => {
        return (
          <CustomToolbar tenant={this.props.tenant} token={this.props.token} />
        );
      },
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: "Aucun résultat trouvé",
          toolTip: "Trier"
        },
        pagination: {
          next: "Page Suivante",
          previous: "Page Précedente",
          rowsPerPage: "Ligne par page:",
          displayRows: "sur"
        },
        toolbar: {
          search: "Recherche",
          downloadCsv: "Télecharger",
          print: "Imprimer",
          viewColumns: "Afficher/Cacher Colonnes",
          filterTable: "Filtre"
        },
        filter: {
          title: "FILTRES",
          reset: "RESET"
        },
        viewColumns: {
          title: "Afficher Colonnes",
          titleAria: "Afficher/Cacher des Colonnes"
        },
        selectedRows: {
          text: "ligne(s) sélectionnées",
          delete: "Supprimer",
          deleteAria: "Supprimer les lignes sélectionnées"
        }
      }
    };
    var data = [];
    var entretienToShow = this.props.TypeMaintenanceFilter;

    this.props.listAlerteCourantes.length !== 0 &&
      this.props.listAlerteCourantes.forEach(alert => {
        //eslint-disable-next-line array-callback-return
        var mach = this.props.listMachine.findIndex(elem => {
          if (elem.idMachine === alert.idMachine) {
            return elem;
          }
        });
        (entretienToShow.toString() === "-1" ||
          (entretienToShow.toString() !== "-1" &&
            alert.idType[0][0][entretienToShow] !== undefined)) &&
          moment(alert.date, "YYYY-MM-DDTHH:mm:ss.SSSZ").isBetween(
            moment(this.props.beginDate, "YYYY-MM-DD"),
            moment(this.props.endDate, "YYYY-MM-DD"),
            "day",
            "[]"
          ) &&
          this.buildRow(alert, data, mach);
      });
    return (
      <MUIDataTable
        title={"Entretiens courants"}
        data={data}
        columns={columns}
        options={options}
      />
    );
  }
  //Fonction pour lancer la génération d'un rapport
  onClick = async e => {
    this.props.dispatch(
      reportActions.generateReport({
        idTenant: this.props.tenant,
        token: this.props.token
      })
    );
  };
}

export default connect(state => ({
  token: state.loginForm.token,
  tenant: state.loginForm.idTenant,
  privileges: state.loginForm.privilege,
  listAlert: state.data.listAlerte,
  listAlerteCourantes: state.data.listAlerteCourantes,
  listTypeCategorie: state.data.listTypeCategorie,
  listType: state.data.listType,
  listCompteurHeure: state.data.listCompteurHeure,
  listResponsable: state.responsable.listResponsable,
  listMachine: state.machine.listMachine,
  pageNom: state.menu.pageName
}))(AppHistoriqueCourant);
