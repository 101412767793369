import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BuildIcon from "@material-ui/icons/Build";
import moment from "moment";
import Constants from "../Constants.json";
import downloadPicture from "../api/File";

import { IconButton, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { getCompleteURLFromPictureObject } from "../utils/GEDutils.js";

//Composant servant à afficher le simages en plein écran
export default function ImageLightBox(props) {
  const {
    picture,
    isOpen,
    closeRequest,
    Machines,
    SCategories,
    PannesList,
    isNotGED,
  } = props;

  ///Création d'un état
  const [redirect, setRedirect] = useState(false);

  return redirect ? (
    <Redirect
      to={{
        pathname: "/AppEntretienPanne",
        state: {
          panneToModify: PannesList.find(
            (Panne) => Panne.idPanne === picture.idAnomalie
          ),
        },
      }}
    />
  ) : isOpen ? (
    <Lightbox
      mainSrc={isNotGED ? picture.URL : picture.pathName}
      onCloseRequest={(e) => closeRequest(e)}
      imageTitle={
        <Typography variant="overline">
          {`Photo ${isNotGED ? "prise" : "importé"} le : ${moment(
            picture.uploadDate
          ).format("YYYY/MM/DD à HH:mm:ss")}`}
        </Typography>
      }
      imageCaption={
        <Typography>
          {!isNotGED &&
            convertPictureDataToText(picture, SCategories, Machines)}
        </Typography>
      }
      toolbarButtons={[
        !isNotGED && (
          <IconButton onClick={(e) => downloadPicture(picture)}>
            <CloudDownloadIcon style={{ color: "white" }} />
          </IconButton>
        ),
        !isNotGED && picture.isAnomalie ? (
          <IconButton
            onClick={(e) => setRedirect(true)}
            disabled={!picture.isAnomalie}
          >
            <BuildIcon style={{ color: "white" }} />
          </IconButton>
        ) : null,
      ]}
    />
  ) : null;
}

function convertPictureDataToText(picture, SCAT, Machines) {
  const pictureSCAT = SCAT.find((_SCAT) => _SCAT.idType === picture.idType);
  const machineObject = Machines.find(
    (machine) => machine.idMachine === picture.IdMachine
  );

  var pictureText = `Cette photo concerne ${
    picture.isAnomalie
      ? "une anomalie"
      : `un entretien courant ${
          !picture.isFacture
            ? pictureSCAT !== undefined
              ? pictureSCAT.nomType
              : "Erreur"
            : ""
        }`
  }. ${
    !picture.isAnomalie
      ? picture.isFacture
        ? "Il s'agit d'une facture."
        : ""
      : ""
  } La machine liée est : ${
    machineObject !== undefined ? machineObject.nomMachine : "Erreur"
  }`;

  return pictureText;
}
