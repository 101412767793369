import React from "react";
import { IconButton, Menu, MenuItem, Icon } from "@material-ui/core";
import FrenchFlag from "../../pictures/flags/france.svg";
import EnglishFlag from "../../pictures/flags/united-kingdom.svg";
import TurkishFlag from "../../pictures/flags/turkish.svg";
import RomaniaFlag from "../../pictures/flags/romania.svg";
import HungaryFlag from "../../pictures/flags/hungary.svg";
import UkraineFlag from "../../pictures/flags/ukraine.svg";
import { useTranslation } from "react-i18next";
import { setDefaultLocale } from "../../utils/dateFnsWithLocale.ts";
import { useDispatch } from "react-redux";

import { setLanguage } from "../../actions/loginFormActions";

function LangueChoice(props) {
  const { language } = props;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { i18n } = useTranslation();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(e, Choosenlanguage) {
    setAnchorEl(null);
    if (Choosenlanguage && Choosenlanguage !== language) {
      e.preventDefault();
      handleChangeLanguage(Choosenlanguage);
    }
  }

  const handleChangeLanguage = (newLanguage) => {
    if (newLanguage) {
      i18n.changeLanguage(newLanguage);

      import(`date-fns/locale/${newLanguage}`).then((locale) => {
        setDefaultLocale(locale.default);
      });

      dispatch(setLanguage(newLanguage));
    }
  };

  React.useEffect(() => {
    if (language) {
      handleChangeLanguage(language);
    }
  }, []);

  const ActiveFlag = () => {
    switch (i18n.language) {
      case "fr":
        return (
          <img src={FrenchFlag} alt="french_flag" style={{ height: "120%" }} />
        );
      case "en":
        return (
          <img
            src={EnglishFlag}
            alt="english_flag"
            style={{ height: "120%" }}
          />
        );
      case "hu":
        return (
          <img
            src={HungaryFlag}
            alt="hungary_flag"
            style={{ height: "120%" }}
          />
        );
      case "ro":
        return (
          <img
            src={RomaniaFlag}
            alt="romania_flag"
            style={{ height: "120%" }}
          />
        );
      case "tr":
        return (
          <img
            src={TurkishFlag}
            alt="turkish_flag"
            style={{ height: "120%" }}
          />
        );
      case "uk":
        return (
          <img
            src={UkraineFlag}
            alt="ukraine_flag"
            style={{ height: "120%" }}
          />
        );
      default:
        return (
          <img
            src={EnglishFlag}
            alt="english_flag"
            style={{ height: "120%" }}
          />
        );
    }
  };
  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        disableRipple
        style={{
          backgroundColor: "#dadad9",
          padding: "2px",
          width: "35px",
        }}
        size="medium"
      >
        <Icon style={{ textAlign: "center", height: "100%", width: "100%" }}>
          <ActiveFlag />
        </Icon>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => handleClose(e)}
      >
        <MenuItem onClick={(e) => handleClose(e, "fr")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img src={FrenchFlag} alt="French" style={{ height: "100%" }} />
          </Icon>
          Français
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "en")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img src={EnglishFlag} alt="English" style={{ height: "100%" }} />
          </Icon>
          English
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "hu")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img
              src={HungaryFlag}
              alt="Hungary"
              style={{
                height: "100%",
              }}
            />
          </Icon>
          Hungary
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "ro")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img
              src={RomaniaFlag}
              alt="Romania"
              style={{
                height: "100%",
              }}
            />
          </Icon>
          Romania
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "tr")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img
              src={TurkishFlag}
              alt="Turkish"
              style={{
                height: "100%",
                width: "36px",
                transform: "translateX(-6px)",
              }}
            />
          </Icon>
          Turkish
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "uk")}>
          <Icon style={{ textAlign: "center", marginRight: "10%" }}>
            <img
              src={UkraineFlag}
              alt="Ukraine"
              style={{
                height: "100%",
              }}
            />
          </Icon>
          Ukraine
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LangueChoice;
