// Fichier Redux qui regroupe les reducers

import { combineReducers } from "redux";
import menu from "./MenuReducers";
import loginForm from "./loginFormReducer";
import machine from "./MachinesReducer";
import responsable from "./ResponsableReducer";
import data from "./DataReducer";
import entretienProgrammer from "./EntretienProgrammerReducer";
import entretienCourant from "./EntretienCourantReducer";
import entretienPanne from "./EntretienPanneReducer";
import historique from "./HistoriqueReducer";
import galerie from "./GalerieReducer";

export default combineReducers({
  menu,
  loginForm,
  machine,
  responsable,
  data,
  entretienProgrammer,
  entretienCourant,
  entretienPanne,
  historique,
  galerie
});
