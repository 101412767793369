import MUIDataTable from "mui-datatables";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux/es/hooks/useSelector";
import ModalDateChoice from "./ModalDateChoice.tsx";
import ModalDetails from "./ModalDetails.tsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { isAfter } from "date-fns";

type HistoryAllProps = {
  filters: Record<string, any>;
};

function HistoryAll(props: HistoryAllProps) {
  //   const dispatch = useDispatch();
  const { t } = useTranslation();

  // Récupération des données du store
  const {
    listAlertePanne,
    listAlerteCourantes,
    listCompteurHeure,
    listAlerte,
    // listTypeCategorie,
    // listType,
  } = useSelector((state: any) => state.data);
  const { listMachine } = useSelector((state: any) => state.machine);
  const { listResponsable } = useSelector((state: any) => state.responsable);
  const { TypeMaintenanceFilter } = useSelector(
    (state: any) => state.historique
  );
  //   const { privilege } = useSelector((state: any) => state.loginForm);

  const [allData, setAllData] = useState<any[]>([]);
  const [filtredData, setFiltredData] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<any[]>([]);

  // ---------- Gestion des filtres des dates ----------
  const [endDate, setEndDate] = React.useState(moment());
  const [beginDate, setBeginDate] = React.useState(
    moment().subtract(1, "year")
  );

  // construction du tableau de données
  useEffect(() => {
    let dataAlerte: any[] = [];
    let dataCourantes: any[] = [];
    let dataPannes: any[] = [];
    // Trie des alertes programmées
    if (
      props.filters.typeToShow === "All" ||
      props.filters.typeToShow === "Schedule"
    ) {
      dataAlerte = listAlerte
        ? listAlerte?.filter((el: any) => {
            const entretienFinished =
              props.filters.entretienFinishtoShow || el.etat !== 3;
            const entretienDate = moment(
              el.dateCreation,
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ).isBetween(
              moment(beginDate, "YYYY-MM-DD"),
              moment(endDate, "YYYY-MM-DD"),
              "day",
              "[]"
            );
            const entretienType = TypeMaintenanceFilter === "all";
            return entretienDate && entretienFinished && entretienType;
          })
        : [];
    }

    // Trie des alertes courantes
    if (
      props.filters.typeToShow === "All" ||
      props.filters.typeToShow === "Current"
    ) {
      dataCourantes = listAlerteCourantes
        ? listAlerteCourantes.filter((el: any) => {
            const entretienDate = moment(
              el.date,
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ).isBetween(
              moment(beginDate, "YYYY-MM-DD"),
              moment(endDate, "YYYY-MM-DD"),
              "day",
              "[]"
            );
            const entretienType =
              TypeMaintenanceFilter === "all" ||
              el.idType[0][0][TypeMaintenanceFilter];

            return entretienDate && entretienType;
          })
        : [];
    }

    // Trie des alertes pannes
    if (
      props.filters.typeToShow === "All" ||
      props.filters.typeToShow === "Failure"
    ) {
      dataPannes = listAlertePanne
        ? listAlertePanne.filter((el: any) => {
            const entretienFinished =
              props.filters.entretienFinishtoShow || !el.isDone;
            const entretienDate = moment(
              el.date,
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ).isBetween(
              moment(beginDate, "YYYY-MM-DD"),
              moment(endDate, "YYYY-MM-DD"),
              "day",
              "[]"
            );

            return entretienFinished && entretienDate;
          })
        : [];
    }

    // assemblage des données
    setFiltredData(
      [...dataAlerte, ...dataCourantes, ...dataPannes]
        .filter(
          (el: any) =>
            el.idMachine !== null &&
            (props?.filters?.machineToShow === -1 ||
              listMachine.find(
                (machine: any) => machine.idMachine === el.idMachine
              )?.idMachine === props.filters.machineToShow) &&
            (props?.filters?.managerToShow === -1 ||
              (typeof el.idResponsable === "string" &&
              el.idResponsable?.split(",")?.length !== listResponsable.length
                ? el.idResponsable.includes(
                    props.filters.managerToShow.toString()
                  )
                : listResponsable.find(
                    (responsable: any) =>
                      responsable.idResponsable === el.idResponsable
                  )?.idResponsable === props.filters.managerToShow))
        )
        .map((el: any) => {
          return {
            ...el,
            responsable: listResponsable.find(
              (responsable: any) =>
                responsable.idResponsable === el.idResponsable
            ),
            equipment: listMachine.find(
              (machine: any) => machine.idMachine === el.idMachine
            ),
            engineTime: listCompteurHeure.find(
              (compteur: any) => compteur.idMachine === el.idMachine
            ),
          };
        })
    );
  }, [
    listAlertePanne,
    listAlerteCourantes,
    listAlerte,
    props.filters.managerToShow,
    props.filters.machineToShow,
    props.filters.typeToShow,
    TypeMaintenanceFilter,
    beginDate,
    endDate,
  ]);

  // fonction de recherche
  const containsSearch = (val: any, search: string): boolean => {
    if (typeof val === "string") {
      return val.toLowerCase().includes(search);
    } else if (typeof val === "object" && val !== null) {
      return Object.values(val).some((val2) => containsSearch(val2, search));
    }
    return false;
  };

  // mise a jour des données par rapport a la recherche
  useEffect(() => {
    setSearchData(
      filtredData
        .filter((el) => {
          if (props.filters.search === "") return true;
          return Object.values(el).some((val) =>
            containsSearch(val, props.filters.search)
          );
        })
        .sort((a: any, b: any) => {
          let aDate = a.date || a.dateFin || a.dateCreation;
          let bDate = b.date || b.dateFin || b.dateCreation;
          return moment(aDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").isAfter(
            moment(bDate, "YYYY-MM-DDTHH:mm:ss.SSSZ")
          )
            ? -1
            : 1;
        })
    );
  }, [props.filters.search, filtredData]);

  // mise a jour des données du tableau
  useEffect(() => {
    const allDataArray: Array<any> = [];
    searchData.forEach((currentData: any) => {
      const currentDataArray: Array<any> = [];

      // Ajout de l'id
      currentDataArray.push(
        currentData.IDstat || currentData.idPanne || currentData.idAlert
      );

      // Ajout du nom de la machine
      currentDataArray.push(currentData.equipment.nomMachine);

      // Ajout du type d'entretien
      currentDataArray.push(
        currentData.idPanne
          ? `${t("WORD_Failure")}`
          : currentData.IDstat
          ? `${t("WORD_Current")}`
          : `${t("WORD_Schedule")}`
      );

      // Ajout de la date
      currentDataArray.push(
        currentData.date || currentData.dateFin || currentData.dateCreation
      );

      // Ajout Responsable
      currentDataArray.push(
        currentData.idAlert
          ? !currentData.idResponsable ||
            currentData.idResponsable?.split(",")?.length ===
              listResponsable.length
            ? t("WORD_All_People")
            : currentData.idResponsable
                .split(",")
                .map((respID: any, index: number) => {
                  const user = listResponsable.find(
                    (manager) =>
                      parseInt(manager.idResponsable) === parseInt(respID)
                  );

                  const formatedName = user
                    ? user.nom.slice(0, 1).toUpperCase() +
                      user.nom.slice(1).toLowerCase() +
                      " " +
                      user.prenom.slice(0, 1).toUpperCase() +
                      user.prenom.slice(1).toLowerCase()
                    : "";
                  return index !== 0 ? ", " + formatedName : formatedName;
                })
                .filter((el) => el)
          : (currentData.responsable?.nom &&
              currentData.responsable?.prenom &&
              currentData.responsable.nom.slice(0, 1).toUpperCase() +
                currentData.responsable.nom.slice(1).toLowerCase() +
                " " +
                currentData.responsable.prenom.slice(0, 1).toUpperCase() +
                currentData.responsable.prenom.slice(1).toLowerCase()) ||
              " "
      );

      // Ajout temps moteur
      currentDataArray.push(
        currentData?.tpsMoteur
          ? Math.round(currentData.tpsMoteur)
          : currentData?.heureActuel
          ? Math.round(currentData.heureActuel)
          : currentData?.engineTime
          ? Math.round(currentData.engineTime.tempsMoteur)
          : " "
      );

      // Ajout de la description
      currentDataArray.push(currentData.description || " ");

      // Ajout button modal de détail
      currentDataArray.push(currentData);

      allDataArray.push(currentDataArray); //.sort((a: any, b: any) => a.Date - b.Date)));
    });
    setAllData(allDataArray);
  }, [searchData]);

  const columns = useMemo(
    () => [
      {
        name: "ID",
        options: { filter: false, sort: false, display: "excluded" },
      },
      {
        name: t("EQUIPMENT_TYPE_Machine"),
        options: {
          filter: false,
          sort: true,
          customHeadLabelRender: () => {
            return <b>{t("EQUIPMENT_TYPE_Machine")}</b>;
          },
        },
      },
      {
        name: t("WORD_Servicing"),
        options: {
          filter: false,
          sort: true,
          customHeadLabelRender: () => {
            return <b>{t("WORD_Servicing")}</b>;
          },
        },
      },
      {
        name: t("WORD_Date"),
        options: {
          filter: false,
          sort: true,
          sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
              let aNom = obj1.data;
              let bNom = obj2.data;
              return (
                (isAfter(parseISO(aNom), parseISO(bNom)) ? 1 : -1) *
                (order === "asc" ? 1 : -1)
              );
            };
          },
          customHeadLabelRender: () => {
            return <b>{t("WORD_Date")}</b>;
          },
          customBodyRender: (value: any) => {
            return format(parseISO(value), "dd/MM/yyyy HH:mm");
          },
        },
      },
      {
        name: t("WORD_Manager"),
        options: {
          filter: false,
          sort: true,
          sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
              let comparison = -5;
              const isMultipleOrAll = (name: string) =>
                (Array.isArray(name) && name?.length > 1) ||
                name?.includes(",") ||
                name === t("WORD_All_People");

              let aNom = obj1.data;
              let bNom = obj2.data;

              // Check if aNom or bNom is multiple or all
              if (isMultipleOrAll(aNom) && isMultipleOrAll(bNom)) {
                comparison = 0;
              } else if (isMultipleOrAll(aNom)) {
                comparison = 1;
              } else if (isMultipleOrAll(bNom)) {
                comparison = -1;
              }
              if (Array.isArray(aNom) && aNom?.length < 2) {
                aNom = aNom[0];
              }

              if (Array.isArray(bNom) && bNom?.length < 2) {
                bNom = bNom[0];
              }

              // If not multiple or all, do alphabetical comparison
              comparison =
                comparison === -5 ? aNom.localeCompare(bNom) : comparison;
              return order === "asc" ? comparison : -comparison;
            };
          },
          customHeadLabelRender: () => {
            return <b>{t("WORD_Manager")}</b>;
          },
          customBodyRender: (value: any) => {
            return (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  minWidth: "200px",
                  width: "15vw",
                }}
              >
                {value}
              </div>
            );
          },
        },
      },
      {
        name: t("WORD_Engine_Time"),
        options: {
          filter: false,
          sort: true,
          sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
              let aNom = obj1.data;
              let bNom = obj2.data;
              return (aNom - bNom) * (order === "asc" ? 1 : -1);
            };
          },
          customHeadLabelRender: () => {
            return <b>{t("WORD_Engine_Time")}</b>;
          },
        },
      },
      {
        name: t("WORD_Description"),
        options: {
          filter: false,
          sort: true,
          customHeadLabelRender: () => {
            return <b>{t("WORD_Description")}</b>;
          },
        },
      },
      {
        name: t("WORD_Details"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <ModalDetails
                key={value.date || value.dateFin || value.dateCreation}
                value={value}
              />
            );
          },
          customHeadLabelRender: () => {
            return <b>{t("WORD_Details")}</b>;
          },
        },
      },
    ],
    []
  );

  const options = {
    filter: false,
    search: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    tableBodyHeight: "100%",
    textLabels: {
      body: {
        noMatch: t("DATATABLE_No_Data"),
        toolTip: t("WORD_Sort"),
      },
      pagination: {
        next: t("DATATABLE_Next_Page"),
        previous: t("DATATABLE_Previous_Page"),
        rowsPerPage: t("DATATABLE_Rows_Per_Page"),
        displayRows: t("DATATABLE_Display_Rows"),
      },
      toolbar: {
        search: t("DATATABLE_Search"),
        downloadCsv: t("DATATABLE_Download_CSV"),
        print: t("DATATABLE_Print"),
        viewColumns: t("DATATABLE_Columns"),
        filterTable: t("DATATABLE_Filters"),
      },
      filter: {
        title: t("DATATABLE_Filters").toUpperCase(),
        reset: "RESET",
      },
      viewColumns: {
        title: t("DATATABLE_View_Columns"),
        titleAria: t("DATATABLE_Columns"),
      },
      selectedRows: {
        text: t("DATATABLE_SelectedRows_Text"),
        delete: t("DATATABLE_SelectedRows_Delete"),
        deleteAria: t("DATATABLE_SelectedRows_DeleteRows"),
      },
    },
    customToolbar: () => {
      return (
        <React.Fragment>
          <ModalDateChoice
            setBeginDate={setBeginDate}
            beginDate={beginDate}
            setEndDate={setEndDate}
            endDate={endDate}
          />
        </React.Fragment>
      );
    },
  };

  return <MUIDataTable data={allData} columns={columns} options={options} />;
}

export default HistoryAll;
