import requestType from "../utils/requestType";
import { serveurRequest } from "../api/socket.js";

// Actions liées à la gestion des responsables

export const GET_RESPONSABLE = requestType("GET_RESPONSABLE");
export const getResponsable = (token, tenant) => async (dispatch) => {
  dispatch({ type: GET_RESPONSABLE.Request });
  serveurRequest("get:AllUsers", {
    profile_id: 13,
  }).then(
    (responsables) => {
      //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
      const formattedResponsable = responsables.map((user) => {
        return {
          idResponsable: user.id,
          idTenant: user.companyId,
          isDefault: false,
          mail: user.email,
          nom: user.firstName,
          prenom: user.lastName,
        };
      });

      const sortResponsables = formattedResponsable.sort((a, b) => {
        if (a.nom < b.nom) {
          return -1;
        }
        if (a.nom > b.nom) {
          return 1;
        }
        return 0;
      });
      //enregistre la liste des responsables dans le store
      dispatch({
        type: GET_RESPONSABLE.Success,
        responsables: sortResponsables,
      });
    },
    (rej) => {
      dispatch({ type: GET_RESPONSABLE.Failure });
      return alert(
        "Echec lors de la récupération de la liste des responsables"
      );
    }
  );
};

export const SET_SELECTED = "SET_SELECTED";
export const setSelected = (idSelect) => async (dispatch) => {
  dispatch({ type: SET_SELECTED, idSelect });
};

export const SET_NOM = "SET_NOM";
export const setNom = (nom) => async (dispatch) => {
  dispatch({ type: SET_NOM, nom });
};

export const SET_PRENOM = "SET_PRENOM";
export const setPrenom = (prenom) => async (dispatch) => {
  dispatch({ type: SET_PRENOM, prenom });
};

export const SET_MAIL = "SET_MAIL";
export const setMail = (mail) => async (dispatch) => {
  dispatch({ type: SET_MAIL, mail });
};
