import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Constants from "./Constants.json";

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: "fr",
    debug: true,
    cleanCode: true,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_HOST}/languages/{{lng}}`,
      customHeaders: {
        "Content-Type": "application/json",
      },
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b", "em"],
    },
  });

export default i18n;
