import requestType from "../utils/requestType";
import { serveurRequest } from "../api/socket.js";
import moment from "moment";

export const SET_GALERIE_FILTERS = "SET_GALERIE_FILTERS";
export const setGalerieFilters = (filters) => async (dispatch) => {
  dispatch({ type: SET_GALERIE_FILTERS, filters });
};

export const SET_SELECT_MODE = "SET_SELECT_MODE";
export const setSelectMode = (selectMode) => async (dispatch) => {
  dispatch({ type: SET_SELECT_MODE, selectMode });
};

export const SET_PICTURES_TO_DOWNLOAD = "SET_PICTURES_TO_DOWNLOAD";
export const setPicturesToDownload =
  (picturesToDownload) => async (dispatch) => {
    dispatch({ type: SET_PICTURES_TO_DOWNLOAD, picturesToDownload });
  };

export const ALL_PICTURES_WAS_DOWNLOADED = "ALL_PICTURES_WAS_DOWNLOADED";
export const allPictureWasDownloaded = () => async (dispatch) => {
  dispatch({ type: ALL_PICTURES_WAS_DOWNLOADED });
};

export const GET_PICTURES = requestType("GET_PICTURES");
export const getPictures = (token, idTenant) => async (dispatch) => {
  dispatch({ type: GET_PICTURES.Request });

  await serveurRequest("GET:pictures/qtrepare", {}).then(
    (res) => {
      //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
      const pictures = (res || []).map((picture) => {
        return {
          IdMachine: picture.equipment_id,
          idAnomalie: picture.failure_id,
          idEntretien: picture.current_maintenance_id,
          idPicture: picture.id,
          idType: picture.secondary_mechanical_id,
          isAnomalie: !!picture.failure_id,
          isEntretien: !!picture.current_maintenance_id,
          isFacture: picture.is_facture,
          name: picture.picture.name,
          pathName: picture.picture.path_link,
          pathThumbnail: picture.picture.path_thumbnail_link,
          uploadDate: picture.picture.date,
        };
      });

      pictures.sort((picture1, picture2) => {
        const uploadDate1 = moment(picture1.uploadDate);
        const uploadDate2 = moment(picture2.uploadDate);

        if (uploadDate1.isBefore(uploadDate2)) {
          return 1;
        } else if (uploadDate1.isAfter(uploadDate2)) {
          return -1;
        }

        return 0;
      });

      dispatch({ type: GET_PICTURES.Success, pictures });
    },
    (rej) => {
      new Error(
        "Erreur lors de la récupération des photos : " + JSON.stringify(rej)
      );
      dispatch({ type: GET_PICTURES.Failure });
      return alert("Erreur de récupération des photos");
    }
  );
};
