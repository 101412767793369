import { io } from "socket.io-client";
import SocketIOFileUpload from "socketio-file-upload";
import { socketsController } from "..";

//const ADDR = "http://192.168.50.88:5010";
const ADDR = "http://127.0.0.1:5010";
//const ADDR = "https://qtrepare.questinnov.com";

function serveurPromise(event, data) {
  //var socket = io(ADDR);
  return socketsController.fetchSocket(event, data);
  /*   return new Promise((resolve, reject) => {
    const dataWithToken = { ...data, "x-token": data.token };
    socket.emit(event, dataWithToken, (result) => {
      if (result.Error) {
        reject(result);
      }
      resolve(result);
    });
  }); */
}

async function serveurRequest(event, data = {}) {
  return await serveurPromise(event, data);
}

async function SendFileToServer(Data, File, progressCallback) {
  /*EXEMPLE DU FORMAT DE DATA 
  {
    entretienID: null,
    machineID: 172,
    isFacture: false,
    isEntretienPhoto: false,
    isAnomalie: true,
    anomalieID: 56,
    entretienTypeID: null
  }; */

  var uploader = new SocketIOFileUpload(socketsController.socket);

  uploader.addEventListener("start", (event) => {
    event.file.meta.fileData = Data;
  });

  uploader.addEventListener("progress", (event) => {
    const percent = (event.bytesLoaded / event.file.size) * 100;

    progressCallback(percent);
  });

  return new Promise((resolve, reject) => {
    uploader.addEventListener("complete", (event) => {
      progressCallback(0);

      resolve(true);
    });

    uploader.addEventListener("error", (event) => {
      progressCallback(0);
      console.error("Erreur : " + event.message);

      reject(new Error(event.message));
    });

    uploader.submitFiles(Array.isArray(File) ? File : [File]);
  });
}

export { serveurPromise, serveurRequest, SendFileToServer };
