import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as DataActions from "../actions/DataActions";
import * as Actions from "../actions/HistoriqueAction";
import * as MenuActions from "../actions/MenuActions.js";
import HistoryAll from "./History/All.tsx";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";

type TypeToShowType = "All" | "Current" | "Schedule" | "Failure";

function AppHistorique() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = useSelector((state: any) => state.loginForm.token);
  const tenant = useSelector((state: any) => state.loginForm.idTenant);
  const { listMachine } = useSelector((state: any) => state.machine);
  const { listResponsable } = useSelector((state: any) => state.responsable);
  const entretienFinishtoShow = useSelector(
    (state: any) => state.historique.EntretienFinishtoShow
  );

  // ---- Initialisation des variables des filtres ----
  const [machineToShow, setMachineToShow] = React.useState<number>(-1);
  const [typeToShow, setTypeToShow] = React.useState<TypeToShowType>("All");
  const [managerToShow, setManagerToShow] = React.useState<number>(-1);
  const [search, setSearch] = React.useState<string>("");

  // ---- Initialisation des variables pour les filtres ----
  const listMachineToShow = [{ idMachine: -1, nomMachine: t("WORD_All") }];
  listMachine.map((machine: any) => {
    listMachineToShow.push(machine);
  });
  const listManagerToShow = [
    { idResponsable: -1, nom: t("WORD_All_People"), prenom: "" },
  ];
  listResponsable.map((manager: any) => {
    listManagerToShow.push(manager);
  });
  const listTypeToShow = [
    { label: t("WORD_All"), name: "All" },
    { label: t("WORD_Current"), name: "Current" },
    { label: t("WORD_Schedule"), name: "Schedule" },
    { label: t("WORD_Failure"), name: "Failure" },
  ];

  React.useEffect(() => {
    dispatch(MenuActions.setPageName("Historique"));
    dispatch(Actions.setFilterHistoriqueTypeMaintenance("all"));
    dispatch(Actions.setFilterHistoriqueMachine("-1"));

    //Je récupère la liste des compteurs heures actuel des machines
    dispatch(DataActions.getCompteurHeure(token, tenant));
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* --------------------------- FILTRES --------------------------- */}
      <Grid item container xs={10} style={{ margin: "2%", width: "100%" }}>
        <Grid item xs={2} style={{ margin: "2%" }}>
          <Autocomplete
            defaultValue={listMachineToShow[0]}
            onChange={(e: any, value: any) => {
              setMachineToShow(value.idMachine);
            }}
            options={listMachineToShow}
            getOptionLabel={(option: any) => {
              return option.nomMachine;
            }}
            getOptionSelected={(option: any, value: any) => {
              return option.idMachine === value.idMachine;
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("WORK_device")} />
            )}
            disableClearable
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "2%" }}>
          <Autocomplete
            defaultValue={listTypeToShow[0]}
            onChange={(e: any, value: any) => {
              setTypeToShow(value.name);
            }}
            options={listTypeToShow}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            getOptionSelected={(option: any, value: any) => {
              return option.name === value.name;
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("WORD_Type")} />
            )}
            disableClearable
          />
        </Grid>
        <Grid item xs={2} style={{ margin: "2%" }}>
          <Autocomplete
            defaultValue={listManagerToShow[0]}
            onChange={(e: any, value: any) => {
              setManagerToShow(value.idResponsable);
            }}
            options={listManagerToShow}
            getOptionSelected={(option: any, value: any) => {
              return option.idResponsable === value.idResponsable;
            }}
            getOptionLabel={(option: any) => {
              return `${option.nom} ${option.prenom}`;
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("WORD_Manager")} />
            )}
            disableClearable
          />
        </Grid>
        <Grid item xs={4} style={{ margin: "2%" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label={t("DATATABLE_Placeholder")}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* --------------------------- TABLEAU --------------------------- */}
      <Grid item xs={12} style={{ width: "95%" }}>
        <HistoryAll
          filters={{
            machineToShow: machineToShow,
            typeToShow: typeToShow,
            managerToShow: managerToShow,
            search: search,
            entretienFinishtoShow: entretienFinishtoShow,
          }}
        />
        {/* <AppHistoriqueExceptionelle
                afficherEntretienTerminer={this.props.EntretienFinishtoShow}
                machineFilter={this.props.MachineFilter}
                TypeMaintenanceFilter={this.props.TypeMaintenanceFilter}
                beginDate={this.state.beginDate}
                endDate={this.state.endDate}
              /> */}
      </Grid>
    </Grid>
  );

  //Fonction pour lancer la génération d'un rapport
  /*   onClick = async (e) => {
    this.props.dispatch(
      reportActions.generateReport({
        idTenant: this.props.tenant,
        token: this.props.token,
      })
    );
  }; */
}
export default AppHistorique;
