import * as Actions from "../actions/GalerieActions";
import * as LoginFormActions from "../actions/loginFormActions";

export default function GalerieReducer(
  state = {
    filters: {
      categorie: null,
      type: null,
      machine: null,
      facture: null,
      anomalie: null
    },
    pictures: [],
    picturesToDownload: [],
    selectMode: false,
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case Actions.GET_PICTURES.Request: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_PICTURES.Success: {
      return {
        ...state,
        pictures: action.pictures,
        isLoading: false
      };
    }
    case Actions.GET_PICTURES.Failure: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.SET_PICTURES_TO_DOWNLOAD: {
      return {
        ...state,
        picturesToDownload: action.picturesToDownload
      };
    }
    case Actions.SET_SELECT_MODE: {
      return {
        ...state,
        selectMode: action.selectMode
      };
    }
    case Actions.ALL_PICTURES_WAS_DOWNLOADED: {
      return {
        ...state,
        selectMode: false,
        picturesToDownload: []
      };
    }
    case Actions.SET_GALERIE_FILTERS: {
      return {
        ...state,
        filters: action.filters
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        filters: {
          categorie: null,
          type: null,
          machine: null,
          facture: null,
          anomalie: null
        },
        pictures: [],
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
}
