import moment from "moment";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienDateCreation } from "../../../../../actions/EntretienProgrammerActions";
import MyDatePicker from "../../../../../utils/datePicker";

export type CreateDateProps = {
  defaultValue?: Date;
};

function CreateDate(props: CreateDateProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <Controller
      name="BeginDate"
      control={control}
      defaultValue={moment(props.defaultValue || undefined)}
      as={
        <MyDatePicker
          label={t("QIREPARE_Creation_Date")}
          inputVariant="outlined"
        />
      }
      onChange={(newDate) => {
        //TODO: a Checker...
        //Dispatch de notre nouvelle date
        dispatch(setNewEntretienDateCreation(newDate[0]));
        return newDate[0];
      }}
    />
  );
}

export default CreateDate;
