import requestType from "../utils/requestType";
import FileSaver from "file-saver";
import { serveurRequest } from "../api/socket.js";

//TODO: Modifier GENERATE_REPORT par RECOVERY_DATA ?
export const GENERATE_REPORT = requestType("GENERATE_REPORT");

export const generateReport = data => async dispatch => {
  dispatch({ type: GENERATE_REPORT.Request });
  if (data.length === 0) {
    dispatch({ type: GENERATE_REPORT.Failure });
    return alert("Erreur lors de la création du rapport."); //TODO: Faire un test par champs de saisie ?
  }

  serveurRequest("generateRapport", data).then(
    rep => {
      var byteArray = new Uint8Array(rep.dataBlob);
      var blob = new Blob([byteArray], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      serveurRequest("getTenantName", data)
        .then(rep => {
          FileSaver.saveAs(blob, "Maintenance_" + rep[0].nom + ".xlsx");
        })
        .then(
          res => {},
          rej => {
            dispatch({ type: GENERATE_REPORT.Failure });
            return alert("Erreur lors de la création du rapport.");
          }
        );
    },
    rej => {
      dispatch({ type: GENERATE_REPORT.Failure });
      return alert("Erreur lors de la création du rapport.");
    }
  );

  dispatch({
    type: GENERATE_REPORT.Success
  });

  //ga("send", "event", "report", `${state.loginForm.tenant}`, 'generate XLSX QIrepare'); // eslint-disable-line no-undef

  return;
};
