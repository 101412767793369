import React, { useState, useEffect, useRef } from "react";
import MyDatePicker from "../utils/datePicker";
import SplitButton from "./useSplitButton";

import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getLastTpsMoteurForMachineID } from "../utils/EntretiensUtils";
import { Send } from "@material-ui/icons";
import {
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import * as Actions from "../actions/EntretienPanneActions";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faultsStatusTypes } from "../types/FaultsStatusTypes.ts";
import { te } from "date-fns/locale";

export default function AnomalieForm(props) {
  const { t } = useTranslation();

  // vérifie si l'utilisateur est sur mobile ou non
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const title = useSelector((state) => state.entretienPanne.title);
  const faultStatus = useSelector((state) => state.entretienPanne.faultStatus);
  const machineSelected = useSelector(
    (state) => state.entretienPanne.idMachine
  );
  const tpsMoteurSelected = useSelector(
    (state) => state.entretienPanne.tpsMoteur
  );
  const isLoading = useSelector((state) => state.entretienPanne.isLoading);
  const responsableSelected = useSelector(
    (state) => state.entretienPanne.idResponsable
  );
  const descriptionWrite = useSelector(
    (state) => state.entretienPanne.description
  );
  const tpsMoteurDoneSelected = useSelector(
    (state) => state.entretienPanne.tpsMoteurFin
  );
  const dateFin = useSelector((state) => state.entretienPanne.dateFin);
  const anomalieTitle = useSelector((state) => state.data.listPanneTitle);
  const intervenantSelected = useSelector(
    (state) => state.entretienPanne.idIntervenant
  );
  const dispatch = useDispatch();
  const [selectedSubmit, setSelectedSubmit] = useState(0);

  //Création du state qui va gérer l'avancement le barre de progression
  const [uploadPercent, setUploadPercent] = useState(0);

  //Création du state qui va gérer l'index de la photo que nous somme entrein d'envoyer
  const [uploadPictureIndex, setUploadPictureIndex] = useState(0);

  const submitButtonEl = useRef(null);

  const [tpsMoteurError, setTpsMoteurError] = useState(false);
  const [acceptTpsMoteurError, decideTpsMoteurError] = useState(false);

  const {
    token,
    tenant,
    privileges,
    machines,
    responsables,
    allCompteur,
    anomalieToModify,
    isFinish,
    photosPanne,
    photosPanneFacture,
    photosAlreadyUploaded,
  } = props;

  //On créer notre formulaire, avec des valeurs par défauts. Pour les valeurs par defauts on regarde d'abord si l'ont à une anomalie à modifier pusi l'ont regarde si nous avons une valeurs pour l'input en question sinon on met la valeurs comme étant null
  const { handleSubmit, register, errors, control, watch, setValue } = useForm({
    defaultValues: {
      title: anomalieToModify?.title || title || "",
      faultStatus: anomalieToModify?.faultstatus || faultStatus || {},
      machineSelect: anomalieToModify?.idMachine || machineSelected || "",
      tpsMoteurInput: anomalieToModify?.tpsMoteur || tpsMoteurSelected || null,
      description: anomalieToModify?.description || descriptionWrite || "",
      responsableSelect:
        anomalieToModify?.idResponsable || responsableSelected || "",
      tpsMoteurInputFinish:
        anomalieToModify?.tpsMoteurFin ||
        tpsMoteurDoneSelected ||
        anomalieToModify?.tpsMoteur ||
        tpsMoteurSelected ||
        0,
      intervenantSelect:
        anomalieToModify?.idIntervenant || intervenantSelected || null,
    },
  });

  // Valeur de status considéré comme terminé
  const isDone =
    watch("faultStatus")?.name === "Panne réparée" ||
    watch("faultStatus")?.name === "Dépannage";

  useEffect(() => {
    if (isDone) {
      // si isDone sur true alors met a jour le temps moteur fini
      let defaultCompteur = getLastTpsMoteurForMachineID(
        allCompteur,
        machineSelected
      );
      setValue("tpsMoteurInputFinish", defaultCompteur);
    }
  }, [isDone]);

  const redirect = () => {
    dispatch(Actions.setIsFinish(false));

    if (selectedSubmit === 0) {
      //Si l'option Creer l'anomalie à été coché on retourne sur le menu
      return <Redirect to="/" />;
    } else if (selectedSubmit === 1) {
      //Si l'option Creer l'anomalie et l'entretien prgrammé à été coché on redirige vers l'entretien programmé
      return <Redirect to="AppEntretienProgrammer" />;
    }
    return null;
  };

  const submit = (data) => {
    if (anomalieToModify) {
      //Si c'est une anomalie à modifier nous appelons la fontion adequat
      dispatch(
        Actions.ModifEntretienPanne(
          token,
          tenant,
          anomalieToModify.idPanne,
          data.machineSelect,
          data.tpsMoteurInput,
          data.title,
          data.faultStatus,
          data.responsableSelect,
          data.description,
          data.tpsMoteurInputFinish ? data.tpsMoteurInputFinish : null,
          moment(anomalieToModify.date).format("YYYY-MM-DD HH:mm:ss"),
          data.dateOfCompletion
            ? moment(data.dateOfCompletion).format("YYYY-MM-DD HH:mm:ss")
            : null,
          data.intervenantSelect ? data.intervenantSelect : null,
          data.commentaire || null,
          photosPanne,
          photosPanneFacture,
          photosAlreadyUploaded,
          setUploadPercent,
          setUploadPictureIndex
        )
      );
    } else {
      //Sinon nous créons une nouvelle anomalie
      dispatch(
        Actions.AjoutEntretienPanne(
          token,
          tenant,
          data.machineSelect,
          data.tpsMoteurInput,
          data.title,
          data.faultStatus,
          data.responsableSelect,
          data.description,
          data.tpsMoteurInputFinish ? data.tpsMoteurInputFinish : null,
          data.dateOfCompletion ? data.dateOfCompletion : null,
          data.intervenantSelect ? data.intervenantSelect : null,
          data.commentaire || null,
          photosPanne,
          photosPanneFacture,
          setUploadPercent,
          setUploadPictureIndex
        )
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {/* Si l'état est is finish => on passe l'état à faux et on redirige l'utilisateur*/}
      {isFinish && redirect()}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={8}>
          <Autocomplete
            options={anomalieTitle}
            defaultValue={anomalieToModify?.title || title || ""}
            //getOptionLabel={(option) => option.title}
            disableClearable
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                id="title"
                name="title"
                inputRef={register({
                  required: "Veuillez renseigner un titre",
                })}
                error={errors.title}
                helperText={errors.title ? errors.title.message : ""}
                fullWidth
                label={t("WORD_Title")}
              />
            )}
            onInputChange={(event, value, reason) => {
              if (reason === "input") {
                const formattedValue = { title: value };
                dispatch(Actions.setTitlePanne(formattedValue));
                setValue("title", value);
              } else if (reason === "reset") {
                dispatch(Actions.setTitlePanne(value));
                setValue("title", value);
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth error={errors.faultStatus ? true : false}>
            <InputLabel
              id="select-fault-status-label"
              style={{ paddingLeft: "12px" }}
            >
              {t("WORD_Fault_Status")}
            </InputLabel>
            <Controller
              as={
                <Select
                  renderValue={(selected) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "normal",
                        }}
                      >
                        <div
                          style={{
                            height: "15px",
                            width: "15px",
                            borderRadius: "50%",
                            backgroundColor: selected.color,
                            marginRight: "15px",
                          }}
                        />
                        {!isMobile && selected.name}
                      </div>
                    );
                  }}
                >
                  {Object.values(faultsStatusTypes).map((faultStatusType) => {
                    return (
                      <MenuItem
                        key={faultStatusType.name}
                        value={faultStatusType}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "normal",
                          }}
                        >
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              borderRadius: "50%",
                              backgroundColor: faultStatusType.color,
                              marginRight: "15px",
                            }}
                          />
                          {faultStatusType.name}
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="faultStatus"
              control={control}
              labelId="select-fault-status-label"
              id="select-fault-status"
              variant="outlined"
              rules={{
                required: "Veuillez renseigner un état",
                validate: (value) => (value?.name ? true : false),
              }}
              onChange={(event) => {
                //Met à jour le store
                dispatch(Actions.setFaultStatusPanne(event[1].props.value));

                //Met à jour le champ
                return event[1].props.value;
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8} md={6}>
          {/* CHAMP DE SELECTION D'UNE MACHINE */}
          <FormControl fullWidth error={errors.machineSelect ? true : false}>
            <InputLabel
              id="select-machine-label"
              style={{ paddingLeft: "12px" }}
            >
              {t("WORK_device")}
            </InputLabel>
            <Controller
              as={
                <Select>
                  {machines.map((machine) => {
                    return (
                      <MenuItem
                        key={machine.idMachine}
                        value={machine ? machine.idMachine : ""}
                      >
                        {machine.nomMachine}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="machineSelect"
              control={control}
              labelId="select-machine-label"
              id="select-machine"
              variant="outlined"
              onChange={(event) => {
                //Met à jour le store
                dispatch(Actions.setIdMachinePanne(event[1].props.value));

                // React Select return object instead of value for selection
                //Met à jour le champs tpsMoteurInput avec le tpsMoteur de la machine choisie
                let defaultCompteur = getLastTpsMoteurForMachineID(
                  allCompteur,
                  event[1].props.value
                );
                setValue("tpsMoteurInput", defaultCompteur);

                //On met aussi à jour le store avec le temps moteur trouvé
                dispatch(Actions.setTpsMoteurPanne(defaultCompteur));

                setValue("tpsMoteurInputFinish", defaultCompteur);
                dispatch(Actions.setTpsMoteurFinPanne(defaultCompteur));

                return event[1].props.value;
              }}
              rules={{ required: "Veuillez choisir une machine" }}
            />
            {errors.machineSelect && (
              <FormHelperText>{errors.machineSelect.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} md={6}>
          {/* CHAMP DE SELECTION DU TEMPS MOTEUR */}
          <Controller
            as={<TextField />}
            fullWidth
            variant="outlined"
            type="number"
            name="tpsMoteurInput"
            id="tpsMoteurInput"
            label={t("WORD_Engine_Time")}
            InputLabelProps={{ shrink: true }}
            error={errors.tpsMoteurInput ? true : false}
            helperText={errors.tpsMoteurInput && errors.tpsMoteurInput.message}
            control={control}
            rules={{
              required: "Veuillez entrer le compteur horaire de la machine",
              min: {
                value: 0,
                message: "Le compteur ne peut pas être en dessous de 0",
              },
            }}
            onChange={([event]) => {
              //On met à jour le store avec le temps moteur
              dispatch(Actions.setTpsMoteurPanne(event.target.value));

              return event.target.value;
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            label={t("WORD_Description")}
            id={`description`}
            name={`description`}
            margin="none"
            variant="outlined"
            multiline
            onChange={(event) => {
              //On dispatch ce nouvel objet
              dispatch(Actions.setDescriptionPanne(event.target.value));
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {/* CHAMP DE SELECTION D'UN RESPONSABLE */}
          <FormControl
            fullWidth
            error={errors.responsableSelect ? true : false}
          >
            <InputLabel
              id="select-responsable-label"
              style={{ paddingLeft: "12px" }}
            >
              {t("WORD_Manager")}
            </InputLabel>
            <Controller
              as={
                <Select style={{ width: "100%" }}>
                  {responsables.map((responsable) => {
                    return (
                      <MenuItem
                        key={responsable.idResponsable}
                        value={responsable.idResponsable}
                      >
                        {`${responsable.nom} ${responsable.prenom}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              variant="outlined"
              labelId="select-responsable-label"
              id="select-responsable"
              name="responsableSelect"
              control={control}
              rules={{ required: "Veuillez choisir un responsable" }}
              onChange={(event) => {
                //Met  à jour le store
                dispatch(Actions.setIdResponsablePanne(event[1].props.value));
                // React Select return object instead of value for selection
                return event[1].props.value;
              }}
            />
            {errors.responsableSelect && (
              <FormHelperText>
                {errors.responsableSelect.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {isDone && (
          <React.Fragment>
            <Grid item xs={8} md={6}>
              {/* CHAMP DE SELECTION DU TEMPS MOTEUR DE FIN*/}
              <Controller
                as={<TextField />}
                fullWidth
                variant="outlined"
                type="number"
                name="tpsMoteurInputFinish"
                id="tpsMoteurInputFinish"
                label={t("QIREPARE_Real_Engine_Time")}
                InputLabelProps={{ shrink: true }}
                error={errors.tpsMoteurInputFinish ? true : false}
                helperText={
                  errors.tpsMoteurInputFinish &&
                  errors.tpsMoteurInputFinish.message
                }
                control={control}
                rules={{
                  required: "Veuillez entrer le compteur horaire de la machine",
                  min: {
                    value: 0,
                    message: "Le compteur ne peut pas être en dessous de 0",
                  },
                }}
                onChange={([event]) => {
                  //On met à jour le store avec le temps moteur
                  dispatch(Actions.setTpsMoteurFinPanne(event.target.value));

                  return event.target.value;
                }}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              {/* CHOIX DE LA DATE DE REALISATION DE l'ANOMALIE */}
              <Controller
                as={<MyDatePicker />}
                control={control}
                inputVariant="outlined"
                name="dateOfCompletion"
                label={t("QIREPARE_Repair_Date")}
                animateYearScrolling={true}
                showTodayButton={true}
                todayLabel="Aujourd'hui"
                views={["year", "month", "date"]}
                format="DD / MM / YYYY"
                defaultValue={
                  anomalieToModify
                    ? moment(
                        anomalieToModify.dateFin,
                        "YYYY-MM-DD HH:mm:ss"
                      ).isValid()
                      ? moment(anomalieToModify.dateFin, "YYYY-MM-DD HH:mm:ss")
                      : moment()
                    : dateFin
                    ? dateFin
                    : moment()
                }
                onChange={([newDate]) => {
                  dispatch(Actions.setDateFin(newDate.format("YYYY-MM-DD")));

                  return newDate;
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {/* CHAMP DE SELECTION D'UN RESPONSABLE */}
              <FormControl
                fullWidth
                error={errors.intervenantSelect ? true : false}
              >
                <InputLabel
                  id="select-intervenant-label"
                  style={{ paddingLeft: "12px" }}
                >
                  {t("WORD_Manager")}
                </InputLabel>
                <Controller
                  as={
                    <Select style={{ width: "100%" }}>
                      {responsables.map((responsable) => {
                        return (
                          <MenuItem
                            key={responsable.idResponsable}
                            value={responsable.idResponsable}
                          >
                            {`${responsable.prenom} ${responsable.nom}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  variant="outlined"
                  labelId="select-intervenant-label"
                  id="select-intervenant"
                  name="intervenantSelect"
                  control={control}
                  rules={{
                    required:
                      "Veuillez choisir le responsable qui à effectué la maintenance",
                  }}
                  onChange={(event) => {
                    //Met  à jour le store
                    dispatch(
                      Actions.setIdIntervenantPanne(event[1].props.value)
                    );
                    // React Select return object instead of value for selection
                    return event[1].props.value;
                  }}
                />
                {errors.intervenantSelect && (
                  <FormHelperText>
                    {errors.intervenantSelect.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
        {/*Nous regardons si l'utilisateur à l'option ou non d'être rediriger vers les entretiens programmé*/}
        {!isLoading ? (
          privileges.anomalieMultiSubmit ? (
            <SplitButton
              isLoading={false}
              anomalieToModify={anomalieToModify}
              submitRef={submitButtonEl}
              //console.log("SplitButton OnChange :" + selectedID);
              onChange={(selectedID) => {
                setSelectedSubmit(selectedID);
              }}
            />
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              ref={submitButtonEl}
              style={{
                backgroundColor: "#C4161B",
                color: "#DADAD9",
              }}
            >
              <Typography>
                {anomalieToModify
                  ? t("QIRECORD_Edit").toUpperCase()
                  : t("WORD_Create").toUpperCase()}
              </Typography>
              <Send style={{ paddingLeft: "10px" }} />
            </Button>
          )
        ) : (
          <Grid item xs={12} style={{ padding: "10px" }}>
            <Typography variant="overline">{`${t(
              "WORD_SendProgress"
            )} (${uploadPictureIndex}/${photosPanne.length})`}</Typography>
            {/* Si nous aovns des photos on affiche une barre de progression lineaire*/}
            {photosPanne.length ? (
              <LinearProgress variant={"determinate"} value={uploadPercent} />
            ) : (
              <LinearProgress variant={"query"} />
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
}
//Problème de react fragment
