// Fichier de reducers Redux qui gère la partie responsable

import * as Actions from "../actions/ResponsableActions";
import * as LoginFormActions from "../actions/loginFormActions";

export default function ResponsableReducer(
  state = {
    listResponsable: [],
    idSelect: "null",
    nom: "",
    prenom: "",
    mail: "",
  },
  action
) {
  switch (action.type) {
    case Actions.GET_RESPONSABLE.Success: {
      return {
        ...state,
        listResponsable: action.responsables,
      };
    }
    case Actions.SET_SELECTED: {
      return {
        ...state,
        idSelect: action.idSelect,
      };
    }
    case Actions.SET_NOM: {
      return {
        ...state,
        nom: action.nom,
      };
    }
    case Actions.SET_PRENOM: {
      return {
        ...state,
        prenom: action.prenom,
      };
    }
    case Actions.SET_MAIL: {
      return {
        ...state,
        mail: action.mail,
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        listResponsable: [],
        idSelect: "null",
        nom: "",
        prenom: "",
        mail: "",
      };
    }
    default: {
      return state;
    }
  }
}
