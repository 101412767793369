import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { setIdResponsableCourant } from "../../../../../actions/EntretienCourantAction";

export type ManagerProps = {
  defaultValue?: string;
};

function Manager(props: ManagerProps) {
  const { errors, control } = useFormContext();

  const dispatch = useDispatch();

  const allManager = useSelector((state) => state.responsable.listResponsable);

  return (
    <FormControl fullWidth error={errors.responsableSelect ? true : false}>
      <InputLabel id="select-responsable-label">Responsable</InputLabel>
      <Controller
        as={
          <Select style={{ width: "100%" }}>
            {(allManager || []).map((responsable) => {
              return (
                <MenuItem
                  key={responsable.idResponsable}
                  value={responsable.idResponsable || ""}
                >
                  {`${responsable.prenom} ${responsable.nom}`}
                </MenuItem>
              );
            })}
          </Select>
        }
        defaultValue=""
        labelId="select-responsable-label"
        id="select-responsable"
        name="responsableSelect"
        control={control}
        rules={{ required: "Veuillez choisir un responsable" }}
        onChange={(event) => {
          //Met  à jour le store
          dispatch(setIdResponsableCourant(event[1].props.value));
          // React Select return object instead of value for selection
          return event[1].props.value;
        }}
      />
      {errors.responsableSelect && (
        <FormHelperText>{errors.responsableSelect.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default Manager;
