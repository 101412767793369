import { InputAdornment, TextField } from "@material-ui/core";
import { PinDropSharp } from "@material-ui/icons";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienAFaire } from "../../../../../../actions/EntretienProgrammerActions";

type ReminderMaxProps = {
  defaultValue?: number;
};

function ReminderMax(props: ReminderMaxProps) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleChange = ([event, value]) => {
    const numericValue = parseInt(
      (value.match(/[0-9]*/gm) || []).join("") || 0
    );

    dispatch(setNewEntretienAFaire(numericValue));

    return numericValue;
  };

  return (
    <Controller
      control={control}
      name="TimeToDo"
      rules={{ required: "Veuillez indiquer le temps limite !", min: 0 }}
      onChange={handleChange}
      defaultValue={props.defaultValue || ""}
      as={(props) => {
        return (
          <TextField
            {...props}
            placeholder={"300"}
            onChange={(event) => props.onChange(event, event.target.value)}
            required
            fullWidth
            label="Nombre d'heures avant l'entretien"
            variant="outlined"
            error={!!errors.TimeToDo}
            helperText={errors.TimeToDo?.message || ""}
            inputProps={{ min: 0, inputMode: "numeric", pattern: "[0-9]*" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("WORD_Hours")}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
}

export default ReminderMax;
