interface FaultProps {
  name: string;
  color: string;
}

export type faultStatusEnum = { [key: string]: FaultProps };

export const faultsStatusTypes: faultStatusEnum = {
  0: {
    name: "En cours / machine inutilisable",
    color: "#FF0000",
  },
  1: {
    name: "En cours / machine opérationnelle",
    color: "#FFDE00",
  },
  2: {
    name: "Dépannage",
    color: "#78E700",
  },
  3: {
    name: "Panne réparée",
    color: "#00c322",
  },
};
