export const SET_TYPE_TO_SHOW = "SET_TYPE_TO_SHOW";
export const setTypeToShow = typeToShow => async dispatch => {
  dispatch({ type: SET_TYPE_TO_SHOW, typeToShow });
};
export const SET_FILTER_HISTORIQUE_TYPE_MAINTENANCE =
  "SET_FILTER_HISTORIQUE_TYPE_MAINTENANCE";
export const setFilterHistoriqueTypeMaintenance = TypeMaintenanceFilter => async dispatch => {
  dispatch({
    type: SET_FILTER_HISTORIQUE_TYPE_MAINTENANCE,
    TypeMaintenanceFilter
  });
};
export const SET_FILTER_HISTORIQUE_MACHINE = "SET_FILTER_HISTORIQUE_MACHINE";
export const setFilterHistoriqueMachine = MachineFilter => async dispatch => {
  dispatch({ type: SET_FILTER_HISTORIQUE_MACHINE, MachineFilter });
};
export const SET_IS_LOADING_HISTORIQUE = "SET_IS_LOADING_HISTORIQUE";
export const setIsLoadingHistorique = isLoading => async dispatch => {
  dispatch({ type: SET_IS_LOADING_HISTORIQUE, isLoading });
};
export const SET_ENTRETIEN_FINISH_TO_SHOW = "SET_ENTRETIEN_FINISH_TO_SHOW";
export const setEntretienFinishToShow = show => async dispatch => {
  dispatch({ type: SET_ENTRETIEN_FINISH_TO_SHOW, show });
};
