import { serveurRequest } from "../api/socket.js";
import { formatEngineTimeForClient } from "../utils/format.ts";
import requestType from "../utils/requestType.js";
// Actions liées à la récupération des informations des entretiens

export const DROP_ALERT = requestType("DROP_ALERT");

export const GET_ALERTE = requestType("GET_ALERTE");
export const getAlerte = (token, idTenant) => async (dispatch) => {
  dispatch({ type: GET_ALERTE.Request });

  serveurRequest("GET:maintenance/schedules", {
    idTenant: idTenant,
  })
    .then((rep) => {
      var alertes = (rep || []).map((schedule) => {
        return {
          idAlert: schedule.id,
          commentaire: schedule.commentaire,
          compteurMachineFin: formatEngineTimeForClient(
            schedule.final_engine_time?.engine_time
          ),
          dateCreation: schedule.start_date,
          dateFin: schedule.end_date,
          description: schedule.description,
          etat: schedule.is_done
            ? 3
            : schedule.second_reminder_sended
            ? 2
            : schedule.first_reminder_sended
            ? 1
            : 0,
          heureActuel: formatEngineTimeForClient(
            schedule.current_engine_time?.engine_time
          ),
          heureLimite: schedule.first_engine_time_reminder || null,
          idAlert: schedule.id,
          idAlertType: schedule.secondary_mechanical_id,
          idIntervenant: schedule.repairer_id,
          idMachine: schedule.equipment_id,
          idResponsable:
            schedule?.managers?.length > 0 && schedule.managers[0] !== null
              ? schedule.managers.map((manager) => manager.manager_id).join(",")
              : null,
          idTenant: schedule.company_id,
          rappel: schedule.second_engine_time_reminder || null,
          reminder_date: schedule.first_date_reminder,
          reminder_date_max: schedule.second_date_reminder,
        };
      });
      //Enregistre les alertes dans le store
      dispatch({ type: GET_ALERTE.Success, alertes });
    })
    .catch((rej) => {
      dispatch({ type: GET_ALERTE.Failure });
      return alert("Echec lors de la récupération des entretiens programmés");
    });
};

export const GET_ALERTE_COURANTE = requestType("GET_ALERTE_COURANTES");
export const getAlerteCourantes = (token, idTenant) => async (dispatch) => {
  dispatch({ type: GET_ALERTE_COURANTE.Request });

  serveurRequest("GET:maintenance/current", {
    company_id: idTenant,
  }).then(
    (rep) => {
      //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
      var alertesCourantes = (rep || []).map((current) => {
        return {
          IDstat: current.id,
          commentaire: "",
          date: current.date,
          idMachine: current.equipment_id,
          idResponsable: current.repairer_id,
          idType: [
            [
              current.mechanicals.reduce((acc, current) => {
                acc[current.secondary_mechanical_id] = {
                  commentaire: current.description,
                  typeID: current.secondary_mechanical_id,
                  value: current.state,
                };
                return acc;
              }, {}),
            ],
          ],
          tpsMoteur: formatEngineTimeForClient(
            current.engine_time?.engine_time
          ),
        };
      });
      //Enregistre les alertes dans le store
      dispatch({ type: GET_ALERTE_COURANTE.Success, alertesCourantes });
    },
    (rej) => {
      dispatch({ type: GET_ALERTE_COURANTE.Failure });
      return alert("Echec de la récupération des entretiens courants");
    }
  );
};

export const GET_ALERTE_PANNE = requestType("GET_ALERTE_PANNE");
export const getAlertePanne = (token, idTenant) => async (dispatch) => {
  dispatch({ type: GET_ALERTE_PANNE.Request });

  serveurRequest("GET:maintenance/failures", {
    idTenant: idTenant,
  }).then(
    (rep) => {
      //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
      var alertesPanne = (rep || []).map((failure) => {
        return {
          commentaire: failure.comment,
          date: failure.start_date,
          dateFin: failure.end_date,
          description: failure.description,
          idIntervenant: failure.repairer_id,
          idMachine: failure.equipment_id,
          idPanne: failure.id,
          idResponsable: failure.manager_id,
          title: failure.title,
          tpsMoteur: formatEngineTimeForClient(
            failure.engine_time?.engine_time
          ),
          tpsMoteurFin: formatEngineTimeForClient(
            failure.final_engine_time?.engine_time
          ),
          faultstatus: failure.fault_status,
        };
      });
      //Enregistre les alertes dans le store
      dispatch({ type: GET_ALERTE_PANNE.Success, alertesPanne });
    },
    (rej) => {
      dispatch({ type: GET_ALERTE_PANNE.Failure });
      return alert("Echec de la récupération des anomalies");
    }
  );
};

//récupération de la liste des types des entretiens(vidange, entretien, réparation)
export const GET_TYPE = requestType("GET_TYPE");
export const getType = (token, tenant) => async (dispatch) => {
  dispatch({ type: GET_TYPE.Request });

  //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
  serveurRequest("get:secondaryMechanical", {
    company_id: tenant,
  }).then(
    (reponse) => {
      const formattedListType = reponse
        .map((rep) => {
          return {
            equipmentTypeId: rep.equipment_type_id,
            idCategorie: rep.main_mechanical_id,
            idTenant: rep.company_id,
            idType: rep.id,
            nomType: rep.name,
          };
        })
        .sort((a, b) => {
          if (a.nomType === b.nomType) {
            return 0;
          } else if (a.nomType > b.nomType) {
            return 1;
          } else {
            return -1;
          }
        });

      dispatch({ type: GET_TYPE.Success, listType: formattedListType });
    },
    (rej) => {
      dispatch({ type: GET_TYPE.Failure });
      return alert("Echec de la récupération des types d'entretiens");
    }
  );
};

//récupération titres d'anomalies
export const GET_ANOMALIE_TITLE = requestType("GET_ANOMALIE_TITLE");
export const getAnomalieTitle = (token, tenant) => async (dispatch) => {
  dispatch({ type: GET_ANOMALIE_TITLE.Request });

  serveurRequest("getPanneTitle", {
    idTenant: tenant,
  }).then(
    (reponse) => {
      reponse.sort((a, b) => {
        if (a.title === b.title) {
          return 0;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return -1;
        }
      });

      const uniqueTitle = [...new Set(reponse)];

      dispatch({
        type: GET_ANOMALIE_TITLE.Success,
        listPanneTitle: uniqueTitle,
      });
    },
    (rej) => {
      dispatch({ type: GET_ANOMALIE_TITLE.Failure });
      return alert("Echec de la récupération des titres des anomalies");
    }
  );
};

//RECUPERATION DES CATEGORIES
export const SET_CAT_TYPE = "SET_CAT_TYPE";
export const setCategorieType = (listCatType) => async (dispatch) => {
  //NOTE - Nous recreons le format initilialement renvoyé par QTrepare afin de gagner du temps
  const formattedCaterogie = listCatType.map((categorie) => {
    return {
      canShowInCourant: categorie.is_current ? 1 : 0,
      canShowInProgrammer: categorie.is_schedule ? 1 : 0,
      idCategorie: categorie.id,
      idTenant: categorie.company_id,
      nomCategorie: categorie.name,
    };
  });

  dispatch({ type: SET_CAT_TYPE, listCatType: formattedCaterogie });
};

//récupération de la liste des compteurs horaires actuel des machines
export const GET_COMPTEUR_HEURE = requestType("GET_COMPTEUR_HEURE");
export const getCompteurHeure =
  (token, idTenant) => async (dispatch, getState) => {
    dispatch({ type: GET_COMPTEUR_HEURE.Request });

    const state = getState();

    const equipments = state.machine.listMachine;

    //NOTE - Nous construisons notre liste de compteur horaire en fonction de nos equipement pour s'adatper au format de QTrepare
    const allEngineTimes = equipments.map((equipment) => {
      return {
        idMachine: equipment.idMachine,
        tempsMoteur: (equipment.geofarmData.engine?.engine_time || 0) / 3600,
        statDate:
          equipment.geofarmData.engine?.date || new Date().toISOString(),
      };
    });

    dispatch({
      type: GET_COMPTEUR_HEURE.Success,
      listCompteurHeure: allEngineTimes,
    });

    /*   serveurRequest("getCompteurHeure", {
    token: token,
    idTenant: idTenant,
  }).then(
    (res) => {
      var listCompteurHeure = res.map((compteur) => {
        compteur.tempsMoteur = compteur.tempsMoteur / 3600;
        return compteur;
      });
      dispatch({ type: GET_COMPTEUR_HEURE.Success, listCompteurHeure });
    },
    (rej) => {
      dispatch({ type: GET_COMPTEUR_HEURE.Failure });
      return alert("Echec de la récupération du compteur des machines");
    }
  ); */
  };

export const dropAlert =
  (idAlert, token, idTenant, typeEntretien) => async (dispatch) => {
    dispatch({ type: DROP_ALERT.Request });

    if (!Number.isInteger(idAlert)) {
      dispatch({ type: DROP_ALERT.Failure });
      return alert("Une erreur c'est produite.");
    }
    if (typeEntretien === 0) {
      //alerte Exceptionelle
      serveurRequest("DELETE:maintenance/schedule", {
        idAlert: idAlert,
        typeEntretien: 0,
      }).then(
        (res) => {
          dispatch({ type: DROP_ALERT.Success });
          dispatch(getAlerte(token, idTenant));
          return alert("Votre entretien programmé à bien été suppprimée");
        },
        (rej) => {
          dispatch({ type: DROP_ALERT.Failure });
          return alert(
            "Erreur lors de la suppression de l'entretien programmé"
          );
        }
      );
    } else if (typeEntretien === 1) {
      //alerte Courante
      serveurRequest("DELETE:maintenance/current", {
        idAlert: idAlert,
        typeEntretien: 1,
      }).then(
        (res) => {
          dispatch({ type: DROP_ALERT.Success });
          dispatch(getAlerteCourantes(token, idTenant));
          return alert("Votre entretien courant à bien été suppprimée");
        },
        (rej) => {
          dispatch({ type: DROP_ALERT.Failure });
          return alert("Erreur lors de la suppression de l'entretien courant");
        }
      );
    } else if (typeEntretien === 2) {
      //Panne
      serveurRequest("DELETE:maintenance/failure", {
        idAlert: idAlert,
        typeEntretien: 2,
      }).then(
        (res) => {
          dispatch({ type: DROP_ALERT.Success });
          dispatch(getAlertePanne(token, idTenant));
          return alert("Votre anomalie à bien été suppprimée");
        },
        (rej) => {
          dispatch({ type: DROP_ALERT.Failure });
          return alert("Erreur lors de la suppression de l'anomalie");
        }
      );
    }
  };
