// Fichier de reducers Redux qui gère la partie entretien
import * as LoginFormActions from "../actions/loginFormActions";
import * as Actions from "../actions/DataActions";

export default function DataReducer(
  state = {
    listAlerte: "",
    listAlerteCourantes: "",
    listAlertePanne: "",
    listPanneTitle: [],
    listType: "",
    listCompteurHeure: [],
    listTypeCategorie: "",
  },
  action
) {
  switch (action.type) {
    case Actions.GET_ALERTE.Success: {
      return {
        ...state,
        listAlerte: action.alertes,
      };
    }
    case Actions.GET_ALERTE_COURANTE.Success: {
      return {
        ...state,
        listAlerteCourantes: action.alertesCourantes,
      };
    }
    case Actions.GET_ALERTE_PANNE.Success: {
      return {
        ...state,
        listAlertePanne: action.alertesPanne,
      };
    }
    case Actions.GET_ANOMALIE_TITLE.Success: {
      return {
        ...state,
        listPanneTitle: action.listPanneTitle,
      };
    }
    case Actions.GET_ANOMALIE_TITLE.Failure: {
      return {
        ...state,
      };
    }
    case Actions.GET_TYPE.Success: {
      return {
        ...state,
        listType: action.listType,
      };
    }

    case Actions.GET_COMPTEUR_HEURE.Success: {
      return {
        ...state,
        listCompteurHeure: action.listCompteurHeure,
      };
    }

    case Actions.SET_CAT_TYPE: {
      return {
        ...state,
        listTypeCategorie: action.listCatType,
      };
    }
    case LoginFormActions.LOGOUT: {
      return {
        ...state,
        listAlerte: "",
        listAlerteCourantes: "",
        listAlertePanne: "",
        listType: "",
        listCompteurHeure: "",
        listTypeCategorie: "",
      };
    }
    default: {
      return state;
    }
  }
}
