import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNewEntretienRappel } from "../../../../../../actions/EntretienProgrammerActions";

type FirstReminderProps = {
  defaultValue?: string;
};

function FirstReminder(props: FirstReminderProps) {
  const { control, errors } = useFormContext();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = ([event, value]) => {
    const numericValue = parseInt(
      (value.match(/[0-9]*/gm) || []).join("") || 0
    );

    dispatch(setNewEntretienRappel(numericValue));

    return numericValue;
  };

  return (
    <Controller
      control={control}
      name="Rappel"
      rules={{
        required: "Veuillez choisir votre temps de rappel !",
        min: 0,
        pattern: /[0-9]*/gm,
      }}
      defaultValue={props.defaultValue || ""}
      onChange={handleChange}
      as={(props) => (
        <TextField
          {...props}
          placeholder={"20"}
          onChange={(event) => props.onChange(event, event.target.value || 0)}
          fullWidth
          required
          label={t("WORD_First_Reminder")}
          variant="outlined"
          error={!!errors.Rappel}
          helperText={errors.Rappel?.message || ""}
          inputProps={{
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t("WORD_Hours")}</InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
}

export default FirstReminder;
