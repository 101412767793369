import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/CloudDownload";
import * as reportActions from "../actions/ReportActions";
import { useDispatch, useSelector } from "react-redux";

function CustomToolbar(){
    const dispatch = useDispatch()
    const token = useSelector(state => state.loginForm.token);
    const tenant = useSelector(state => state.loginForm.idTenant);

  const downloadRapport = async e => {
    dispatch(
      reportActions.generateReport({
        idTenant: tenant,
        token: token
      })
    );
  };

  return <Tooltip title={"Télécharger"}>
  <IconButton
    onClick={downloadRapport}
  >
    <AddIcon />
  </IconButton>
</Tooltip>
}

export default CustomToolbar