/**
 * Classe appelée lors de la tentative de connexion. Affiche un "rond de chargement"
 */

import React, { Component } from "react";
import "../css/AppProgress.css";


class AppProgress extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="AppProgress">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppProgress;
